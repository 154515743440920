import React, { Suspense } from "react";
import MainRoutes from "./MainRoutes";
import Layout from "./components/Layout";
import { Route, Switch, withRouter } from "react-router-dom";
import LiveAuctionLanding from "./pages/LiveAuctionLanding";
import { isMobile } from "react-device-detect";
import { ReactComponent as CloseIcon } from "./assets/image/closeWhite.svg";
import "./App.css";

class App extends React.Component {
  closeNotification = () => {
    document
      .getElementById("showUserMessageDivID1")
      ?.setAttribute("style", "display:none");
  };
  render() {
    return (
      <Suspense fallback={null}>
        <Switch>
          <Route
            exact
            path="/live-auction-landing"
            component={withRouter(LiveAuctionLanding)}
          />
          <Layout>
            <MainRoutes />
            <div
              id="showUserMessageDivID1"
              className={isMobile ? "showUserMobile" : ""}
            >
              <p id="showUserMessageDivID"></p>
              <CloseIcon
                className="closeIconStyles"
                onClick={this.closeNotification}
              />{" "}
            </div>
          </Layout>
        </Switch>
      </Suspense>
    );
  }
}
export default App;
