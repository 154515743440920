import React, { useState, useEffect } from "react";
import { StyledComingSoonPage2 } from "./ComingSoonPage2.styled";
import fahh1 from "./../../assets/fahh1.png";
import { useSelector } from "react-redux";
import social_icon from "./../../assets/coming-soon/social_icon.png";
import Footer_icon from "./../../assets/coming-soon/footer_icon.svg";
import social_icon1 from "./../../assets/coming-soon/social_icon1.png";
import social_icon2 from "./../../assets/coming-soon/social_icon2.png";
import social_icon3 from "./../../assets/coming-soon/social_icon3.png";
import FAHH_logo_color_2 from "./../../assets/coming-soon/FAHH_logo_color_2.svg";
import Rock_the_Bells1 from "./../../assets/coming-soon/Rock_the_Bells1.svg";
import { triggerMixpanelEvent } from "../../Scenes/common";
import Fahh_coming_soon_12 from "./../../assets/coming-soon/fahh_coming_soon_12.svg";
import { Modal, Button } from "react-bootstrap";
import Logo_new from "./../../assets/main/fahh_logo.png";

// FAHH_logo_color_2
interface CartProps {}
const ComingSoonPage2: React.FC<CartProps> = () => {
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [showConformationModel, setShowConformationModel] = useState(false);

  useEffect(() => {
    triggerMixpanelEvent("pageOnLoad", {});
  }, []);
  const handleSignup = () => {
    if (userName && userEmail) {
      triggerMixpanelEvent("userSignup", { userName, userEmail });
      setUserName("");
      setUserEmail("");
      setShowConformationModel(true);
    }
  };
  const onOKButton = () => {
    setShowConformationModel(false);
  };
  return (
    <StyledComingSoonPage2>
      <Modal
        show={showConformationModel}
        onCancel={() => setShowConformationModel(false)}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {" "}
        <StyledComingSoonPage2>
          <Modal.Body className="modalBodyDefault">
            <p className="modalBodyText">Welcome to FineArt.HipHop</p>

            <p className="modalBodyTextSub">
              You have successfully joined FineArt.HipHop's mailing list. We
              will notify you when the{" "}
              <span>50 Years of Hip Hop Flyers Auction</span> goes live and
              periodically share other important information.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onOKButton} className="modalCandelBtn1">
              Ok
            </Button>
          </Modal.Footer>
        </StyledComingSoonPage2>
      </Modal>

      <div className="coming-soon-page3">
        <div className="logo-image-full">
          <img className="background-image" src={Logo_new} />{" "}
        </div>
        <p className="sub-text yellow-color-new">
          {/* BIDDING HAS BEEN PAUSED - APPROVED BIDDERS HAVE BEEN NOTIFIED BY
          EMAIL. IF YOU ARE AN APPROVED BIDDER AND HAVE NOT RECEIVED DETAILS,
          PLEASE CONTACT US AT:{" "}
          <a href="mailto:support@fineart.hiphop">support@fineart.hiphop</a> */}
          All is well. We are performing site maintenance. We will be back in 60
          minutes.
        </p>
        {/* <img className="background-image" src={fahh1} /> */}
        {/* <div className="coming-soon-content">
         
          <div className="main-content-page">
            <div className="main-content-header">
              <p className="main-text">Coming Soon</p>
              <p className="main-text-border"></p>

              <p className="sub-text yellow-color">
                “The 50 Years of Hip Hop Flyers Auction”
              </p>
              <p className="sub-sub-text  ">
                In recognition of the 50th anniversary of Hip Hop, this auction
                features rare and historically significant Hip Hop flyers and
                handbills curated from the personal collections of leading
                graphic artists and prominent collectors such as{" "}
                <span className="yellow-color">
                  Phase 2, Buddy Esquire, Kool Herc, DJ Kay Slay, CC the Female
                  DJ, Winston Sanders, Easy AD (co-Founder of the Cold Crush
                  Brothers), Biz Markie, Jerry Roebuck, Cut Chemist, Sureshot La
                  Rock, Triple E of the Debonaire 4, Paradise Gray, Pete Nice,
                  Sweety G, Charlie Ahearn, Van Silk, Dr. Khalid el-Hakim and
                  Faith Newman
                </span>
              </p>
              <p className="sub-text yellow-color">
                BIDDING HAS BEEN PAUSED - INFORMATION WILL BE COMING TO ALL
                BIDDERS WITHIN 48 HOURS
              </p>
            </div>
            <div className="form-text-box">
              <p className="label-text">Sign up to join the mailing list</p>
              <div className="input-box-bg">
                <input
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                  value={userName}
                  className="name-input"
                  placeholder="Enter your name"
                />
                <input
                  value={userEmail}
                  onChange={(e) => {
                    setUserEmail(e.target.value);
                  }}
                  className="email-input"
                  placeholder="Enter your email address"
                />
                <button onClick={handleSignup} className="submit-btn">
                  I’m In (Sign up){" "}
                </button>
              </div>
            </div>
            <div className="bottom-present-text">
              <div className="present-by">
                <span></span> Presented by <span></span>
              </div>
              <div className="present-by-content">
                <div className="present-by-left">
                  <div className="content-present-logo">
                    <img src={Rock_the_Bells1} />
                  </div>
                  <div className="content-present-text">
                    <p className="present-header">Rock the Bells</p>
                    <p className="present-sub-header">
                      A platform that elevates Hip Hop culture and the people
                      who created it
                    </p>
                    <p className="present-icons-list">
                      <span>
                        <a
                          target="_blank"
                          href="https://twitter.com/RockTheBells"
                        >
                          <img src={social_icon2} />
                        </a>
                      </span>
                      <span>
                        <a
                          target="_blank"
                          href="https://www.instagram.com/RockTheBells"
                        >
                          <img src={social_icon1} />
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="center-box-content"></div>
                <div className="present-by-right">
                  <div className="content-present-logo">
                    <img src={Fahh_coming_soon_12} />
                  </div>
                  <div className="content-present-text">
                    <p className="present-header">FineArt.HipHop</p>
                    <p className="present-sub-header">
                      The trusted and authentic auction platform and marketplace
                      for Hip Hop inspired fine art and collectibles
                    </p>
                    <p className="present-icons-list">
                      <span>
                        <a
                          target="_blank"
                          href="https://twitter.com/fineart_hiphop"
                        >
                          <img src={social_icon2} />
                        </a>
                      </span>
                      <span>
                        <a
                          target="_blank"
                          href="https://www.instagram.com/fineart.hiphop/"
                        >
                          <img src={social_icon1} />
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="border-line"></div>
              <div className="footer-content">
                <p>
                  <img src={Footer_icon} /> All rights reserved by
                  FineArt.HipHop
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </StyledComingSoonPage2>
  );
};

export default ComingSoonPage2;
