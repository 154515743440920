import React, { Component } from "react";
import "./css/style.css";
class GetanEstimate extends React.Component {
  componentDidMount() {
    document.title = "FAHH-getanEstimate";
  }
  render() {
    return (
      <div className="getanEstimateContainer">
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </p>
        <p>this is Get an Estimate page</p>
      </div>
    );
  }
}
export default GetanEstimate;
