import React from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";

import AccountSlideBar from "../../components/AccountSlideBar";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";

import BottomArrow from "../../assets/image/Bottom_arrow.svg";

import "./css/Faq.css";

class Faq extends React.Component<any, any> {
  componentDidMount() {
    document.title = "FAHH-faq";
  }
  render() {
    return (
      <>
        <div className="faq_page_content support_page_content account_all_page">
          <Container>
            <Row className="support_page_left_right_content">
              <AccountSlideBar />
              <Col lg={9}>
                <div className="support_page_right_content account_all_right_content">
                  <div className="support_page_active_bid account_all_right_section ">
                    <div className="account_all_right_content_heading d-flex pb-4">
                      <div className="all_acount_heading_dropdown pl-0">
                        {/* <h4 className="mb-0">FAQ</h4> */}
                      </div>
                    </div>
                    <div className="support_no_activity">
                      <Accordion defaultActiveKey="0">
                        <Card>
                          <Card.Header className="bg-white p-0">
                            <Accordion.Toggle
                              className="d-flex align-items-center w-100 text-left text-decoration-none"
                              as={Button}
                              variant="link"
                              eventKey="0"
                            >
                              <p className="mb-0 mr-auto">
                                Lorem ipsum lorem ipsum
                              </p>
                              <img src={BottomArrow} />
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="0">
                            <Card.Body>
                              <p className="mb-0">
                                Anim pariatur cliche reprehenderit, enim eiusmod
                                high life accusamus terry richardson ad squid. 3
                                wolf moon officia aute, non cupidatat skateboard
                                dolor brunch. Food truck quinoa nesciunt laborum
                                eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                                put a bird on it squid single-origin coffee
                                nulla assumenda shoreditch et. Nihil anim
                                keffiyeh helvetica, craft beer labore wes
                                anderson cred nesciunt sapiente ea proident. Ad
                                vegan excepteur butcher vice lomo. Leggings
                                occaecat craft beer farm-to-table, raw denim
                                aesthetic synth nesciunt you probably haven't
                                heard of them accusamus labore sustainable VHS.
                              </p>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                        <Card>
                          <Card.Header className="bg-white p-0">
                            <Accordion.Toggle
                              className="d-flex align-items-center w-100 text-left text-decoration-none"
                              as={Button}
                              variant="link"
                              eventKey="1"
                            >
                              <p className="mb-0 mr-auto">
                                Lorem ipsum lorem ipsum
                              </p>
                              <img src={BottomArrow} />
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body>
                              <p className="mb-0">
                                Anim pariatur cliche reprehenderit, enim eiusmod
                                high life accusamus terry richardson ad squid. 3
                                wolf moon officia aute, non cupidatat skateboard
                                dolor brunch. Food truck quinoa nesciunt laborum
                                eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                                put a bird on it squid single-origin coffee
                                nulla assumenda shoreditch et. Nihil anim
                                keffiyeh helvetica, craft beer labore wes
                                anderson cred nesciunt sapiente ea proident. Ad
                                vegan excepteur butcher vice lomo. Leggings
                                occaecat craft beer farm-to-table, raw denim
                                aesthetic synth nesciunt you probably haven't
                                heard of them accusamus labore sustainable VHS.
                              </p>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                        <Card>
                          <Card.Header className="bg-white p-0">
                            <Accordion.Toggle
                              className="d-flex align-items-center w-100 text-left text-decoration-none"
                              as={Button}
                              variant="link"
                              eventKey="2"
                            >
                              <p className="mb-0 mr-auto">
                                Lorem ipsum lorem ipsum
                              </p>
                              <img src={BottomArrow} />
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="2">
                            <Card.Body>
                              <p className="mb-0">
                                Anim pariatur cliche reprehenderit, enim eiusmod
                                high life accusamus terry richardson ad squid. 3
                                wolf moon officia aute, non cupidatat skateboard
                                dolor brunch. Food truck quinoa nesciunt laborum
                                eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                                put a bird on it squid single-origin coffee
                                nulla assumenda shoreditch et. Nihil anim
                                keffiyeh helvetica, craft beer labore wes
                                anderson cred nesciunt sapiente ea proident. Ad
                                vegan excepteur butcher vice lomo. Leggings
                                occaecat craft beer farm-to-table, raw denim
                                aesthetic synth nesciunt you probably haven't
                                heard of them accusamus labore sustainable VHS.
                              </p>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default Faq;
