import React, { Component, useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
// import CurrencyCountry from "../../as../../library/Requests/PlaceBid";
import { ReactComponent as CloseIcon } from "../../assets/image/closeIcon.svg";
import { getBidsPlacedbyItem } from "./../../library/Requests/PlaceBid";

const PrevBidDetails = (props: any) => {
  const [prevBidsList, setPrevBidsList] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);

  const { ShowPrevBids, hideModal, currencyName, collectionData } = props;
  useEffect(() => {
    if (ShowPrevBids) {
      getAllPrevBids();
    }
  }, [ShowPrevBids]);
  const getAllPrevBids = async () => {
    if (props?.lotId) {
      setPrevBidsList([]);
      setIsLoading(true);
      const resp = await getBidsPlacedbyItem(props?.lotId);
      setPrevBidsList(resp?.data);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Modal
        show={ShowPrevBids}
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        className="CustomModal"
        centered
        contentClassName="custom-modal-style"
      >
        <Modal.Header className="CurrencyModalHeader">
          <Modal.Title
            className="currency-title-main-sec"
            id="example-modal-sizes-title-lg"
          >
            Bid History
          </Modal.Title>
          <CloseIcon className="modalCloseIcon" onClick={hideModal} />
        </Modal.Header>
        <Modal.Body className="CurrencyShowDiv CurrencyShowDiv-sub-title">
          <div className="currencydiv1">
            {isLoading && (
              <>
                <p className="is-page-loading-bid-history">Loading...</p>
              </>
            )}
            <table className="table table-striped currencyTable">
              <tbody className="CurrencyTableBody">
                {(prevBidsList || [])?.map((item: any, index: any) => {
                  return (
                    <tr
                      className="CountryTr"
                      // onClick={() => {
                      //   handleSelectCurrency(item);
                      // }}
                    >
                      <td colSpan={2} className="CountryTd">
                        <div className="prev-bid-details-sec">
                          <span className="CountryCodeSpan1">
                            {item?.biddingId}
                            {collectionData?.registrationNo === item?.biddingId
                              ? ` (You)`
                              : null}
                          </span>
                          <span className="CountryNameSpan1">{`${item?.biddingPrice?.toLocaleString()} ${currencyName}`}</span>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default PrevBidDetails;
