import { get, post, put, del, freshDeskPost, freshDeskGet } from "./helpers";
import { API_BASE, SERVER_IP } from "../constants";
const freshDeskUrl = "https://fahh.freshdesk.com/api";
export const getcustomerfollowedbidsCall = async (id) => {
  return await get(
    `${SERVER_IP}${API_BASE}/Bid/getcustomerfollowedbids?CustomerId=${id}&Page=1&PageSize=50`
  );
};
export const getcustomerbidsCall = async (id) => {
  return await get(
    `${SERVER_IP}${API_BASE}/Bid/getcustomerbids?CustomerId=${id}&Page=1&PageSize=50`
  );
};
export const getcustomerpurchasedbidsCall = async (id) => {
  return await get(
    `${SERVER_IP}${API_BASE}/Order/getcustomerPruchases?CustomerId=${id}&Page=1&PageSize=50`
  );
};
export const getPaymentDetailsCall = async (id) => {
  return await get(
    `${SERVER_IP}${API_BASE}/Order/getPaymentDetails?OrderId=${id}`
  );
};
export const addcustomeraddressCall = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/Customer/addcustomeraddress`,
    body
  );
};
export const editcustomeraddressCall = async (body) => {
  return await put(
    `${SERVER_IP}${API_BASE}/Customer/editcustomeraddress`,
    body
  );
};
export const getcustomeraddressCall = async (id) => {
  return await get(
    `${SERVER_IP}${API_BASE}/Customer/getcustomeraddress?CustomerId=${id}`
  );
};
export const changepasswordCall = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/Customer/changepassword`, body);
};
export const forgotPasswordCall = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/Customer/forgotPassword`, body);
};
export const deletecustomeraddressCall = async (id) => {
  const jsonB = { id };
  return await put(
    `${SERVER_IP}${API_BASE}/Customer/deletecustomeraddress?id=${id}`,
    jsonB
  );
};
export const makeOrderCall = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/Order/makeOrder`, body);
};
export const payNowCall = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/CheckOut/PayNow`, body);
};
export const verifyEmailAccountCall = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/Customer/resendEmailverification`,
    body
  );
};

export const updateCustomerpreferenceCall = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/Settings/updateCustomerpreference`,
    body
  );
};
export const getCustomerEnabledPreferenceCall = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/Settings/getCustomerEnabledPreference`,
    body
  );
};

export const getAllPreferenceCall = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/Settings/getAllMessageGroup`,
    body
  );
};
export const saveEnquiryFormDataCall = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/Settings/submitEnquiry`, body);
};
export const saveSupportQueryDataCall = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/Support/submitcustomerquery`,
    body
  );
};
export const getSupportQueryDataCall = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/Support/GetQueriesByCustomer`,
    body
  );
};
export const submitforNewsLetterCall = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/Settings/submitforNewsLetter`,
    body
  );
};

export const getAllQueriesall = async (body) => {
  return await get(`${SERVER_IP}${API_BASE}/Support/getQueries`);
};

export const updateSalesTaxCall = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/Order/updateSalesTaxInfo`, body);
};
export const updateShippingChargesCall = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/Order/updateShippingCharges`,
    body
  );
};

export const verifyemailConfirmCall = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/Customer/verifyemailConfirm`,
    body
  );
};
export const handleCreateTicketCall = async (body) => {
  return await freshDeskPost(`${freshDeskUrl}/v2/tickets`, body);
};
export const getAllTicketsCall = async (id) => {
  return await freshDeskGet(
    `${freshDeskUrl}/v2/tickets?email=${id}&include=description`
  );
};
export const getSelectedTicketView = async (id) => {
  return await freshDeskGet(`${freshDeskUrl}/v2/tickets/${id}/conversations`);
};
export const sendReplayClickMessage = async (body) => {
  const ticketId = body?.id;
  delete body?.id;
  return await freshDeskPost(
    `${freshDeskUrl}/v2/tickets/${ticketId}/reply`,
    body
  );
};
