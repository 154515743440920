import React, { useEffect, useState } from "react";

import { AccountActivationStyled } from "./AccountActivation.styled";
import { Container, Row, Col, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";

import { ReactComponent as PasswordCrossIcon } from "./../../assets/image/passwordCross.svg";
import { ReactComponent as PasswordTickIcon } from "./../../assets/image/passwordTick.svg";
import { verifyemailConfirmCall } from "../../library/Requests/MyAccount";
import CustomModal from "../../components/CustomModel";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AccountActivation = () => {
  const [userPassword, setUserPassword] = useState("");
  const [userPasswordError, setUserPasswordError] = useState("");
  const [userConformPassword, setUserConformPassword] = useState("");
  const [userConformPasswordError, setConformUserPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const query = useQuery();
  const token = query.get("token");
  const guid = query.get("guid");
  const email = query.get("email");

  useEffect(() => {
    checkAccountValid();
  }, []);

  const checkAccountValid = async () => {
    if (guid) {
      const jsonV = {
        token: token ? token : "",
        guid: guid,
      };
      const newResp = await verifyemailConfirmCall(jsonV);
      console.log("newResp", newResp);
      if (newResp?.success) {
        setShowSuccessMessage(true);
        // window.location.href = "/";
      } else if (newResp?.errors[0]) {
        setErrorMessage(newResp?.errors[0]);
      }
    }
  };
  return (
    <>
      <AccountActivationStyled>
        <div className="Create-New-password-sec">
          <div className="create-header">Account Activation</div>
          {showSuccessMessage && (
            <div className="create-sub-header">
              Your Account has beed verified Please click{" "}
              <a href="/login">here</a> to login.
            </div>
          )}
          <div className="create-sub-header"> {errorMessage}</div>
        </div>
      </AccountActivationStyled>{" "}
    </>
  );
};
export default AccountActivation;
