const ACH = () => {
  return (
    <>
      ACH
      {/* <div>
        <fieldset className="buyer-inputs">
          <input
            type="text"
            autocomplete="given-name"
            aria-required="true"
            aria-label="First Name"
            required="required"
            placeholder="Given Name"
            name="givenName"
            spellcheck="false"
          />

          <input
            type="text"
            autocomplete="family-name"
            aria-required="true"
            aria-label="Last Name"
            required="required"
            placeholder="Family Name"
            name="familyName"
            spellcheck="false"
          />
        </fieldset>
        <button id="ach-button">Pay with Bank Account</button>
      </div>
      <div>
        <body>
          <fieldset class="buyer-inputs">
            <input
              type="text"
              autocomplete="given-name"
              aria-required="true"
              aria-label="First Name"
              required="required"
              placeholder="Given Name"
              name="givenName"
              spellcheck="false"
            />

            <input
              type="text"
              autocomplete="family-name"
              aria-required="true"
              aria-label="Last Name"
              required="required"
              placeholder="Family Name"
              name="familyName"
              spellcheck="false"
            />
          </fieldset>
          <button id="ach-button">Pay with Bank Account</button>

          <form id="payment-form">
            <div id="card-container"></div>
            <button id="card-button" type="button">
              Pay $1.00
            </button>
          </form>
          <div id="payment-status-container"></div>
        </body>
      </div> */}
    </>
  );
};
export default ACH;
