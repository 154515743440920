import React, { useState } from "react";
import { Popover, PopoverBody } from "reactstrap";
import InfoIcon from "../../assets/image/icon3.png";
import CloseIcon from "../../assets/image/closeIcon.svg";
import "./popover.css";
const ActiveBidInfo = (props: any) => {
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);

  return (
    <div className="popover-sec">
      <button
        id={`Popover${props.index}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsPopOverOpen(!isPopOverOpen);
        }}
        className="button-popover"
      >
        {<img src={props.image} className="pop-icon" />}
      </button>
      <div className="popover-sec bid-popover poper-section">
        <Popover
          placement={props?.position}
          isOpen={isPopOverOpen}
          target={`Popover${props.index}`}
          trigger="legacy"
          toggle={() => setIsPopOverOpen(!isPopOverOpen)}
          className={`popover-product ${props.class}  common-info-popover`}
        >
          <PopoverBody className=" inside-section">
            <>
              {props.popoverText && (
                <p className="bid-popover-text">{props.popoverText}</p>
              )}
              <img
                src={CloseIcon}
                className="bid-close-icon-button"
                alt="close-icon"
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsPopOverOpen(!isPopOverOpen);
                }}
              />
            </>
          </PopoverBody>
        </Popover>
      </div>
    </div>
  );
};

export default ActiveBidInfo;
