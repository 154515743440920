import React, { useEffect, useState } from "react";

import { CreateNewPasswordStyled } from "./CreateNewPassword.styled";
import { Container, Row, Col, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
import Pass_hide from "./../../assets/password-hide.png";
import Pass_show from "./../../assets/password-show.png";

import { ReactComponent as PasswordCrossIcon } from "./../../assets/image/passwordCross.svg";
import { ReactComponent as PasswordTickIcon } from "./../../assets/image/passwordTick.svg";
import { changepasswordCall } from "./../../library/Requests/MyAccount";
import CustomModal from "./../../components/CustomModel";
import { triggerMixpanelEvent } from "./../../Scenes/common";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CreateNewPassword = () => {
  const [userPassword, setUserPassword] = useState("");
  const [userPasswordError, setUserPasswordError] = useState("");
  const [userConformPassword, setUserConformPassword] = useState("");
  const [userConformPasswordError, setConformUserPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const query = useQuery();
  const token = query.get("token");
  const guid = query.get("guid");
  const email = query.get("email");

  const ksonPass = {
    charLength: false,
    specialChar: false,
    numberLength: false,
  };
  const [passwordValidation, setPasswordValidation] = useState(ksonPass);
  useEffect(() => {
    triggerMixpanelEvent("create_new_password_page");
  }, []);
  useEffect(() => {
    if (userPassword != "") {
      let charLength1 = userPassword.length >= 10 ? true : false;
      const regex = /\d/;
      const regex1 = /[!@#$%^&*_+]/g;
      setPasswordValidation({
        specialChar: regex1.test(userPassword),
        numberLength: regex.test(userPassword),
        charLength: charLength1,
      });
    }
  }, [userPassword]);
  const validateSubmit = () => {
    var retFl = true;
    if (
      userPassword &&
      userConformPassword &&
      passwordValidation.specialChar &&
      passwordValidation.numberLength &&
      passwordValidation.charLength &&
      userPassword == userConformPassword &&
      !isLoading
    ) {
      retFl = false;
    }
    return retFl;
  };
  const handleSubmit = async () => {
    if (!validateSubmit()) {
      const jsonV = {
        token: token,
        email: email,
        oldPassword: "",
        newPassword: userPassword,
        guid: guid,
      };
      setIsLoading(true);
      const newResp = await changepasswordCall(jsonV);
      console.log("newResp", newResp);
      setIsLoading(false);
      if (newResp?.success) {
        setShowSuccessMessage(true);
        window.location.href = "/";
      } else if (newResp?.errors[0]) {
        setErrorMessage(newResp?.errors[0]);
      }
    }
  };
  const togglePassword = () => {
    if (passwordType === "text") {
      setPasswordType("password");
    } else {
      setPasswordType("text");
    }
  };

  return (
    <>
      <CreateNewPasswordStyled>
        <div className="Create-New-password-sec">
          <div className="create-header">Create a New Password</div>
          <div className="create-sub-header">
            Your password should have at least 10 characters with at least one
            number and one special character.
          </div>
          <div className="create-password-sec">
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label>Password</Form.Label>
                  <div
                    className={
                      userPasswordError
                        ? "password-sec-con create-password-sec-error"
                        : "password-sec-con"
                    }
                  >
                    <Form.Control
                      type={passwordType}
                      name="password"
                      value={userPassword}
                      onChange={(e) => {
                        setUserPassword(e.target.value);
                        setErrorMessage("");
                      }}
                      isInvalid={userPasswordError ? true : false}
                    />
                    <div className="input-group-btn">
                      <span className="btn" onClick={togglePassword}>
                        {passwordType === "password" ? (
                          <img className="password-hide-img" src={Pass_show} />
                        ) : (
                          <img className="password-hide-img" src={Pass_hide} />
                        )}
                      </span>
                    </div>
                  </div>

                  <Form.Control.Feedback type="invalid">
                    {userPasswordError ? <>{userPasswordError}</> : null}
                  </Form.Control.Feedback>
                  {/* <div onClick={() => setFooterCollapseOne(!footerCollapseOne)}>123</div> */}
                  <Form.Text className="">
                    {/* <Link to="/sign-up" className="close-icon mr-1">
                     </Link> */}
                    <span className="close-icon mr-1">
                      {passwordValidation.charLength ? (
                        <PasswordTickIcon />
                      ) : (
                        <PasswordCrossIcon />
                      )}
                    </span>
                    At least 10 characters
                  </Form.Text>
                  <Form.Text className="">
                    <span className="close-icon mr-1">
                      {passwordValidation.numberLength ? (
                        <PasswordTickIcon />
                      ) : (
                        <PasswordCrossIcon />
                      )}
                    </span>
                    At least one number
                  </Form.Text>
                  <Form.Text className="">
                    <span className="close-icon mr-1">
                      {passwordValidation.specialChar ? (
                        <PasswordTickIcon />
                      ) : (
                        <PasswordCrossIcon />
                      )}
                    </span>
                    One special character(!@#$%^&*_+)
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      setErrorMessage("");
                      setUserConformPassword(e.target.value);
                    }}
                    type="password"
                    value={userConformPassword}
                    isInvalid={userConformPasswordError ? true : false}
                    name="confirmPassword"
                  />
                  <Form.Control.Feedback type="invalid">
                    {userConformPasswordError ? (
                      <>{userConformPasswordError}</>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </div>
          {errorMessage && <p className="error_msg">{errorMessage}</p>}
          <div className="create-submit-sec">
            <button disabled={validateSubmit()} onClick={handleSubmit}>
              {isLoading ? "LOADING" : "SUBMIT"}
            </button>
          </div>
        </div>
        <CustomModal
          show={showSuccessMessage}
          titleText={`You have been successfully changed the Password. Please login to continue`}
          deleteText="OK"
          cancelText=""
          onCancel={() => {}}
          onDelete={() => {
            setShowSuccessMessage(false);
            window.location.href = "/";
          }}
        />
      </CreateNewPasswordStyled>{" "}
    </>
  );
};
export default CreateNewPassword;
