import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { Container, Row, Col, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { isMobile } from "react-device-detect";

interface Props {
  currentStep: any;
  handleChange: any;
  password: any;
  handleChangeAddress: any;
  handleChangePhoto: any;
  idImageError?: any;
  addressImageError?: any;
}

// function Step3(props: any) {
export const Step3: React.FC<Props> = (props: any) => {
  const [fileNameInputOne, setFileNameInputOne] = useState(null);
  const [fileNameInputTwo, setFileNameInputTwo] = useState(null);

  useEffect(() => {
    localStorage.removeItem("photoIDURL");
    localStorage.removeItem("addressProofURL");
  }, []);
  if (props.currentStep !== 3) {
    return null;
  }

  return (
    <Row>
      <Col sm={12}>
        <h6 className="d-md-block d-none">
          To proceed with your registration, please upload a valid copy of your
          government issued identification (e.g., Driver’s License, Passport,
          State-Issued Photo Identification etc.). If you need further
          assistance, please contact{" "}
          <a href="mailto:support@fineart.hiphop">support@fineart.hiphop</a>
          {/* <a href="mailto:support@fineart.hiphop">support@fineart.hiphop</a>{" "} */}
          .
        </h6>
        <Form.File id="formcheck-api-regular1">
          <Form.File.Label>
            Upload Government Issued Photo Identification
          </Form.File.Label>
          <div className="custom_upload_file">
            <Button className="d-flex align-items-center">
              <svg
                className="d-md-none d-inline-block mr-1"
                width="14"
                height="12"
                viewBox="0 0 14 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.66671 10.6667H12.3334V6H13.6667V11.3333C13.6667 11.5101 13.5965 11.6797 13.4714 11.8047C13.3464 11.9298 13.1769 12 13 12H1.00004C0.82323 12 0.65366 11.9298 0.528636 11.8047C0.403612 11.6797 0.333374 11.5101 0.333374 11.3333V6H1.66671V10.6667ZM7.66671 4V8.66667H6.33338V4H3.00004L7.00004 0L11 4H7.66671Z"
                  fill="white"
                ></path>
              </svg>
              CHOOSE FILE
            </Button>
            {!props?.idImageError && (
              <p className="d-md-block d-none1 file-name-sec-account">
                {fileNameInputOne}
              </p>
            )}
            {!fileNameInputOne && (
              <p className="d-md-block d-none">or drop files here</p>
            )}
            <Form.File.Input
              accept="image/png, image/jpeg"
              onChange={(e: any) => {
                setFileNameInputOne(e.target.files[0]?.name);
                props?.handleChangePhoto(e);
              }}
            />
            {props?.idImageError && (
              <p className="login_error_msg">{props?.idImageError}</p>
            )}
          </div>
        </Form.File>
        {isMobile ? (
          <h6 className="d-md-block MobileRegistrationText">
            To proceed with your registration, please upload a valid copy of
            your government issued identification (e.g., Driver’s License,
            Passport, State-Issued Photo Identification etc.). If you need
            further assistance, please contact{" "}
            <a href="mailto:support@fineart.hiphop">support@fineart.hiphop</a>
          </h6>
        ) : (
          ""
        )}
      </Col>
      <Col sm={12}>
        <h6 className="d-md-block d-none">
          Please upload a document showing your name and the residential address
          on your profile (e.g., a utility bill or bank statement etc.) dated
          within the last 3 months.
        </h6>
        <Form.File id="formcheck-api-regular2">
          <Form.File.Label>Proof of Address</Form.File.Label>
          <div className="custom_upload_file">
            <Button className="d-flex align-items-center">
              <svg
                className="d-md-none d-inline-block mr-1"
                width="14"
                height="12"
                viewBox="0 0 14 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.66671 10.6667H12.3334V6H13.6667V11.3333C13.6667 11.5101 13.5965 11.6797 13.4714 11.8047C13.3464 11.9298 13.1769 12 13 12H1.00004C0.82323 12 0.65366 11.9298 0.528636 11.8047C0.403612 11.6797 0.333374 11.5101 0.333374 11.3333V6H1.66671V10.6667ZM7.66671 4V8.66667H6.33338V4H3.00004L7.00004 0L11 4H7.66671Z"
                  fill="white"
                ></path>
              </svg>
              CHOOSE FILE
            </Button>
            {!props?.addressImageError && (
              <p className="d-md-block d-none1 file-name-sec-account">
                {fileNameInputTwo}
              </p>
            )}

            {!fileNameInputTwo && (
              <p className="d-md-block d-none">or drop files here</p>
            )}
            <Form.File.Input
              accept="image/png, image/jpeg"
              onChange={(e: any) => {
                setFileNameInputTwo(e.target.files[0]?.name);
                props?.handleChangeAddress(e);
              }}
            />
            {props?.addressImageError && (
              <p className="login_error_msg">{props?.addressImageError}</p>
            )}
          </div>
        </Form.File>
        {isMobile ? (
          <h6 className="d-md-block MobileRegistrationText">
            Please upload a document showing your name and the residential
            address on your profile (e.g., a utility bill or bank statement
            etc.) dated within the last 3 months.
          </h6>
        ) : (
          ""
        )}
      </Col>
    </Row>
  );
};

export default Step3;
