import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "../../assets/image/closeIcon.svg";
import { ReactComponent as Infinity } from "../../assets/image/infinity-symbol-SVG.svg";
import { WelcomeModelStyled } from "./WelcomeModel.styled";
import { isMobile } from "react-device-detect";

export default class WelcomeModel extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }
  render() {
    const { showWelcomeModel, hideModal, gotoSignupPage } = this.props;

    return (
      <div>
        <Modal
          show={showWelcomeModel}
          size="lg"
          aria-labelledby="example-modal-sizes-title-lg"
          className="CustomModal "
          centered
          contentClassName="custom-modal-style bidIncrementModal welcome-model-popup-main-sec"
        >
          <WelcomeModelStyled>
            <Modal.Header className="welcome-model-header-text">
              <CloseIcon className="modalCloseIcon" onClick={hideModal} />
            </Modal.Header>
            <Modal.Body className="welcome-model-popup">
              <div className="welcome-model-popup-con">
                <div className="welcome-model-register-text">
                  <p>
                    Welcome to FineArt.HipHop - the only marketplace and auction
                    platform dedicated to Hip Hop inspired fine art and
                    collectibles.
                  </p>
                  <p>
                    If you are interested in bidding in the{" "}
                    <span>50 Years of Hip Hop Flyers</span> auction, please
                    click the Register button below to register to bid.{" "}
                  </p>
                </div>
                <div className="welcome-model-register-btn">
                  {/* <button className="btn-close" onClick={hideModal}>
                    CLOSE
                  </button> */}
                  <button className="btn-register" onClick={gotoSignupPage}>
                    REGISTER
                  </button>
                </div>
              </div>
            </Modal.Body>
          </WelcomeModelStyled>
          {/* <Modal.Footer className="CustomFooter">
            <Button className="FooterCloseBtn" onClick={hideModal}>
              Close
            </Button>
          </Modal.Footer> */}
        </Modal>
      </div>
    );
  }
}
