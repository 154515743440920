import React from "react";

import { Link } from "react-router-dom";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import noImage from "../../assets/image/noImage.jpg";

import moment from "moment";
import CardItem from "./CardItem";
const MobileView = (props: any) => {
  const { onGoingAuctions } = props;
  const responsive = {
    desktop: {
      breakpoint: { max: 1920, min: 1400 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1399, min: 1100 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 1920, min: 1400 },
      items: 3,
      slidesToSlide: 1,
    },
  };

  return (
    <>
      <div className="mobile-view-item">
        <Carousel>
          {(onGoingAuctions || []).map((item: any, index: any) => {
            return <CardItem showArrows={false} item={item} />;
          })}
        </Carousel>
      </div>
    </>
  );
};
export default MobileView;
