import { Link } from "react-router-dom";
import "./style.css";
import UpcomingActionSlider from "../../components/UpcomingActionSlider";
import ActiveActionsSlider from "../../components/ActiveActionsSlider";
import { ActionPageStyled } from "./ActionPageStyled.styled";

const ActionPage = (props: any) => {
  const { AllCollections, upCommingAuctions, handleSucessafterRegister,userDetails } =
    props;

  // selectActionType = (e: any) => {
  //   const { AllCollections, upCommingAuctions } = this.state;
  //   const upCommingFull = AllCollections?.upCommingAuctions;
  //   const Atype = e.target.value;

  //   if (Atype == "all") {
  //     this.setState({ upCommingAuctions: upCommingFull });
  //   } else if (Atype == "online") {
  //     let finaalAction = upCommingFull.filter(
  //       (item: any) => item.auctionType == "Online Auction"
  //     );
  //     this.setState({ upCommingAuctions: finaalAction });
  //   } else if (Atype == "live") {
  //     let finaalAction = upCommingFull.filter(
  //       (item: any) => item.auctionType == "Live Auction"
  //     );
  //     this.setState({ upCommingAuctions: finaalAction });
  //   }
  // };
  const activeCount = AllCollections?.onGoingAuctions?.length ?? 0;
  const upCommingAuctionsCount = upCommingAuctions?.length ?? 0;
  return (
    <ActionPageStyled>
      {activeCount > 0 && (
        <>
          <div className="custom_slider_heading_filter d-flex align-items-center">
            <h3 className="mb-0 mr-auto position-relative pl-3">
              Active Auctions
            </h3>
            {AllCollections?.onGoingAuctions?.length > 1 && (
              <Link
                to="/auction-calendar?selectedTab=calender&subTab=active"
                className="view_all_btn upcoming_view_all_btn"
              >
                View All
              </Link>
            )}
          </div>
          <ActiveActionsSlider
            AllCollections={AllCollections}
            handleSucessafterRegister={handleSucessafterRegister}
          />
        </>
      )}
      {/* <div className="custom_slider_heading_filter d-flex align-items-center">
          <h3 className="mb-0 mr-auto position-relative pl-3">
            Active Auctions
          </h3>
        </div> */}
      {activeCount === 0 && !props.isLoader && (
        <div className="tank-you-page-sec">
          <p>THANK YOU FOR MAKING OUR FIRST AUCTION A SUCCESS!</p>

          <p>WE’LL BE BACK SOON WITH DATES AND TIMES FOR OUR NEXT AUCTIONS.</p>
        </div>
      )}

      {upCommingAuctionsCount > 0 && (
        <>
          <div className="custom_slider_heading_filter d-flex align-items-center">
            <h3 className="mb-0 mr-auto position-relative pl-3">
              Upcoming Auctions
            </h3>
            <div className="custom_slider_heading_filter_content d-flex align-items-center justify-content-end">
              {/* <Form.Group
                  controlId="exampleForm.ControlSelect1"
                  className="d-flex align-items-center mb-0 w-100"
                >
                  <Form.Label className="w-100 text-right mr-3 mb-0">
                    Filter By
                  </Form.Label>
                  <Form.Control
                    as="select"
                    onChange={this.selectActionType}
                    className="shadow-none"
                  >
                    <option value="all">All</option>
                    <option value="online">Online</option>
                    <option value="live">Live</option>
                  </Form.Control>
                </Form.Group> */}
              {upCommingAuctionsCount > 1 && (
                <Link
                  to="/auction-calendar?selectedTab=calender&subTab=upcomming"
                  className="view_all_btn upcoming_view_all_btn upcoming-btn"
                >
                  View All
                </Link>
              )}
            </div>
          </div>
          {upCommingAuctions?.length == 0 ? (
            <div>No Results Found</div>
          ) : (
            <UpcomingActionSlider
              upCommingAuctions={upCommingAuctions}
              handleSucessafterRegister={handleSucessafterRegister}
            />
          )}
        </>
      )}
    </ActionPageStyled>
  );
};

export default ActionPage;
