import React from "react";

import { Link, withRouter } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import { Col } from "react-bootstrap";

import "./css/AccountSlideBar.css";

class AccountSlideBar extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  logoutHandle = () => {
    const code1 = localStorage.getItem("gateCode2") as any;
    const code2 = localStorage.getItem("welcomeModelText") as any;
    localStorage.removeItem("customerInfoDetails");
    localStorage.clear();
    localStorage.setItem("gateCode2", code1);
    localStorage.setItem("welcomeModelText", code2);
    // this.props.history.push(`/`)
    window.location.href = "/";
  };

  render() {
    const itemsOne = [
      {
        id: 1,
        sidebarLinkName: "Profile",
        linksTitle: "profile",
        links: "/profile",
      },
      // {
      //   id: 2,
      //   sidebarLinkName: "Bids",
      //   linksTitle: "bids",
      //   links: "/myBids",
      // },
      {
        id: 3,
        sidebarLinkName: "Purchases",
        linksTitle: "purchases",
        links: "/myPurchases",
      },
      {
        id: 4,
        sidebarLinkName: "My Followed Lots",
        linksTitle: "followedlots",
        links: "/myFollowedLots",
      },
      {
        id: 5,
        sidebarLinkName: "Email Preferences",
        linksTitle: "emailpreferences",
        links: "/emailpreferences",
      },
      {
        id: 5,
        sidebarLinkName: "Customer Support",
        linksTitle: "support",
        links: "/support",
      },
      // {
      //   id: 6,
      //   sidebarLinkName: "Freshdesk Create Ticket",
      //   linksTitle: "freshdeskCreateTicket",
      //   links: "/freshdeskCreateTicket",
      // },
    ];
    const itemsTwo = [] as any;

    return (
      <>
        <div className="bids_page_main_heading w-100">
          <h2 className="mb-0">My Account</h2>
        </div>
        <Col lg={3}>
          <div className="more_collection_colum d-flex d-lg-block">
            <ListGroup className="custom_account_list_sidebar mr-lg-0 mr-auto">
              {itemsOne.map((i1) => {
                return (
                  <ListGroup.Item action href={i1.links} title={i1.linksTitle}>
                    {i1.sidebarLinkName}
                  </ListGroup.Item>
                );
              })}
              <ListGroup.Item
                onClick={this.logoutHandle}
                className="logout_btn"
                title="Logout"
              >
                Logout
              </ListGroup.Item>
            </ListGroup>
            {/* <ListGroup className="custom_account_list_sidebar custom_account_second_list_sidebar">
              {itemsTwo.map((i2: any) => {
                return (
                  <ListGroup.Item action href={i2.links} title={i2.linksTitle}>
                    {i2.sidebarLinkName}
                  </ListGroup.Item>
                );
              })}
              <ListGroup.Item
                onClick={this.logoutHandle}
                className="logout_btn"
                title="Logout"
              >
                Logout
              </ListGroup.Item>
            </ListGroup> */}
          </div>
        </Col>
      </>
    );
  }
}
export default withRouter(AccountSlideBar);
