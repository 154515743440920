import React, { useState } from "react";

import Modal from "react-bootstrap/Modal";
import { Button, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import { ReactComponent as PasswordCrossIcon } from "./../../assets/image/passwordCross.svg";
import { ReactComponent as PasswordTickIcon } from "./../../assets/image/passwordTick.svg";
import Pass_hide from "./../../assets/password-hide.png";
import Pass_show from "./../../assets/password-show.png";

import { changepasswordCall } from "./../../library/Requests/MyAccount";

export const ChangePasswordModal: React.FC = () => {
  const [show, setShow] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");

  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [charLength, setCharLength] = useState(false);
  const [numberLength, setNumberLength] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  // const [conformButton,setConformButton] = useState(true);
  const [passwordError, setPasswordError] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onConfirm = async () => {
    if (validateNewpassword(newPassword, confirmNewPassword)) {
      const userD = localStorage.getItem("customerInfoDetails");
      if (userD != undefined && userD != null && userD != "") {
        const userIn = JSON.parse(userD);
        if (userIn?.email) {
          const body = {
            email: userIn?.email,
            newPassword: newPassword,
            oldPassword: currentPassword,
          };
          const resp = await changepasswordCall(body);
          if (resp?.success) {
            setConfirmShow(true);
            setShow(false);
          } else if (resp.errors.length > 0) {
            setPasswordError(resp.errors[0]);
          }
        }
      }
    }
  };
  const onConfirmModalClose = () => {
    setConfirmShow(false);
  };
  const updateValues = (e: any) => {
    const { name, value } = e.target;
    if (name == "newPassword") {
      setNewPassword(value);
      let charLength1 = value.length >= 10 ? true : false;
      const regex = /\d/;
      const regex1 = /[!@#$%^&*_+]/g;
      setSpecialChar(regex1.test(value));
      setNumberLength(regex.test(value));
      setCharLength(charLength1);
      validateNewpassword(value, confirmNewPassword);
    } else {
      setConfirmNewPassword(value);
      validateNewpassword(newPassword, value);
    }
  };
  const togglePassword = () => {
    if (passwordType === "text") {
      setPasswordType("password");
    } else {
      setPasswordType("text");
    }
  };
  const togglePassword1 = () => {
    if (passwordType1 === "text") {
      setPasswordType1("password");
    } else {
      setPasswordType1("text");
    }
  };

  const validateNewpassword = (newp: any, confirmNewPass: any) => {
    if (newp != "" && confirmNewPass != "") {
      if (newp === confirmNewPass) {
        setPasswordError("");
        return true;
      } else {
        setPasswordError("New Password and Confirm New Password do not match.");
        return false;
      }
    }
  };

  let conformButton = true;

  if (
    confirmNewPassword &&
    currentPassword &&
    newPassword &&
    passwordError == "" &&
    charLength &&
    numberLength &&
    specialChar
  ) {
    conformButton = false;
  } else {
    conformButton = true;
  }

  return (
    <>
      <Button variant="outline-primary" className="btn-md" onClick={handleShow}>
        CHANGE PASSWORD
      </Button>

      <Modal
        centered
        show={show}
        onHide={handleClose}
        className="custom_modal_box custom_magage_profile_modal"
      >
        <Modal.Body>
          <h3>Edit Password</h3>
          <Form>
            <Row>
              <Col md={10}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Current Password*</Form.Label>
                  <div className="password-sec-con">
                    <Form.Control
                      type={passwordType}
                      value={currentPassword}
                      onChange={(e) => {
                        setCurrentPassword(e.target.value);
                        setPasswordError("");
                      }}
                      placeholder="Current Password"
                    />
                    <div className="input-group-btn">
                      <span className="btn" onClick={togglePassword}>
                        {passwordType === "password" ? (
                          <img className="password-hide-img" src={Pass_show} />
                        ) : (
                          <img className="password-hide-img" src={Pass_hide} />
                        )}
                      </span>
                    </div>
                  </div>
                </Form.Group>
              </Col>
              <Col md={10}>
                <Form.Group controlId="exampleForm.ControlInput2">
                  <Form.Label>Enter New Password*</Form.Label>
                  <div className="password-sec-con">
                    <Form.Control
                      type={passwordType1}
                      name="newPassword"
                      value={newPassword}
                      onChange={(e) => {
                        updateValues(e);
                      }}
                      placeholder="Enter New Password"
                    />
                    <div className="input-group-btn">
                      <span className="btn" onClick={togglePassword1}>
                        {passwordType1 === "password" ? (
                          <img className="password-hide-img" src={Pass_show} />
                        ) : (
                          <img className="password-hide-img" src={Pass_hide} />
                        )}
                      </span>
                    </div>
                  </div>

                  <Form.Text className="">
                    <span className="close-icon mr-1">
                      {charLength ? (
                        <PasswordTickIcon />
                      ) : (
                        <PasswordCrossIcon />
                      )}
                    </span>
                    At least 10 characters
                  </Form.Text>
                  <Form.Text className="">
                    <span className="close-icon mr-1">
                      {numberLength ? (
                        <PasswordTickIcon />
                      ) : (
                        <PasswordCrossIcon />
                      )}
                    </span>
                    At least one number
                  </Form.Text>
                  <Form.Text className="">
                    <span className="close-icon mr-1">
                      {specialChar ? (
                        <PasswordTickIcon />
                      ) : (
                        <PasswordCrossIcon />
                      )}
                    </span>
                    One special character(!@#$%^&*_+)
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col md={10}>
                <Form.Group controlId="exampleForm.ControlInput2">
                  <Form.Label>Confirm New Password*</Form.Label>
                  <Form.Control
                    type="password"
                    name="confirmNewPassword"
                    value={confirmNewPassword}
                    onChange={(e) => {
                      updateValues(e);
                    }}
                    placeholder="Confirm New Password"
                  />
                </Form.Group>
              </Col>
              {passwordError != "" && (
                <div className="passwordErrorError">{passwordError}</div>
              )}
              <Col className="d-flex justify-content-between modal_btns">
                <Button
                  variant="outline-secondary close_modal_btn"
                  onClick={handleClose}
                >
                  CANCEL
                </Button>
                <Button
                  disabled={conformButton}
                  variant="outline-secondary save_change_modal_btn"
                  onClick={onConfirm}
                >
                  CONFIRM
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        show={confirmShow}
        onHide={handleClose}
        className="custom_modal_box custom_magage_profile_modal"
      >
        <Modal.Body>
          <h5 className="text-center w-100 mb-3">
            Your password has been successfully changed
          </h5>
          <Col className="d-flex justify-content-between modal_btns pt-0">
            <Button
              variant="outline-secondary save_change_modal_btn"
              className="ml-auto"
              onClick={onConfirmModalClose}
            >
              Ok
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ChangePasswordModal;
