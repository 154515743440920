import React, { useEffect } from "react";
import Header from "../Header";
import { Footer } from "../Footer";
import { useLocation } from "react-router-dom";
import { STATIC_GATE_CODE } from "./../../library/constants";
import { getGateCode, LOGINID } from "./../../Scenes/common";
import TagManager from "react-gtm-module";
import { GOOGLE_TAG_MANAGER_CODE } from "../../config";

const tagManagerArgs = {
  gtmId: GOOGLE_TAG_MANAGER_CODE,
  dataLayer: {
    userId: LOGINID,
  },
};
const BRLayout: React.FC = (props) => {
  const location = useLocation();
  const gateCode = getGateCode();
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <div className="wrapper">
      {location?.pathname.indexOf("/collectionDataPrint/") === -1 &&
        location?.pathname.indexOf("/collectionDataDownload/") === -1 &&
        gateCode === STATIC_GATE_CODE && <Header />}
      <div className="body-container-sec"> {props.children}</div>
      {location?.pathname.indexOf("/collectionDataPrint/") === -1 &&
        location?.pathname.indexOf("/collectionDataDownload/") === -1 &&
        location?.pathname.indexOf("live-auction-landing") === -1 &&
        gateCode === STATIC_GATE_CODE && <Footer />}
    </div>
  );
};

export default BRLayout;
