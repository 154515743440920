import {
  Col,
  Row,
  Collapse,
  InputGroup,
  Form,
  ColProps,
} from "react-bootstrap";
import { OffCanvas, OffCanvasMenu } from "react-offcanvas";
import { ReactComponent as Close } from "../../assets/close-icon.svg";
import filter_img from "../../assets/Frame.png";
import BrowseFilterList from "../../components/BrowseFilterList";

import React from "react";
import { useState } from "react";
import { BlobServiceClient } from "@azure/storage-blob";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

const FiltersSection = (props: any) => {
  const [file, setFile] = useState(null);
  const [filterShow, setFilterShow] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e: any) => {
    // e.preventDefault();
    // //process.env.REACT_APP_AZURE_STORAGE_CONNECTION_STRING
    // const blobServiceClient = new BlobServiceClient();
    // const blockBlobClient = blobServiceClient.getBlockBlobClient(file.name);
    // try {
    //   await blockBlobClient.uploadFile(file);
    //   console.log("File uploaded successfully");
    // } catch (err) {
    //   console.error(err);
    // }
  };

  return (
    <>
      <div>
        <span
          className="filter-sec-new-version"
          onClick={() => {
            setFilterShow(true);
          }}
        >
          Filters <img src={filter_img} />
        </span>
      </div>
      {!isMobile && (
        <OffCanvas
          width={420}
          transitionDuration={300}
          isMenuOpened={filterShow}
          position={"left"}
          effect={"parallax"}
          // @ts-ignore
          id={"mainPageFilter"}
          setFilterShow
        >
          <OffCanvasMenu
            className={`filter-overlay homepage-main-filter ${
              isMobile && "mobile-home-page-filter"
            }`}
          >
            <div className="fl_heading_sec">
              <div className="first_heading_wrap">
                <p className="fl_heading_h1">Filters</p>
                <Close onClick={() => setFilterShow(false)} />
              </div>
              <div>
                <BrowseFilterList
                  {...props}
                  filterShow={filterShow}
                  setFilterShow={setFilterShow}
                />
              </div>
            </div>
          </OffCanvasMenu>
        </OffCanvas>
      )}

      {isMobile && (
        <BrowseFilterList
          {...props}
          filterShow={filterShow}
          setFilterShow={setFilterShow}
        />
      )}
    </>
  );
};

export default FiltersSection;
