import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "../../assets/image/closeIcon.svg";
import { ReactComponent as Infinity } from "../../assets/image/infinity-symbol-SVG.svg";
import { BidIncrementTableStyled } from "./BidIncrementTable.styled";
import { isMobile } from "react-device-detect";

export default class BidIncrementTable extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }
  render() {
    const {
      ShowBidIncrementTable,
      hideModal,
      bidIncrementTableData,
      currencyName,
    } = this.props;

    this.props?.bidIncrementTableData?.pop();
    this.props?.bidIncrementTableData?.push({
      priceFrom: 10000000,
      priceTo: <Infinity className="infinitySymbolStyles" />,
      increment: 1000000,
    });

    return (
      <div>
        <Modal
          show={ShowBidIncrementTable}
          size="lg"
          aria-labelledby="example-modal-sizes-title-lg"
          className="CustomModal "
          centered
          contentClassName="custom-modal-style bidIncrementModal"
        >
          <BidIncrementTableStyled>
            <Modal.Header className="BidIncrementHeader">
              <Modal.Title className="BidIncrementTitle">
                <h1>Bid Increments Table</h1>
              </Modal.Title>
              <CloseIcon className="modalCloseIcon" onClick={hideModal} />
            </Modal.Header>
            <Modal.Body className="BidIncrementBody">
              <div className="BidIncrementdiv">
                <table className="table table-striped">
                  <thead className="tableHead">
                    {isMobile ? (
                      <>
                        {" "}
                        <th>From - To:</th>
                      </>
                    ) : (
                      <>
                        {" "}
                        <th>From:</th>
                        <th>To:</th>
                      </>
                    )}

                    <th>Increment:</th>
                    {/* <Infinity /> */}
                  </thead>
                  <tbody className="IncrementTableDiv">
                    {(bidIncrementTableData || [])?.map(
                      (item: any, index: any) => {
                        return (
                          <>
                            {item.priceFrom != "10000000" && (
                              <tr className="CountryTr " key={index}>
                                {isMobile ? (
                                  <>
                                    {" "}
                                    <td>
                                      {item.priceFrom?.toLocaleString()}
                                      {" - "}
                                      {item.priceTo?.toLocaleString()}{" "}
                                      {currencyName}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    <td>
                                      {item.priceFrom?.toLocaleString()}{" "}
                                      {currencyName}
                                    </td>
                                    <td>
                                      {" "}
                                      {item.priceTo?.toLocaleString()}{" "}
                                      {currencyName}
                                    </td>
                                  </>
                                )}

                                <td>
                                  {" "}
                                  {item.increment?.toLocaleString()}{" "}
                                  {currencyName}
                                </td>
                              </tr>
                            )}
                          </>
                        );
                      }
                    )}
                    <tr className="CountryTr ">
                      {isMobile ? (
                        <>
                          <td>
                            {` 100,001 +`} {currencyName}
                          </td>
                        </>
                      ) : (
                        <>
                          <td>
                            {` 100,001 +`} {currencyName}
                          </td>
                          <td></td>
                        </>
                      )}

                      <td>
                        {" "}
                        {`5,000`} {currencyName}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Modal.Body>
          </BidIncrementTableStyled>
          {/* <Modal.Footer className="CustomFooter">
            <Button className="FooterCloseBtn" onClick={hideModal}>
              Close
            </Button>
          </Modal.Footer> */}
        </Modal>
      </div>
    );
  }
}
