import React, { useState } from "react";

import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { verifyEmailAccountCall } from "./../../library/Requests/MyAccount";

import "./css/VerifyAccountModal.css";
interface Props {
  userData?: any;
  verifyEmail?: any;
}

export const VerifyAccountModal: React.FC<Props> = (Props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const verifyEmail = async () => {
    const newJO = {
      customerGuid: Props?.userData?.customerGuid,
      id: Props?.userData?.id,
      email: Props?.userData?.email,
    };
    const resp = await verifyEmailAccountCall(newJO);
    handleClose();
  };
  console.log("Props?.userData", Props?.userData);

  return (
    <>
      <Button variant="primary" className="btn-md" onClick={handleShow}>
        VERIFY ACCOUNT
      </Button>

      <Modal
        centered
        show={show}
        onHide={handleClose}
        className="custom_modal_box confirm_msg_text custom_magage_profile_modal"
      >
        <Modal.Body>
          <p className="text-center">
            We sent an email confirmation link to{" "}
            <Link to="/" className="text-decoration-none">
              {Props?.userData?.email}
            </Link>
            . Please confirm to enjoy full features of Fineart.hiphop
          </p>
        </Modal.Body>
        <Modal.Footer className="pb-0 pt-3">
          <div className="d-flex justify-content-center modal_btns pt-0 m-0 w-100">
            <Button
              onClick={() => {
                verifyEmail();
              }}
              variant="outline-secondary"
              className="close_modal_btn mr-4"
            >
              RESEND CONFIRMATION
            </Button>
            <Button
              variant="outline-secondary"
              className="save_change_modal_btn"
              onClick={handleClose}
            >
              CLOSE
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default VerifyAccountModal;
