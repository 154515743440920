import React, { useEffect } from "react";

const ShowCounter = ({
  days,
  hours,
  minutes,
  seconds,
  isMarketPlace,
  isLeastTimer,
  showProgressBarsec,
  card,
}: any) => {
  // const leastTimeType = () => {
  //   if (minutes <= 0) {
  //     return `${seconds} s`;
  //   } else if (hours <= 0) {
  //     return `${minutes} m`;
  //   } else if (days <= 0) {
  //     return `${hours} h`;
  //   } else {
  //     return `${days} ${days > 1 ? "days" : "day"}`;
  //   }
  // };
  useEffect(() => {
    if (days === 0 && hours == 0 && minutes == 0) {
      try {
        showProgressBarsec(true, seconds);
      } catch (error) {
        console.log("errorerror", error);
      }
    } else {
      showProgressBarsec(false, seconds);
    }
  }, [hours, minutes, seconds]);

  return (
    <>
      <span className={`timer-low-sec-count${days}${hours}`}>
        {days < 1
          ? `${(hours < 10 ? "0" : "") + hours}:${
              (minutes < 10 ? "0" : "") + minutes
            }:${(seconds < 10 ? "0" : "") + seconds}`
          : `${days} ${days > 1 ? "Days" : "Day"}`}
      </span>{" "}
    </>
  );
};
export const InspectionCounter = ({ days, hours, minutes, seconds }: any) => {
  return (
    <>
      {`${days ? days + " d : " : ""}${hours} h : ${minutes} m${
        seconds && " : " + seconds + " s"
      }`}
    </>
  );
};
export default ShowCounter;
