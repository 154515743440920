import styled from "styled-components";

export const WelcomeModelStyled = styled.div`
  .welcome-model-register-text p {
    color: #000;
    font-family: Noto Serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 40.45px;
    span {
      font-weight: 700;
    }
  }
  .welcome-model-header {
    text-align: right;
  }
  .welcome-model-register-text {
    padding: 20px;
  }
  .welcome-model-register-btn {
    display: flex;
    width: 100%;
    justify-content: center;
    border-top: 1px solid #999;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .welcome-model-register-btn button {
    padding: 14px 48px;
    align-items: center;
    border-radius: 4px;
    background: #000;
    color: #fff;
    width: 48%;
  }
  .welcome-model-register-btn button.btn-close {
    color: #000;
    background: #fff;
  }
  .welcome-model-header-text {
    justify-content: right;
    border: none;
    padding-bottom: 5px;
    padding-top: 10px;
    svg {
      width: 23px;
      height: 23px;
      // margin-top: 6px;
      opacity: 0.5;
    }
  }
  .welcome-model-popup {
    padding-top: 0px;
  }
  .welcome-model-register-text {
    padding-top: 0px;
    margin-top: -10px;
  }
  @media (max-width: 767px) {
    .welcome-model-register-text p {
      color: #000;
      text-align: center;
      font-family: Noto Serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  }
`;
