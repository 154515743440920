import styled from "styled-components";

export const BidIncrementTableStyled = styled.div`
  .BidIncrementTitle h1 {
    color: #111;
    font-family: Noto Serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
  }
  .BidIncrementdiv th,
  .BidIncrementdiv td {
    color: #555;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: 0.14px;
  }
  .BidIncrementdiv {
    border: none;
  }
  .IncrementTableDiv {
    max-height: 300px;
  }
  @media (max-width: 767px) {
    .BidIncrementTitle h1 {
      font-size: 15px;
      font-weight: 600;
    }
    .BidIncrementdiv th,
    .BidIncrementdiv td {
      color: #555;
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 184.615% */
    }
  }
`;
