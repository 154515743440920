import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "./styles.css";

const RegisterToBid = (props) => {
  const [selectedTExt, setSelectedTExt] = useState("");

  const handleValuesSelect = (value) => {
    setSelectedTExt(value);
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="registertoBidText">
        <div>
          <p className="registertoBidHead">{"Register to Bid"}</p>
        </div>
        <div>
          <p className="subtextPageTitle">
            Registration is opened for this for this Live Auction
          </p>
        </div>
        <div>
          <p className="subtextPage">
            {" "}
            Please select your preffered mode of bid:&nbsp;&nbsp;&nbsp;&nbsp;
            {/* <Form.Check
                            inline
                            type="radio"
                            onClick={() => { handleValuesSelect('isRestrictedMaterial', "yes") }}
                            label="Telephone"
                            name="restricted_materials"
                            className= "lotRestrictedRadio"
                        /> */}
            <label
              class="radio-inline"
              onClick={() => {
                handleValuesSelect("Telephone");
              }}
            >
              <input type="radio" name="optradio" />
              &nbsp;&nbsp;Telephone
            </label>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <label
              class="radio-inline"
              onClick={() => {
                handleValuesSelect("Online");
              }}
            >
              <input type="radio" name="optradio" />
              &nbsp;&nbsp;Online
            </label>
          </p>
        </div>

        <div className="dividerLineBid"></div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <p className="bidBottomText">
            Important: Please ensure you have read and understood the Buyers
            Premium Schedule prior to registering for the sale.
          </p>
        </div>
        <Button
          onClick={() => {
            props.onRegisterBid(selectedTExt);
          }}
          className="modalDeleteBtn"
        >
          Register
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RegisterToBid;
