import React, { useState } from "react";

import Modal from "react-bootstrap/Modal";
import { Button, Col } from "react-bootstrap";

import "./css/FinishSignupFromModal.css";
import { LOGINID } from "../../Scenes/common";

interface Props {
  finishClick: any;
  returnUrlValue?: any;
  isLoading?: any;
}

export const FinishSignupFromModal: React.FC<Props> = (props: any) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {/* <Button variant="outline-primary" className="btn-md" onClick={handleShow}>
        Change Password
      </Button> */}
      <Button
        variant="outline-primary"
        className="ml-2 finish_btn btn-md d-none"
        type="button"
        disabled={isLoading || props?.isLoading}
        onClick={async () => {
          // if (LOGINID) {
          setIsLoading(true);
          await props.finishClick();
          setIsLoading(true);
          handleShow();
          // } else {
          //   props.finishClick();
          // }
        }}
      >
        {props?.isLoading ? "UPDATING" : isLoading ? "UPDATING" : "FINISH"}
      </Button>

      <Modal
        centered
        show={show}
        onHide={handleClose}
        className="custom_modal_box custom_magage_profile_modal signup_finish_btn_modal"
      >
        <Modal.Body>
          <h5 className="text-center w-100 mb-3">
            Your account has been succesfully{" "}
            {props?.returnUrlValue ? "updated" : "created"}.
          </h5>
          <Col className="d-flex justify-content-between modal_btns pt-0">
            <Button
              variant="outline-secondary save_change_modal_btn"
              className="ml-auto"
              onClick={handleClose}
              href={props?.returnUrlValue || "/"}
            >
              OK
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default FinishSignupFromModal;
