import React, { useState, Component, useEffect } from "react";
import { Row, Col, Accordion, Card, Button, Form } from "react-bootstrap";
import { ReactComponent as Line } from "./../../assets/Line.svg";
import "./style.css";
import { getAllSiteManagersCall } from "../../library/Requests/SiteManager";
import { getAllCategories } from "../../library/Requests";
import moment from "moment";
import Article1Main from "./../../assets/articleimages/article2-main.png";
import Article2Image from "./../../assets/articleimages/article1.png";
import Article3Image from "./../../assets/articleimages/article3.png";

import article2img1 from "./../../assets/articleimages/article2img1.png";
import article2img2 from "./../../assets/articleimages/article2img2.png";
import article2img3 from "./../../assets/articleimages/article2img3.png";

import article2img4 from "./../../assets/articleimages/article2img4.png";
import article2img5 from "./../../assets/articleimages/article2img5.png";
import article2img6 from "./../../assets/articleimages/article2img6.png";

import article2img7 from "./../../assets/articleimages/article2img7.png";
import article2img8 from "./../../assets/articleimages/article2img8.png";
import article2img9 from "./../../assets/articleimages/article2img9.png";
import article2img10 from "./../../assets/articleimages/article2img10.png";
import article2img_new from "./../../assets/articleimages/article2img_new.png";
import shareicon from "./../../assets/main/share-fill.png";
import ShareModal from "./../../components/ShareModal/ShareModal";
import { DOMAIN_URL } from "../../library/constants";

const Article2 = () => {
  const [shareOpen, setShareOpen] = useState(false);
  const lotPageUrl = `${DOMAIN_URL}article2`;

  const moreList = [
    {
      title: "Article",
      text: "Introducing FineArt.HipHop",
      dateText: "August 13th, 2023",
      image: Article2Image,
      link: "/introducingFineArtHipHop",
    },
    {
      title: "Article",
      text: "Frozen Moments",
      dateText: "August 13th, 2023",
      image: Article3Image,
      link: "/frozenMoments",
    },
  ];
  return (
    <>
      <div className="wrapper">
        <ShareModal
          show={shareOpen}
          handleClose={() => {
            setShareOpen(false);
          }}
          url={lotPageUrl}
          title={"The Birth Of “Hip Hop” On Flyers"}
          onCopyClick={() => {
            navigator.clipboard.writeText(lotPageUrl);
          }}
        />

        <div className="topbannerSection">
          <div className="manageSiteManagerView1"></div>
          <div className="manageSiteManagerView manageSiteManagerView2">
            <Row className="imageSectionView">
              <img
                src={Article1Main}
                className="d-block w-100 tk "
                alt="clicked-slider-img"
              />
            </Row>
            <div className="share-icon-header-sec">
              <button
                onClick={() => {
                  setShareOpen(true);
                }}
              >
                <img src={shareicon} />
              </button>
            </div>
            <div className="BottomSectionView BottomSectionView1">
              <Row className="bannerContentRow">
                <Col className="bannerTextContent">
                  <p className="bannerCategoryText">
                    <Line fill="#FDD490" className="CategoryTextLine" />{" "}
                    <span className="finalTextcolorName">Article</span>{" "}
                  </p>
                  <p className="bannerTitleText">
                    <span className="finalTextcolorName">
                      {`The Birth Of “Hip Hop” On Flyers`}
                    </span>{" "}
                  </p>
                  <p className="bannerSubText">
                    <span className="finalTextcolorName">
                      {" "}
                      by Pete Nice | August 13th, 2023
                    </span>{" "}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="PreviewArticleContainer">
          <Card className="previewArticle">
            <Card.Body className="previewArticleBody">
              <Row className=" btnsRowPreview">
                {/* <span className="textHeaderPreview">Fineart Hiphop &nbsp; | &nbsp; {moment(ArticleData?.createdOnUtc).format("MMM Do, YYYY")} </span>
                    <span className="textHeaderPreview"><Love/> 0 &nbsp;&nbsp; &nbsp;&nbsp; <Share/> 0</span> */}
              </Row>
              <Row className="headerPreviewContent">
                <p className="PreviewTitle">
                  {`The Birth Of “Hip Hop” On Flyers`}{" "}
                </p>
              </Row>

              <Row className="headerPreviewContent headerContentViewArticle">
                The folklore of New York City includes several origin stories
                for the term “Hip Hop” which credit Cowboy of the Furious 5 and
                Lovebug Starski for coining the phrase, but the first time the
                words “Hip Hop” appeared anywhere in print was during the summer
                of 1979 in Brooklyn on a flyer to promote a party at “The ZOO
                Discotheque” on Atlantic Avenue. The flyer was saved and
                preserved by DJ Chipps who performed that night as part of the
                mobile DJ crew Fantasia along with other advertised talent
                including Grandmaster Flowers, DJ Flash, Carisma Funk, Sound
                Mechanics, Frankie D and “Disco MCs Eddie Cheeba and Lovebug
                Starsky.” On the bottom of the flyer an advertisement appears
                for a contest billed as “Battle of The Hip Hop MCs.” It is this
                inclusion and use of “Hip Hop” that makes this flyer historic.
                Historians and collectors have examined several thousand
                examples of party flyers from New York City dating between 1972
                and 1980 and this flyer from July 6, 1979 represents the first
                documented appearance and use of the term. Surprisingly, from
                July 1979 through the winter of 1981 the term Hip Hop appeared
                only four other times advertising jams and parties in the New
                York City vicinity. In July of 1980 Fab 5 Freddy created a
                handwritten flyer stating that a “Hip Hip Hip Hop” performance
                would occur at the Mudd Club in Manhattan and in February of
                1981 Charlie Ahearn made a flyer for a party at 814 Broadway
                that included the tag line, “U Hip to the Hop.”
              </Row>
              <Row className="row-image-article">
                <img className="v-img" src={article2img1}></img>
              </Row>
              <div className="shadow-text-row">
                The Zoo: Brooklyn, NY 1979 • Designer unknown
              </div>
              <div className="plain-text-row">
                In November of 1980 flyer artists Danny T and Eddie Ed created
                two flyers that referred to the Bronx nightclub, T-Connection,
                as “The Hip-Hop Palace of New York” and the “Hip-Hop Party
                Palace of the Bronx.”
              </div>
              <div className="bold-text-row">
                The Birth Of “Hip Hop” On Flyers The Hip-Hop Family Reunion Jam
              </div>
              <Row className="row-image-article">
                <img className="h-img" src={article2img2}></img>
              </Row>
              <div className="shadow-text-row">
                T-Connection: Bronx, NY 1981 • Designer Buddy Esquire, Poo 2 &
                Eddie Ed
              </div>

              <div className="plain-text-row">
                It wasn’t until the Spring of 1981 that the term Hip Hop truly
                became a staple of the culture as Buddy Esquire, Eddie Ed and
                Poo-Two created a flyer advertising a party at T-Connection as
                “The Hip-Hop Family Reunion Jam” featuring performances of Kool
                DJ AJ, Red Alert, Jazzy Jay, Afrika Bambaataa, the Soul Sonic
                Force, The Cold Crush Brothers, Breakout, Grandwizard Theodore,
                The Fantastic 5 and the Funky 4 Plus 1. Foreshadowing the future
                dominance of the genre they also added that the party was
                dedicated to, “The Hip-Hop Music of The World!”
              </div>
              <Row className="row-image-article">
                <img className="h-img" src={article2img3}></img>
              </Row>
              <div className="shadow-text-row">
                Buddy, Tony, Eddie & Poo 1981
              </div>
              <div className="bold-text-row">
                Phase 2’s “Hell Raisin’ Hip Hopp Hoedown”
              </div>
              {/* <div> */}
              <Row className="row-image-article row-image-article_new_extra">
                <img className="h-img" src={article2img_new}></img>
              </Row>
              {/* </div> */}
              <div className="shadow-text-row">
                The Hell Raisin’ Hip Hopp Hoedown New York, NY 1981 • Designer
                Phase 2
              </div>
              <div className="bold-text-row">
                T-Connection: Bronx, NY 1981 • Designer Buddy Esquire, Poo 2 &
                Eddie Ed
              </div>
              <Row className="row-image-article">
                <img className="h-img" src={article2img4}></img>
              </Row>
              <div className="shadow-text-row">
                T-Connection: Bronx, NY 1981 • Designer Buddy Esquire, Poo 2 &
                Eddie Ed
              </div>
              <Row className="row-image-article">
                <img className="v-img" src={article2img5}></img>
              </Row>
              <div className="shadow-text-row">
                Negril: Manhattan, NY 1981 • Designer Phase 2
              </div>
              <div className="bold-text-row">
                Phase 2’s First Annual “Hip Hop” Anniversary
              </div>
              <Row className="row-image-article">
                <img className="h-img" src={article2img6}></img>
              </Row>
              <div className="shadow-text-row">
                I.S.201 Harlem, NY 1981 • Designer Phase 2
              </div>
              <Row className="row-image-article">
                <img className="v-img" src={article2img7}></img>
              </Row>
              <div className="shadow-text-row">Phase 2</div>
              <div className="plain-text-row">
                By the end of 1981 a flyer created by Phase 2 solidified the use
                of the term memorializing a “Hip Hop Anniversary” flyer which
                identified over 100 artists, DJs, MCs, promoters and performers
                and celebrated what he called, “Those Funk Filled Years of Hip
                Hoppin.” The I.S. 201 party flyer commissioned by Mike & Dave
                also featured Phase’s iconic collage featuring hand cut
                photographs and portraits of the figures he considered part of
                the fabric of what became Hip Hop also paying homage to the Funk
                and Soul influences represented by album covers and record label
                logos including Stax, Cotillion, Volt, ATCO and Atlantic. He
                also advertised gifts of “Free Hip Hop T-Shirts” for guests and
                announced that awards would be presented for categories ranging
                from “Godfather of Hip Hop” to “The King of Rappers.” From 1982
                through 1983 the term Hip Hop would become more and more
                prevalent on party flyers and promoters like Mike & Dave and Van
                Silk would utilize it most effectively when throwing parties
                that would feature awards shows and “10th Anniversary” parties
                for Hip Hop itself.
              </div>
              <div className="bold-text-row">
                In Memory of the Flyer Collectors: DOT-A-ROCK, BIZ MARKIE, DJ
                KAY SLAY
              </div>
              <Row className="row-image-article">
                <img className="h-img" src={article2img8}></img>
              </Row>
              <div className="shadow-text-row">
                MC Dota Roca, 2016 • Designer Charlie Ahearn
              </div>

              <div className="bold-text-row">
                Hip Hop History The Sureshot La-Rock Collection
              </div>
              <Row className="row-image-article">
                <img className="v-img" src={article2img9}></img>
              </Row>
              <div className="shadow-text-row">
                Harlem World: Harlem, NY 1979 • Designer unknown
              </div>
              <div className="plain-text-row">
                Back in 1993, Phase 2 penned a missive for The Source Magazine
                mapping out the history and philosophy of Hip Hop party flyers.
                He called them “Funky Flyers” and he traced them back to 1972.
                In flashback mode he recalled, “...party’s over, someone hands
                you a flyer. On it, there’s a facsimile of Herc, pulling a
                rabbit from a magician’s hat, along with handwritten information
                about the next function. The flyer is fresh, to the point and
                unique. It’s signed: Kareem. There were more to come, then one
                day Kareem just faded away.”
              </div>
              <div className="plain-text-row">
                But the culture that would become Hip Hop would not fade away
                and the flyers created would become the foundational documents
                of the movement. Some were written on index cards to hype house
                parties and others were hand drawn or designed with innovative
                press-type designs to pack dusty night clubs. Thank God that
                practitioners like Phase, Buddy Esquire, the Sisco Kid,
                Straightman, Anthony Riley, Eddie Ed, Brian Hicks, Danny Tonge,
                Maurice Kempson and a host of others did their thing. They
                helped create what became Hip Hop culture.
              </div>
              <div className="plain-text-row">
                In 1993, a young MC in Virginia named Sureshot La Rock read
                Phase 2’s article in the Source and was inspired to obsessively
                collect these works of art, the products of what Phase referred
                to as “slick innovation.”
              </div>

              <Row className="row-image-article">
                <img className="h-img" src={article2img10}></img>
              </Row>
              <div className="shadow-text-row">
                Top Of The Lane: Bronx, NY 1975 • Designer Kool Herc
              </div>
              <div className="plain-text-row">
                30 years after he picked up that historic issue of The Source,
                Sureshot has compiled and archived the largest and most
                significant collection of Hip Hop flyers in existence. The
                pedigree and provenance traces directly back to the likes of
                Phase and Herc. His stacks of faded and folded papers are
                nothing short of a national treasure. We salute Sureshot La Rock
                and thank him for blessing us with some of the rarest and most
                important Hip Hop handbills for this historic exhibition.
              </div>
              <Row className="headerPreviewContent">
                {/* <p className="PreviewAdescCon"> {ArticleData?.excerpt}</p>
                <p className="PreviewAdescCon"> {ArticleData?.tags}</p> */}
              </Row>
              <div className="the-end-sec">
                <div className="the-end-sec-content">
                  <span className="dash-line"></span>
                  <span className="dash-text">END</span>
                  <span className="dash-line"></span>
                </div>
              </div>
              <Row className="more-from-word-sec">
                <p className="MoreRowText">
                  <Line fill="#FDD490" className="MoreTextLine" />
                  <span className="">More from the Word</span>
                </p>
              </Row>

              <Row className="headerPreviewContent ImagesRows">
                {(moreList || [])?.map((item: any, index: any) => {
                  console.log("inside", item);
                  let userLink = item.link;

                  return (
                    <>
                      <Col
                        sm={12}
                        md={4}
                        xs={12}
                        className="ImagesCols col-12 col-sm-12"
                      >
                        <a target="_self" href={userLink}>
                          <Card className="ImageCards col-sm-12">
                            <Card.Img className="ImageMore" src={item.image} />
                          </Card>

                          <p className="StoriesText">
                            <Line fill="#FDD490" className="StoriesLine" />
                            {item.title}
                          </p>
                          <p className="TheCollectionText col-sm-12 col-xs-12">
                            {item.text}
                          </p>
                          <p className="DateTexts">August 13th, 2023</p>
                        </a>
                      </Col>
                    </>
                  );
                })}
              </Row>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};
export default Article2;
