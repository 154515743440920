export const API_BASE = "/api";

var azure_accountID_id = "fineartblob";
export const azure_contentGuId = "temp";
export const VONTAGE_API_KEY = "47382381"; //"47354851"
export const VONTAGE_API_SECRET = "729e80e57105483f662ae1736f0fd07e81e1fc8c"; //"31c783736d0e36b726b1600cb9e5f8f20d65093b"
// export const SERVER_IP = 'https://api-development5.azurewebsites.net';
// 'https://api-development5.azurewebsites.net/dev';
// 'https://api-development5.azurewebsites.net/staging';
let SERVER_IP_URL = "https://api.fineart.hiphop";
let DOMAIN_URL1 = "https://fineart.hiphop/";

let MIXPANEL_ID1 = "23a366bc897a619546257c46835550f3";

if (typeof window !== "undefined") {
  var hostName = window.location.hostname;
  if (hostName === "localhost") {
    SERVER_IP_URL = "https://qaapi.fineart.hiphop";
    azure_accountID_id = "qafahhblob";
    DOMAIN_URL1 = "https://qa.fineart.hiphop/";
    MIXPANEL_ID1 = "c1185deb816e531b49855105af0b88c1";
  } else if (hostName === "qa.fineart.hiphop") {
    SERVER_IP_URL = "https://qaapi.fineart.hiphop";
    azure_accountID_id = "qafahhblob";
    DOMAIN_URL1 = "https://qa.fineart.hiphop/";
    MIXPANEL_ID1 = "c1185deb816e531b49855105af0b88c1";
  }
}
const map_url =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyDILWRZ3d-zi2xIjmATRtOUNkFJ8kEr4p0&libraries=places";

// SERVER_IP_URL = "https://api-fahh.azurewebsites.net";
export const SERVER_IP = SERVER_IP_URL;
export const azure_accountID = azure_accountID_id;
export const DOMAIN_URL = DOMAIN_URL1;
export const GOOGLE_MAP_URL = map_url;
export const STATIC_GATE_CODE = "FAHH@1234";
export const MIXPANEL_ID = MIXPANEL_ID1;
