import React, { Component } from "react";
import "./css/style.css";
class ShippingGuides extends React.Component {
  componentDidMount() {
    document.title = "FAHH-shippingGuides";
  }
  render() {
    return (
      <div className="contactUsContainer">
        <p className="contact-us-main-header">Shipping</p>
        <div className="shipping-box-header">
          <p>
            After you have paid for your property, the final step is to make
            arrangements for collection and shipping.
          </p>
          <p>
            For winning bids in all sales, you can choose to arrange have your
            item shipped via Fed Ex at checkout on the FAHH platform.
            Alternatively, if you are located in the New York Metropolitan area,
            you can contact{" "}
            <a href="mailto:support@fineart.hiphop">support@fineart.hiphop</a>{" "}
            to see if local pickup delivery for your item is available.
          </p>
          <p>
            After the sale, some property may be removed to an off-site storage
            warehouse. The location of your lot can be confirmed with your
            Post-Sale Coordinator reachable at{" "}
            <a href="mailto:support@fineart.hiphop">support@fineart.hiphop</a>{" "}
            or will be listed in your initial email with your invoice.
            Collection is only available at the FineArt.HipHop location that is
            in possession of the property.
          </p>
          <p>
            If you intend to export your purchases, you may require an export
            and/or CITES permit. For any further questions or information on
            your shipping arrangements, please contact your Post-Sale
            Coordinator at {" "}
            <a href="mailto:support@fineart.hiphop">support@fineart.hiphop</a>.
          </p>
        </div>
      </div>
    );
  }
}
export default ShippingGuides;
