import React, { useState, Component } from "react";
import { Row, Col, Accordion, Card, Button, Form } from "react-bootstrap";
import { ReactComponent as Line } from "./../../assets/Line.svg";
import "./style.css";
import { getAllSiteManagersCall } from "./../../library/Requests/SiteManager";
import { getAllCategories } from "./../../library/Requests";
import moment from "moment";
import Article1Main from "./../../assets/articleimages/article1-main.png";
import Article2 from "./../../assets/articleimages/article2.png";
import Article3 from "./../../assets/articleimages/article3.png";
import shareicon from "./../../assets/main/share-fill.png";
import { DOMAIN_URL } from "../../library/constants";
import ShareModal from "./../../components/ShareModal/ShareModal";

const Article1 = () => {
  const [shareOpen, setShareOpen] = useState(false);
  const lotPageUrl = `${DOMAIN_URL}article1`;

  const moreList = [
    {
      title: "Article",
      text: "The Birth Of “Hip Hop” On Flyers",
      dateText: "August 13th, 2023",
      image: Article2,
      link: "/theBirthOfHipHop",
    },
    {
      title: "Article",
      text: "Frozen Moments",
      dateText: "August 13th, 2023",
      image: Article3,
      link: "/frozenMoments",
    },
  ];
  return (
    <>
      <div className="wrapper">
        <ShareModal
          show={shareOpen}
          handleClose={() => {
            setShareOpen(false);
          }}
          url={lotPageUrl}
          title={"Introducing FineArt.HipHop"}
          onCopyClick={() => {
            navigator.clipboard.writeText(lotPageUrl);
          }}
        />
        <div className="topbannerSection">
          <div className="manageSiteManagerView1"></div>
          <div className="manageSiteManagerView manageSiteManagerView2">
            <Row className="imageSectionView">
              <img
                src={Article1Main}
                className="d-block w-100 tk "
                alt="clicked-slider-img"
              />
            </Row>
            <div className="share-icon-header-sec">
              <button
                onClick={() => {
                  setShareOpen(true);
                }}
              >
                <img src={shareicon} />
              </button>
            </div>
            <div className="BottomSectionView BottomSectionView1">
              <Row className="bannerContentRow">
                <Col className="bannerTextContent">
                  <p className="bannerCategoryText">
                    <Line fill="#FDD490" className="CategoryTextLine" />{" "}
                    <span className="finalTextcolorName">Article</span>{" "}
                  </p>
                  <p className="bannerTitleText">
                    <span className="finalTextcolorName">
                      {`Introducing FineArt.HipHop`}
                    </span>{" "}
                  </p>
                  <p className="bannerSubText">
                    <span className="finalTextcolorName"> by Ed Young</span>{" "}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="PreviewArticleContainer">
          <Card className="previewArticle">
            <Card.Body className="previewArticleBody">
              <Row className=" btnsRowPreview">
                {/* <span className="textHeaderPreview">Fineart Hiphop &nbsp; | &nbsp; {moment(ArticleData?.createdOnUtc).format("MMM Do, YYYY")} </span>
                    <span className="textHeaderPreview"><Love/> 0 &nbsp;&nbsp; &nbsp;&nbsp; <Share/> 0</span> */}
              </Row>
              <Row className="headerPreviewContent">
                <p className="PreviewTitle">{`Introducing FineArt.HipHop`} </p>
              </Row>
              {/* <Row className="headerPreviewContent">
                  <p className="PreviewAuthor"> By {ArticleData?.author} </p>
                </Row>
                <Row className="headerPreviewContent imageContainer">
                  <Row className="imageRow">
                    {ArticleData?.featuredImage ? (
                      <img
                        src={ArticleData?.featuredImage}
                        alt="Preview"
                        className="previewImage"
                      />
                    ) : null}
                  </Row>
                </Row> */}
              <Row className="headerPreviewContent headerContentViewArticle">
                FineArt.HipHop is founded on the premise that the Hip Hop
                lifestyle is undoubtedly the most diverse and pervasive
                lifestyle in the world today. In this 50th Year of Hip Hop it is
                fitting that a company founded by people from the Hip Hop
                community rises to acknowledge, curate, honor and expose to the
                world the unique and valuable art to which Hip Hop culture has
                given rise. Unlike other music genres before it, Hip Hop
                expanded to encompass the entirety of life for its generation –
                extending from music to fashion to politics to economics to the
                sciences and more. The art from the Hip Hop generation
                represents this breadth and FineArt.HipHop will authentically
                seek out and bring to market these valuable artifacts for the
                world to see and collectors and aficionados to cherish. We are
                more than excited as we begin this journey and welcome all to
                come along with us as the influence of Hip Hop continues to grow
                and inspire.
              </Row>

              <Row className="headerPreviewContent">
                {/* <p className="PreviewAdescCon"> {ArticleData?.excerpt}</p>
                <p className="PreviewAdescCon"> {ArticleData?.tags}</p> */}
              </Row>
              <div className="the-end-sec">
                <div className="the-end-sec-content">
                  <span className="dash-line"></span>
                  <span className="dash-text">END</span>
                  <span className="dash-line"></span>
                </div>
              </div>
              <Row className="more-from-word-sec">
                <p className="MoreRowText">
                  <Line fill="#FDD490" className="MoreTextLine" />
                  <span className="">More from the Word</span>
                </p>
              </Row>

              <Row className="headerPreviewContent ImagesRows">
                {(moreList || [])?.map((item: any, index: any) => {
                  console.log("inside", item);
                  let userLink = item.link;

                  return (
                    <>
                      <Col
                        sm={12}
                        md={4}
                        xs={12}
                        className="ImagesCols col-12 col-sm-12"
                      >
                        <a target="_self" href={userLink}>
                          <Card className="ImageCards col-sm-12">
                            <Card.Img className="ImageMore" src={item.image} />
                          </Card>

                          <p className="StoriesText">
                            <Line fill="#FDD490" className="StoriesLine" />
                            {item.title}
                          </p>
                          <p className="TheCollectionText col-sm-12 col-xs-12">
                            {item.text}
                          </p>
                          <p className="DateTexts">August 13th, 2023</p>
                        </a>
                      </Col>
                    </>
                  );
                })}
              </Row>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};
export default Article1;
