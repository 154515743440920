import styled from "styled-components";

export const CurrencyConverterBoxStyled = styled.span`
  display: inline-block;
  .show-name-item {
    display: inline-block;
  }
  .currency-item-country-name {
    border-radius: 8px;
    background: #f2f2f2;
    padding: 13px 10px;
    cursor: pointer;
    img {
      margin-right: 10px;
      max-width: 40px;
    }
  }
  @media (max-width: 700px) {
    .Create-New-password-sec {
      width: 80%;
      margin: auto;
    }
    width: 100%;
    float: right;
    justify-content: right;
    display: flex;
    margin-bottom: 20px;
    margin-top: 10px;
  }
`;
