import styled from "styled-components";

export const GateCodeStyled = styled.div`
  .create-header {
    color: #111;
    text-align: center;
    font-family: Noto Serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 150px;
  }
  .Create-gate-code-sec {
    width: 500px;
    margin: auto;
    padding: 20px;
  }
  .error_msg {
    color: red;
    text-align: left;
    margin-top: 10px;
  }
  .gate-code-sec-con {
    text-align: center;
    margin-top: 50px;

    input {
      width: 80%;
      height: 40px;
      padding: 10px;
      border-radius: 4px;
      border: 1px solid #e2e2e2;
    }
    button {
      height: 40px;
      width: 20%;
      background: #000;
      border: none;
      border-radius: 4px;
      color: #fff;
      font-size: 15px;
      // padding: 10px 20px;
      cursor: pointer;
    }
  }
  @media (max-width: 700px) {
    .Create-gate-code-sec {
      width: 90%;
      margin: auto;
      padding: 20px;
    }
    .create-header {
      font-size: 24px;
    }
    .gate-code-sec-con {
      input {
        width: 70%;
      }
      button {
        width: 30%;
      }
    }
  }
`;
