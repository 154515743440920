import { CurrencyConverterBoxStyled } from "./CurrencyConverterBox.styled";
import React, { useEffect, useState } from "react";
import CurrencyConverter from "../../components/CurrencyConverter";

import { Container, Row, Col, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
import { getAllCurrencyItems } from "./../../library/Requests/PlaceBid";
const CurrencyConverterBox = (props: any) => {
  const [selectedCountry, setSelectedCountry] = useState({} as any);
  const [showCurrencyPopup, setShowCurrencyPopup] = useState(false);
  const [conversion_rates, setConversion_rates] = useState([] as any);
  const [allConversonRates, setAllConversonRates] = useState({} as any);
  useEffect(() => {}, []);
  const getAllCurrencyCall = async () => {
    if (conversion_rates?.length === 0) {
      const resp = await getAllCurrencyItems();
      setAllConversonRates(resp);
      var newList = [] as any;
      for (const key in resp?.conversion_rates) {
        newList.push({ code: key, amount: resp?.conversion_rates[key] });
      }
      setConversion_rates(newList);
    }
  };
  const handleShowClick = () => {
    getAllCurrencyCall();
    setShowCurrencyPopup(true);
  };
  const hideModal = () => {
    setShowCurrencyPopup(false);
  };
  const handleSelectCurrency = async (iitm: any) => {
    hideModal();
    const newJson = {
      ...allConversonRates,
      base_code: iitm?.currency_code,
      country: iitm?.country,
      flag: iitm?.flag,
    };
    setSelectedCountry(newJson);
    console.log("newJson", newJson);
    props?.handleShowCurrency(newJson);
  };
  const handleClearSelectedCurrency = () => {
    setSelectedCountry({});
    props?.handleShowCurrency({});
    hideModal();
  };

  return (
    <CurrencyConverterBoxStyled>
      <span onClick={handleShowClick} className="show-name-item">
        <span className="currency-item-country-name">
          {selectedCountry?.base_code ? (
            <>
              <img src={selectedCountry?.flag} />
              {selectedCountry?.base_code}
            </>
          ) : (
            <>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAALESURBVHja7Jc/aBNxFMc/l0STtqYtihgkYLOYitjuFuwiUgfBUOgSOqS6CNqmRRqLmyjBBDQ4FLRL/TOokEEhgyC4O7RSB0MHWxEtWLGtrW2Su/s9h8ZeUlF7rV4XHzy+995v+d77vnf3fpqIsJ3mYpvtPwENcAPeMjppJlD0APXHj9/44nZvrhh3d45tsvYuAk9GdwM0nTiRkZmZb3L9+jPbuBUDmjyA1zAUIyMviMXaSaVzDPSfJJ3O0V+JqRz9A1acSufQgC+XrlpvJRXCVua06nNXYz36m0kArwtAKUVPTzvJ5FPifR0kk0/pW4/x6jje10GhoEOhaHmx7OtzP50XQDfWOIbb2lISjz+SqakFicVGN4yx2OhWJQh7AAzDJB7vYHDwEclkF4nExnBo6DGz3Rfs959/F8aHGQDKBBSJxEOuXeuit/cemUz3hhBA6d82NfxSKlkStLZekcnJeTl2LC35/Jwt/CsS6LpJT88d7oycJRod5sH9c0Sjw9z/A4Lw8egp0MptLmI9V8br8prPB8WCJYGuK27fPkPk9E2y2T5ORzJks71EIqtxZC2uznd23kJ8y9Vj9zv7MZKGjlROQSg0JKHQZZmYmJVgMLFhDAYTW5YAIBwMJmR8/JPU1Z2XsTF7OL3nkH0PtMj7g20ChDUgHAhczC8tlTAM03ZD52ue258CjwfNX8eBty+bNSBsmmbe5XL2z6yUwu12N3sApve34jFMpKQ7swPs3IGxw2NNgTINRARRpv1tQtbFld3+q3VT3CjTsAgE34/j8/kclWBlZQVqa1cJTO89TI3XiyyvOCNBbQ3LpaK1E5pKVX/B/jkDDaWkQoKPr2hoaHBUgoWFBWhsXCXwLtBCY73fUQJzXxfXKmDqfpPPMu8oAfEDBUwN2AccAfY6vJbPAq+18p3AX0YnrQgsav8vp9tN4PsALYQJa7MTgzkAAAAASUVORK5CYII=" />{" "}
              USD
            </>
          )}
        </span>
      </span>
      <CurrencyConverter
        conversion_rates={conversion_rates}
        ShowPopCurrency={showCurrencyPopup}
        hideModal={hideModal}
        handleSelectCurrency={handleSelectCurrency}
        selectedCountry={selectedCountry}
        handleClearSelectedCurrency={handleClearSelectedCurrency}
      />
    </CurrencyConverterBoxStyled>
  );
};

export default CurrencyConverterBox;
