import styled from "styled-components";

export const BannerContainer = styled.div`

  .shop-hero-section {
    aspect-ratio: 1440/459;
  }
  .slick-slide {
    position: relative;
  }


 
  .slideshow-slider-dots {
    bottom: 25px;
  }
  .slideshow-slider-dots li {
    p {
      font-family: DIN Condensed;
      font-size: 14px;
      font-weight: 700;
      line-height: 15.67px;
      letter-spacing: 0.075em;
      text-align: left;
      padding-top: 5px;
      color: #a6a6a6;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: 0;
    }
    &.slick-active p {
      color: #ffffff;
    }
  }
  figcaption {
    position: absolute;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    display: block;
    // margin-top: 55px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 46px;
  }
  figcaption small {
    display: block;
    font-family: Oswald;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
  }
  figcaption button {
    display: block;
    font-family: Oswald;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.075em;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    border: 0;
    background: transparent;
    padding: 0;
    margin-top: 12px;
    opacity: 1;
    height: 38px;
    :hover {
      background: #ffd0d0;
      border: 1px solid #8a0f0f;
      padding: 0 8px;
      border-radius: 5px;
      color: #8a0f0f;
    }
  }

  figcaption span {
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 52px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #ffffff;
  }
  :hover figcaption span {
    text-decoration: underline;
  }
  .slick-slide .carosel-cat-name-new {
    color: #fff;
    font-family: Mercury Display A,Mercury Display B,MercuryDisplay,serif;
    font-size: 16px;
    /* font-style: normal; */
    font-weight: 400 !important;
    line-height: normal;
    text-transform: none;
    img {
      aspect-ratio: auto;
      height: 20px;
      width: 16px;
      display: inline-block;
      object-fit: contain;
    }
  }
  .main-sub-page-title {
    color: #fff;
    font-family: "MercuryDisplayBold,Times new roman";
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
  }
  .main-sub-page-sub-title {
    color: #fff;
    font-family: MercuryDisplayRegular,Times new roman;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .home-banner-image-text-sec {
    margin-left: 10%;
  }
  @media only screen and (max-width: 990px) {
    figcaption {
      margin-top: 0px;
    }
  }
  @media only screen and (max-width: 768px) {
    figcaption {
      margin: 20px !important;
      padding: 0;
      top: 15px;

      span {
        font-weight: 500 !important;
        font-size: 30px !important;
        line-height: 42px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 80%;
        display: inline-block;
      }
      button {
        font-size: 12px !important;
        line-height: 15px !important;
        margin-top: 8px !important;
        height: 25px !important;
        background: rgb(138, 15, 15);
        width: 90px;
      }
    }
    .next-arrow-sec {
      display: none;
    }

    .slideshow-slider-dots {
      flex-wrap: nowrap;
      bottom: 16px !important;
      li p {
        display: none;
      }
    }
  }


      @media only screen and (min-width: 999px){
       .slick-slide {
    figure {
      aspect-ratio: 1440/459;
      margin: 0;
      img {
        aspect-ratio: 1440/459;
        // object-fit: contain;
        object-fit: cover;
        width: 100%;
      }
      .br-opacity {
        aspect-ratio: 1440/459;
        // max-height: 459px;
      }
    }
  }
      .slick-list {
    aspect-ratio: 1440/459;
  }
}

@media only screen and (max-width: 600px){
.slick-list{
height:300px;}

.next-arrow-sec {
  display: none !important;
}
}

`;
