import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import noImage from "../../assets/image/noImage.jpg";

import moment from "moment";
import { CardItemStyled } from "./CardItemStyled.styled";
import bidBlack from "../../assets/image/bid-black.png";
import bidWhite from "../../assets/image/bid-white.png";
import { afterLoginTriggerPageCall, LopinPopUpPage } from "../../actions";
import { bidregistrationCall } from "../../library/Requests/PlaceBid";
import { triggerMixpanelEvent } from "../../Scenes/common";
import RegisterToBid from "../RegisterToBid";
import CustomModal from "../CustomModel";
import { useDispatch, useSelector } from "react-redux";

const CardItem = (props: any) => {
  const { item } = props;

  const dispatch = useDispatch();

  const { user } = useSelector((state: any) => state);

  const [placeBidHover, setPlaceBidHover] = useState(false);
  const [warningModelPopup, setWarningModelPopup] = useState(false);
  const [userRejectedAddressPopup, setUserRejectedAddressPopup] =
    useState(false);
  const [registerToBidPopUp, setRegisterToBidPopUp] = useState(false);
  const [registerBidSuccess, setRegisterBidSuccess] = useState(false);
  const [errorModelPopup, setErrorModelPopup] = useState(false);
  const [errorTextData, setErrorTextData] = useState("");
  const [registerButtonData, setRegisterButtonData]: any = useState({
    ...item,
  });
  const [userData, setUserData]: any = useState({});

  useEffect(() => {
    let userInfo: any = localStorage.getItem("customerInfoDetails");
    setUserData(JSON.parse(userInfo));
  }, []);

  const registertoBid = async (e: any) => {
    e.preventDefault();
    let userInfo: any = localStorage.getItem("customerInfoDetails");
    const userIn: any = JSON.parse(userInfo);

    if (userIn?.id) {
      dispatch(afterLoginTriggerPageCall({}));
      if (item?.auctionType == 1) {
        registoBidValue("Online");
      } else {
        registoBidValue("Online");
        // this.setState({registerToBidPopUp: true});
      }
    } else {
      const jsonD = {
        pageName: "registerBid",
        lotId: 0,
      };
      dispatch(afterLoginTriggerPageCall(jsonD));
      dispatch(LopinPopUpPage(true));
    }
  };
  const registoBidValue = async (value: any) => {
    let userInfo: any = localStorage.getItem("customerInfoDetails");
    const userIn: any = JSON.parse(userInfo);
    if (value != "" && userIn?.id) {
      if (userInfo != undefined && userInfo != null && userInfo != "") {
        if (!userIn?.billingAddressId) {
          //  !userIn?.identityProof ||  !userIn?.addressProof ||
          setWarningModelPopup(true);
        } else if (!userIn?.active && userIn?.isVerified) {
          setUserRejectedAddressPopup(true);
        } else {
          setRegisterToBidPopUp(false);
          const body = {
            bidRegistration: {
              collectionId: item?.id,
              customerId: userIn?.id,
              type: value,
              registred: true,
            },
          };
          const resp = await bidregistrationCall(body);
          if (resp?.success) {
            triggerMixpanelEvent("bid_registration_success", {
              lotId: item?.id,
            });
            setRegisterBidSuccess(true);
            let obj = { ...registerButtonData, isRegistredToAuction: true };
            setRegisterButtonData(obj);
          } else if (resp?.errors?.length > 0) {
            setErrorModelPopup(true);
            setErrorTextData(resp?.errors[0]);
            triggerMixpanelEvent("bid_registration_error", {
              lotId: item.id,
              error_message: resp?.errors[0],
            });
          } else if (resp?.message) {
            triggerMixpanelEvent("bid_registration_error", {
              lotId: item.id,
              error_message: resp?.message,
            });
            setErrorModelPopup(true);
            setErrorTextData(resp?.message);
          }
        }
      }
    }
  };

  const gotoUploadPhotoPage = () => {
    let userInfo = localStorage.getItem("customerInfoDetails");
    if (userInfo != undefined && userInfo != null && userInfo != "") {
      const userIn = JSON.parse(userInfo);
      if (!userIn?.billingAddressId) {
        window.location.href = `/sign-up/step2?returnUrl=/collectionDetails/${item.id}`;
      } else {
        window.location.href = `/sign-up/step3?returnUrl=/collectionDetails/${item.id}`;
      }
    }
  };

  console.log(userData, "userData");

  return (
    <CardItemStyled>
      <Link
        to={`/collectionDetails/${item.id}`}
        key={item.id}
        className="action_slider_content Collection_action_slider_content text-decoration-none text-dark"
      >
        <div className="action_slider_img overflow-hidden">
          <img
            src={
              item?.coverImage != "string" ||
              item?.coverImage != null ||
              item?.coverImage != undefined ||
              item?.coverImage != ""
                ? item.coverImage
                : noImage
            }
            className="img-fluid border-0"
          />
        </div>
        <div className="action_slider_body_content">
          <div className="bidding-text">Bidding Open</div>
          <h3 className="action_slider_heading home-page-title-coll overflow-hidden">
            {item.title}
          </h3>
          <ul className="d-flex align-items-center pl-0 flex-wrap">
            <li className="action_slider_date_text pr-2 border-0">
              {item?.auctionEndDate ? (
                moment(item?.auctionStartDate).format("D") ===
                moment(item?.auctionEndDate).format("D") ? (
                  <span>
                    {moment(item?.auctionStartDate).format("MMMM D, YYYY")}
                  </span>
                ) : (
                  <>
                    <span className="active-start-date">
                      {moment(item?.auctionStartDate).format("MMMM D")}
                    </span>{" "}
                    {"-"}{" "}
                    <span className="active-end-date">
                      {moment(item?.auctionEndDate).format("MMMM D, YYYY")}
                    </span>
                  </>
                )
              ) : (
                <span>
                  {moment(item?.auctionStartDate).format("MMMM D, YYYY")}
                </span>
              )}
            </li>
          </ul>
          <ul className="d-flex align-items-center pl-0 mb-4 flex-wrap">
            <li className="lot-date-closing-text pl-0">
              Lots Closing{" "}
              {moment(item?.auctionStartTime, "HHmmss").format("h:mm A")}{" "}
              {item?.timeZoneAbbr === "EST" ? "ET" : item?.timeZoneAbbr}
            </li>
            <li className="lot-date-closing-text pr-0 auction-type-sec">
              <Link
                to={item.actionLink}
                className="text-decoration-none text-dark"
              >
                {item.auctionType === "Online Auction"
                  ? "Online Only Auction"
                  : item.auctionType}
              </Link>
            </li>
          </ul>

          <div className="active-auctions-brow-btns">
            <Link
              to={`/${
                item.auctionType === "Live Auction1"
                  ? `live-auction-landing/${item.id}`
                  : `collectionDetails/${item.id}`
              }`}
              className="active-auction-btn"
            >
              BROWSE AUCTION
            </Link>
            {!user?.isVerified ? (
              <div
                onClick={registertoBid}
                id={`registered-button-active-${item?.id}`}
                className="active-auction-btn"
              >
                Register to Bid
              </div>
            ) : (
              <Link
                to={`/${
                  item.auctionType === "Live Auction1"
                    ? `live-auction-landing/${item.id}`
                    : `collectionDetails/${item.id}`
                }`}
                className="active-auction-btn registered-button"
                onMouseEnter={() => {
                  setPlaceBidHover(true);
                }}
                onMouseLeave={() => {
                  setPlaceBidHover(false);
                }}
              >
                <img
                  src={bidWhite}
                  className="bid_icon mr-1 "
                />
                Bid
              </Link>
            )}
          </div>
        </div>
      </Link>
      <RegisterToBid
        show={registerToBidPopUp}
        titleText=""
        deleteText="OK"
        cancelText=""
        onCancel={() => {}}
        onRegisterBid={registoBidValue}
      />
      <CustomModal
        show={registerBidSuccess}
        titleText={`You have been successfully registered for the ${
          item?.auctionType === "Online Auction"
            ? "Online Only Auction"
            : "Live Auction"
        }`}
        deleteText="OK"
        cancelText=""
        onCancel={() => {}}
        onDelete={() => {
          setRegisterBidSuccess(false);
        }}
      />
      <CustomModal
        show={errorModelPopup}
        titleText={errorTextData}
        deleteText="OK"
        cancelText=""
        longText={true}
        onCancel={() => {}}
        onDelete={() => {
          setErrorModelPopup(false);
        }}
      />
      <CustomModal
        show={warningModelPopup}
        titleText="Submit your identification "
        subTitleText="In order to place a bid on a lot in this auction, please complete FAHH’s verification process."
        deleteText="CONTINUE"
        cancelText=""
        onCancel={() => {}}
        onDelete={() => {
          setWarningModelPopup(false);

          gotoUploadPhotoPage();
        }}
      />{" "}
      <CustomModal
        show={userRejectedAddressPopup}
        // titleText="Submit your identification "
        subTitleText="Your ID proof document has been rejected, It appears that there were issues with the provided ID. To proceed, please resubmit a clear and valid ID image."
        deleteText="REUPLOAD"
        cancelText="OK"
        showCancelBtn={true}
        onCancel={() => {
          setUserRejectedAddressPopup(false);
        }}
        onDelete={() => {
          setUserRejectedAddressPopup(false);
          gotoUploadPhotoPage();
        }}
      />
    </CardItemStyled>
  );
};
export default CardItem;
