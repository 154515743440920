import styled from "styled-components";

export const StyledComingSoonPage2 = styled.div`
  .coming-soon-page {
    text-align: center;
    height: 1035px;
    width: 100%;
    text-align: center;
    // padding-top: 100px;
    color: white;
    overflow: hidden;
    // background: #c04848; /* fallback for old browsers */
    background: linear-gradient(rgb(244 240 244 / 80%), rgb(239 232 232 / 80%)),
      url("https://fineartblob.blob.core.windows.net/images/KYCDocuments/5794da66-e1d7-48ba-b759-44fd1b226a16/638130815621658315-1677484756189__fahh1.png"); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(rgb(76 74 76 / 80%), rgb(80 78 78 / 80%)),
      url("https://fineartblob.blob.core.windows.net/images/siteManager/ComingSoon_6.png"); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: url("https://fineartblob.blob.core.windows.net/images/siteManager/ComingSoon_6.png"); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    background-size: cover;
    background-repeat: no-repeat;
  }
  .header-page img {
    width: 150px;
  }
  P {
    margin: 0;
  }
  .main-content-page {
    // margin-top: 115px;
  }
  .background-image {
    height: 100%;
    width: 100px;
  }
  .logo-image-full {
    text-align: center;
  }
  .coming-soon-content {
    margin-top: 60px;
  }
  .coming-soon-page3 {
    width: 80%;
    margin: auto;
    margin-top: 100px;
  }
  .modalBodyText {
    font-family: "Noto Serif";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    /* identical to box height */

    text-align: center;

    color: #111111;
  }
  .modal-content {
    padding: 3px 15px;
  }
  .modalBodyTextSub {
    font-family: "Noto Serif";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #111111;
    margin-top: 25px !important;
    span {
      font-weight: bold;
    }
  }
  .main-content-header {
    width: 80%;
    margin: auto;
  }
  .form-text-box {
    width: 60%;
    margin: auto;
    margin-top: 48px;
  }
  .bottom-present-text {
    width: 80%;
    margin: auto;
    margin-top: 64px;
  }

  .main-text {
    font-family: "Libre Baskerville";
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 69px;
    text-align: center;

    color: #ffffff;
  }
  .sub-text {
    font-family: "Libre Baskerville";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    margin-top: 24px;
    text-align: center;
  }
  .sub-sub-text {
    font-family: "Libre Baskerville";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 40px;
    margin-top: 32px;
    text-align: center;

    color: #ffffff;
  }
  .yellow-color {
    color: #ffde17;
  }
  .label-text {
    font-family: "Libre Baskerville";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    text-align: left;
    line-height: 30px;
    /* identical to box height, or 94% */

    color: #ffffff;
  }
  .input-box-bg {
    background: #ffffff;
    border-radius: 40px;
    display: flex;
    justify-content: space-between;
    height: 72px;
    padding-top: 11px;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 10px;
    input {
      border: none;
      height: 50px;
      font-family: "Libre Baskerville";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #111111;
      // opacity: 0.3;
    }
    input:focus-visible {
      outline: none;
    }
    .email-input {
      border-left: 2px solid #e6e6e6;
      padding-left: 20px;
    }
  }
  .input-box-bg input::-webkit-input-placeholder {
    opacity: 0.3;
  }
  .present-by {
    font-family: "Libre Baskerville";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    color: #ffffff;
    span {
      width: 32px;
      display: inline-block;
      border-bottom: 2px solid #fff;
      margin-top: -20px;
      position: relative;
      top: -7px;
      margin-right: 10px;
    }
  }
  .present-by-content {
    display: flex;
    margin-top: 40px;
  }
  .footer-content p {
    font-family: "Libre Baskerville";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
  }
  .submit-btn {
    padding: 13px 10px;
    width: 256px;
    // margin-left: 125px;
    text-transform: uppercase;
    height: 51px;
    background: #111111;
    border-radius: 40px;
    border: none;
    font-family: "Libre Baskerville";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    /* identical to box height */

    text-transform: uppercase;

    color: #ffffff;
  }
  .border-line {
    border-bottom: 2px solid #fff;
    opacity: 0.3;
    margin-top: 35px;
    margin-bottom: 25px;
  }
  .present-by-left,
  .present-by-right {
    display: flex;
    justify-content: start;
    text-align: left;
  }
  .content-present-text {
    padding-left: 20px;
  }
  .main-text-border {
    opacity: 0.5;
    border-bottom: 2px solid #ffffff;
    margin: 15px 0px;
  }
  .present-header {
    font-family: "Libre Baskerville";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 30px;
    color: #ffffff;
  }
  .present-sub-header {
    font-family: "Libre Baskerville";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    margin-top: 8px;
    margin-bottom: 25px;
  }
  .present-icons-list img {
    margin-right: 30px;
  }
  .center-box-content {
    width: 180px;
  }
  .modalCandelBtn1 {
    background: #000;
    border: none;
    width: 150px;
    height: 50px;
    cursor: pointer;
    color: #fff !important;
  }
  .modalCandelBtn1:hover,
  .modalCandelBtn1 {
    boder: none;
    color: #fff !important;
  }
  @media only screen and (max-width: 767px) and (min-width: 1100px) {
    .input-box-bg input {
      // width: 26%;
    }
  }
  @media (max-width: 767px) {
    .main-content-header {
      width: 90%;
    }
    .main-text {
      font-size: 32px;
      line-height: 40px;
    }
    .sub-text {
      font-size: 18px;
      line-height: 26px;
    }
    .sub-sub-text {
      font-size: 16px;
      line-height: 28px;
    }
    .form-text-box {
      width: 90%;
    }
    .label-text {
      font-size: 18px;
      line-height: 24px;
    }
    .input-box-bg {
      display: block;
      height: auto;
      background: none;
      input {
        opacity: 1;
        height: 48px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        width: 100%;
        border-radius: 48px;
        padding-left: 20px;
        margin-bottom: 16px;
      }
    }
    .submit-btn {
      height: 44px;
      margin-left: 0px;
      margin-top: 10px;
      width: 100%;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
    }
    .present-by {
      font-size: 21px;
      line-height: 26px;
    }
    .coming-soon-page {
      height: 1860px;
    }
    .present-by-content {
      display: block;
    }
    .present-by-left,
    .present-by-right {
      display: block;
      text-align: center;
    }
    .present-sub-header {
      margin-top: 14px;
      margin-bottom: 30px;
    }
    .present-by-right {
      margin-top: 60px;
    }
    .present-by-content {
      margin-top: 10px;
    }
    .present-by-right .content-present-text {
      margin-top: 25px;
    }
    .coming-soon-page {
      background: url("https://fineartblob.blob.core.windows.net/images/siteManager/ComingSoon_5.png"); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
  }
`;
