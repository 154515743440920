import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Button } from "react-bootstrap";

import filterListImg from "../../assets/image/slide1.jpg";
import noImage from "../../assets/image/noImage.jpg";
import bidBlack from "../../assets/image/bid-black.png";
import bidWhite from "../../assets/image/bid-white.png";
import moment from "moment";
import PrevBidDetails from "../../components/PrevBidDetails/PrevBidDetails";

import "./css/BrowseTabListContent.css";
import { followeddata } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

interface Props {
  currenciesList?: any;
  timezonesList?: any;
  bannerTimeZone?: any;
  collectionData?: any;
  collectionItems?: any;
  followBid?: any;
  unFollowBid?: any;
  isPastAuction?: any;
  isLoading?: any;
  registertoBid?: any;
}

export const BrowseTabListContent: React.FC<Props> = (Props) => {
  const {
    collectionData,
    collectionItems,
    currenciesList,
    timezonesList,
    bannerTimeZone,
    registertoBid,
  } = Props;
  const { user } = useSelector((state: any) => state);
  const [showPrevBids, setShowPrevBids] = useState(false);
  const [selectedLotId, setSelectedLotId] = useState("");
  const [isHovered, setIsHovered] = useState<number | null>(null);

  const dispatch = useDispatch();
  let currencyName = Props?.currenciesList.find(
    (i: any) => collectionData?.currency == i?.id
  )?.currencyCode;
  const getmainImage = (item: any) => {
    if (item?.itemImages && item?.itemImages.length > 0) {
      let itemURRL = item?.itemImages.find(
        (i: any) => i.displayOrder == 1 && !i.deleted
      )?.vurtualPath;
      if (
        itemURRL === "string" ||
        itemURRL === null ||
        itemURRL === undefined ||
        itemURRL === ""
      ) {
        return noImage;
      } else {
        return itemURRL;
      }
    }
  };

  const endDateFormat = moment(collectionData?.onlineEndDate).format(
    "MMMM D, YYYY"
  );
  const endTimeFormat = moment(collectionData?.onlineEndTime, "HHmmss").format(
    "h:mm A"
  );
  const handleShowPrevBids = (count: any, lId: any) => {
    if (count > 0) {
      setSelectedLotId(lId);
      setShowPrevBids(true);
    }
  };
  const hideModal = () => {
    setShowPrevBids(false);
  };

  // let previewTimeZone = timezonesList.find((i:any)=>collectionData?.timeZoneId === i?.id)?.abbrivation

  // if(!previewTimeZone) {
  //     previewTimeZone = timezonesList.find((i:any)=>collectionData?.timeZoneId === i?.id)?.name;

  // }

  const handleChecktime = (time: any) => {
    const expiresOnDate = new Date(time);
    // Get the current date and time
    const currentDate = new Date();

    // Check if the expiresOnDate is less than the current date
    if (currentDate > expiresOnDate) {
      return true;
    } else {
      return false;
    }
  };

  const handleCheckLocaltime = (time: any) => {
    if (time) {
      const formatedTime = moment.utc(time).local().format("YYYY-MM-DD HH:mm");

      const expiresOnDate = new Date(formatedTime);

      // Get the current date and time
      const currentDate = new Date();

      if (currentDate < expiresOnDate) {
        return true;
      } else {
        return false;
      }
    }
  };
  return (
    <>
      <div className="browse_listing_right_tab_inner_content">
        <PrevBidDetails
          ShowPrevBids={showPrevBids}
          hideModal={hideModal}
          lotId={selectedLotId}
          currencyName={currencyName}
          collectionData={collectionData}
        />
        <div className="browse_listing_inner_content">
          {collectionItems?.length > 0 ? (
            <Row className="filter_list_data_column">
              {(collectionItems || []).map((item: any, index: any) => {
                const currentBid1 =
                  item?.currentBid || item?.initialBiddingPrice;

                return (
                  <Col
                    className="data_column_box data_column_box_list_view"
                    sm={12}
                  >
                    <div className="lot_number d-inline-block">
                      Lot {item.sno}
                    </div>
                    <div className="list_view_cont d-flex">
                      <div className="lots_slider_img lot-page-border">
                        <Link to={`/lotDetails/${item.id}`}>
                          <img
                            src={getmainImage(item)}
                            alt="filterListImg"
                            className="w-100"
                          />
                        </Link>
                      </div>
                      <div className="lots_slider_cont_details">
                        <h3 className="makers-name list-view mt-0">
                          {item?.makerName}
                        </h3>
                        <h4 className="mt-0">{item?.title}</h4>
                        {item?.bidsCount > 0 &&
                          item?.status !== 6 &&
                          item?.status !== 7 &&
                          item?.myBidsCount > 0 && (
                            <>
                              {!item?.outFromBid ? (
                                <div className="card-box-top-right-new card-box-top-right-new-cd green-colo-new">
                                  You Have the Highest Bid
                                </div>
                              ) : (
                                <div className="card-box-top-right-new card-box-top-right-new-cd">
                                  You Have Been Outbid
                                </div>
                              )}
                            </>
                          )}
                        <div className="price_list_btn d-flex flex-column">
                          {!item?.myBidsCount &&
                            !handleCheckLocaltime(collectionData?.startOnUtc) &&
                            item?.status !== 6 &&
                            item?.status !== 7 && (
                              <div className="bidding-open-text">
                                Bidding Open
                              </div>
                            )}
                          {handleCheckLocaltime(collectionData?.startOnUtc) ? (
                            <div className="bidding-not-started">
                              Bidding Not Started
                            </div>
                          ) : null}
                          <div className="lots_cost  priceBlock">
                            <div
                              className={
                                collectionData?.onlineEndDate
                                  ? "lotCloseContainer"
                                  : ""
                              }
                            >
                              {/* {collectionData?.onlineEndDate ? (
                                <>
                                  <span className="d-block">Lot Closes</span>
                                  <span className="strong d-block fullWidth textSize">
                                    {moment(
                                      item?.extentedExpiresOn || item?.expiresOn
                                    ).format("MMMM D, YYYY h:mm A")}{" "}
                                    (
                                    {bannerTimeZone === "EST"
                                      ? "ET"
                                      : bannerTimeZone}
                                    )
                                  </span>
                                </>
                              ) : null} */}
                            </div>
                          </div>
                          <div className="lots_cost lots_cost_lat lots_cost_lat_coll_details">
                            {item?.status === 6 ? (
                              <>
                                <span className="d-block lotDetailsTextRightSide">
                                  Lot Sold:
                                </span>
                                <span className="strong d-block">
                                  {currencyName}{" "}
                                  {item?.soldFor?.toLocaleString()}
                                </span>
                              </>
                            ) : item?.status === 7 ? (
                              <span className="d-block lotDetailsTextRightSide">
                                Bidding is Closed
                              </span>
                            ) : (
                              <>
                                <span className="d-block lotDetailsTextRightSide lot-currentbid-text">
                                  {handleChecktime(collectionData?.startOnUtc)
                                    ? "Current Bid: "
                                    : "Starting Bid: "}{" "}
                                  {handleChecktime(
                                    collectionData?.startOnUtc
                                  ) ? (
                                    <span className="d-block">
                                      {" "}
                                      <span
                                        className={`grid-price-block1 mr-1 ${
                                          isMobile && "d-block"
                                        }`}
                                      >
                                        {currencyName}{" "}
                                        {currentBid1?.toLocaleString()}
                                        {/* {highestBidDetails?.amount?.toLocaleString()} */}
                                      </span>
                                      {` (`}
                                      <span
                                        className={`bids-count-no  ${
                                          item?.bidsCount > 0 && "bids-count"
                                        }`}
                                        onClick={() => {
                                          handleShowPrevBids(
                                            item?.bidsCount,
                                            item?.id
                                          );
                                        }}
                                      >
                                        {item?.bidsCount}{" "}
                                        {item?.bidsCount == 1 ? "Bid" : "Bids"}
                                      </span>
                                      , Reserve
                                      {item?.lotMetReservice ? "" : " Not"} Met
                                      {`)`}
                                    </span>
                                  ) : (
                                    <span className=" d-block ">
                                      <span className="grid-price-block1 mr-1">
                                        {currencyName}{" "}
                                        {currentBid1?.toLocaleString()}
                                        {/* {highestBidDetails?.amount?.toLocaleString()} */}
                                      </span>
                                      (Reserve, Yes)
                                    </span>
                                  )}
                                </span>
                              </>
                            )}
                          </div>
                          <div className="lots_cost">
                            <span className="d-block">Estimate:</span>
                            <p className="priceLine textSize">
                              <>
                                <span className="currency-info d-block ">
                                  {currencyName}{" "}
                                  {item?.estimatedPrice?.toLocaleString()}
                                </span>
                                {item?.estimatedPriceTo && (
                                  <span className="currency-info d-block ">
                                    <>
                                      {" "}
                                      - {currencyName}{" "}
                                      {item?.estimatedPriceTo?.toLocaleString()}
                                    </>
                                  </span>
                                )}
                              </>
                            </p>
                            {/* {item?.status === 6 ? (
                                <p className="lotSoldPriceList">
                                  Lot Sold:{" "}
                                  <span>
                                    {currencyName}{" "}
                                    {item?.soldFor?.toLocaleString()}
                                  </span>
                                </p>
                              ) : item?.status === 7 ? (
                                <p className="lotunSoldPriceList">
                                  {" "}
                                  Bidding is Closed
                                </p>
                              ) : (
                                <></>
                              )} */}
                          </div>

                          {(item?.status === 6 || item?.status === 7) && (
                            <div className="bidding-date-closed-text">
                              {moment
                                .utc(collectionData?.extentedExpiresOnUtc)
                                .tz("America/New_York")
                                .format("MMMM D, YYYY [|] h:mm A")}{" "}
                              (ET)
                            </div>
                          )}

                          {item?.status !== 6 && item?.status !== 7 ? (
                            <div className="bidding-start-text mb-3">
                              <span className="">
                                {!handleCheckLocaltime(
                                  collectionData?.startOnUtc
                                )
                                  ? "Lot Begins Closing: "
                                  : "Bidding Starts: "}
                              </span>
                              {!handleCheckLocaltime(
                                collectionData?.startOnUtc
                              ) ? (
                                <span className="strong fullWidth textSize">
                                  {moment
                                    .utc(item?.expiresOnUtc)
                                    .tz("America/New_York")
                                    .format("MMMM D, YYYY [(]h:mm A")}{" "}
                                  <>{"ET)"}</>
                                </span>
                              ) : (
                                <span className="strong fullWidth textSize">
                                  {moment
                                    .utc(collectionData?.startOnUtc)
                                    .tz("America/New_York")
                                    .format("MMMM D, YYYY [(]h:mm A")}{" "}
                                  {"ET)"}
                                </span>
                              )}
                            </div>
                          ) : null}

                          {Props?.isPastAuction && (
                            <div className="btn_lots mt-auto">
                              {item?.ifollowed ? (
                                <Button
                                  onClick={() => {
                                    Props?.unFollowBid(item?.id);
                                  }}
                                  className=" followingThisBid lots_btn_same d-inline-flex justify-content-center align-items-center text-decoration-none"
                                >
                                  FOLLOWING
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => {
                                    Props?.followBid(item?.id);
                                  }}
                                  className="lots_btn_same d-inline-flex justify-content-center bg-white align-items-center text-decoration-none"
                                >
                                  FOLLOW
                                </Button>
                              )}
                            </div>
                          )}

                          {!Props?.isPastAuction && (
                            <div className="btn_lots mt-auto">
                              {item?.ifollowed ? (
                                <Button
                                  onClick={() => {
                                    Props?.unFollowBid(item?.id);
                                  }}
                                  className=" followingThisBid lots_btn_same d-inline-flex justify-content-center align-items-center text-decoration-none"
                                >
                                  FOLLOWING
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => {
                                    Props?.followBid(item?.id);
                                  }}
                                  className="lots_btn_same d-inline-flex justify-content-center bg-white align-items-center text-decoration-none"
                                >
                                  FOLLOW
                                </Button>
                              )}
                              {collectionData?.auctionType != 1 && (
                                <>
                                  {collectionData?.registredToCollection ? (
                                    <Link
                                      to={`/lotDetails/${item.id}`}
                                      className={`lots_btn_same d-inline-flex justify-content-center bg-white align-items-center text-decoration-none
                                ${
                                  handleChecktime(collectionData?.startOnUtc)
                                    ? "registered-button-text bid-button-collec"
                                    : "registered-button-text"
                                }`}
                                      onMouseEnter={() => setIsHovered(index)}
                                      onMouseLeave={() => setIsHovered(null)}
                                    >
                                      {handleChecktime(
                                        collectionData?.startOnUtc
                                      ) ? (
                                        <span>
                                          <img
                                            src={bidWhite}
                                            className="bid_icon mr-1"
                                          />
                                          BID
                                        </span>
                                      ) : (
                                        <span>REGISTERED</span>
                                      )}
                                    </Link>
                                  ) : (
                                    <div
                                      onClick={registertoBid}
                                      className="lots_btn_same d-inline-flex justify-content-center bg-white align-items-center text-decoration-none"
                                      onMouseEnter={() => setIsHovered(index)}
                                      onMouseLeave={() => setIsHovered(null)}
                                      id={`registered-button-list-${collectionData?.id}`}
                                    >
                                      REGISTER TO BID
                                    </div>
                                  )}
                                </>
                              )}
                              {collectionData?.auctionType == 1 && (
                                <>
                                  {user?.isVerified ? (
                                    <Link
                                      to={`/lotDetails/${item.id}`}
                                      className={`lots_btn_same d-inline-flex justify-content-center bg-white align-items-center text-decoration-none
                                ${
                                  handleChecktime(collectionData?.startOnUtc)
                                    ? "registered-button-text bid-button-collec"
                                    : "registered-button-text"
                                }`}
                                      onMouseEnter={() => setIsHovered(index)}
                                      onMouseLeave={() => setIsHovered(null)}
                                    >
                                      {handleChecktime(
                                        collectionData?.startOnUtc
                                      ) ? (
                                        <span>
                                          <img
                                            src={bidWhite}
                                            className="bid_icon mr-1"
                                          />
                                          BID
                                        </span>
                                      ) : (
                                        <span>REGISTERED</span>
                                      )}
                                    </Link>
                                  ) : (
                                    <div
                                      onClick={registertoBid}
                                      className="lots_btn_same d-inline-flex justify-content-center bg-white align-items-center text-decoration-none"
                                      onMouseEnter={() => setIsHovered(index)}
                                      onMouseLeave={() => setIsHovered(null)}
                                      id={`registered-button-list-${collectionData?.id}`}
                                    >
                                      REGISTER TO BID
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          ) : !Props?.isLoading ? (
            <Row>
              <div className="no-results-sec-coll-details">
                Oops! No results found. Please try adjusting your search or
                filters and try again.
              </div>
            </Row>
          ) : null}
          {/* <div className="load_more_list_btn text-center">
            <Link
              to="/collectionDetails/1"
              className="text-white d-inline-block text-decoration-none"
            >
              Load More
            </Link>
          </div> */}
        </div>
      </div>
    </>
  );
};
export default BrowseTabListContent;
