import React from "react";

// import LiveActionSlider from "../LiveActionSlider";
// import LiveActionTabMenu from "../LiveActionTabMenu";
import { Link } from "react-router-dom";
import ImageGallery from "react-image-gallery";

import WordBanner from "./../../assets/image/word_banner.jpg";
import slide1 from "./../../assets/image/slide1.jpg";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Row, Col } from "react-bootstrap";

import "./css/LiveActionBoxTwo.css";

class LiveActionBoxTwo extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      openLeftSideBar: false,
    };
  }
  createMarkup = (data: any) => {
    return { __html: data };
  };

  render() {
    const images = [
      {
        original: slide1,
        thumbnail: slide1,
      },
      {
        original: WordBanner,
        thumbnail: WordBanner,
      },
      {
        original: slide1,
        thumbnail: slide1,
      },
    ];
    const ListItems = this.props?.collectionItems;
    const collectionData = this.props?.collectionData;
    const lotData = this.props?.lotData;
    let currencyName = this.props?.currenciesList.find(
      (i: any) => collectionData?.currency == i?.id
    )?.currencyCode;
    let imageL = lotData?.itemImages;
    // var codes: ImageList[] = []
    //   class ImageList {
    //     async save() { }
    //   }
    let imageList: any = [];
    imageL?.map((image: any, index: any) => {
      const imageP = image?.vurtualPath;
      let imageT = image?.vurtualPath;
      if (image.displayOrder == 8) {
      } else {
        var temp = { original: imageP, thumbnail: imageT };
        imageList.push(temp);
      }
    });

    return (
      <>
        <div className="auction_box_2">
          <div className="auction_banner_for_main">
            <div className="image_gallery_slider live_action_slider">
              <ImageGallery
                items={imageList}
                showPlayButton={false}
                showFullscreenButton={false}
                //   showNav={false}
              />
            </div>
            <div className="auction_banner_cont">
              <div className="auction_banner_cont_top">
                <h3>{lotData?.title}</h3>
                <div className="lots_cost">
                  <span>Estimate</span>
                  <span className="strong">
                    {currencyName} {lotData?.estimatedPrice}
                    {lotData?.estimatedPriceTo && (
                      <>
                        - {currencyName} {lotData?.estimatedPriceTo}
                      </>
                    )}
                  </span>
                </div>
              </div>
              <div className="auction_banner_cont_tabs">
                <div className="product_description description_tabs_content">
                  <Tabs
                    defaultActiveKey="Description"
                    id="uncontrolled-tab-example"
                    className="prd_des_list"
                  >
                    <Tab eventKey="Description" title="Description">
                      <div className="prdct_des_tab_cont">
                        <div
                          dangerouslySetInnerHTML={this.createMarkup(
                            lotData?.description
                          )}
                        />
                      </div>
                    </Tab>
                    <Tab eventKey="Condition" title="Condition Report">
                      <div className="prdct_des_tab_cont">
                        <div
                          dangerouslySetInnerHTML={this.createMarkup(
                            lotData?.conditionalReport
                          )}
                        />
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default LiveActionBoxTwo;
