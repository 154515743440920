import { get, post, put, del } from "./helpers";
import { API_BASE, SERVER_IP } from "../constants";
import { EXCHANGERATE_API } from "./../../config";
// export const autobidCall = async (body)=> {
//     return await post(`${SERVER_IP}${API_BASE}/Bid/autobid`, body);
//   };
export const autobidCall = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/Bid/autobid`, body);
};
export const autobidCallBroadCast = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/BroadCast/autobid`, body);
};
// /api/BroadCast/autobid
export const quickbidCall = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/Bid/quickbid`, body);
};
export const quickbidCallBroadCast = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/BroadCast/quickbid`, body);
};

export const bidregistrationCall = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/Bid/bidregistration`, body);
};
export const getOutBidByCustomerCall = async (id) => {
  return await get(
    `${SERVER_IP}${API_BASE}/Bid/getOutBidByCustomer?CustomerId=${id}`
  );
};
export const getAllCurrencyItems = async (id) => {
  return await get(`${EXCHANGERATE_API}/latest/USD `);
};

// export const getSelectedCurrencyValue = async (code) => {
//   return await get(`${EXCHANGERATE_API}/latest/${code} `);
// };

export const getBidsPlacedbyItem = async (id, page = 1, PageSize = 20) => {
  return await get(
    `${SERVER_IP}${API_BASE}/AuctionManagement/getbidsplacedbyitem?ItemId=${id}&Page=${page}&PageSize=${PageSize}`
  );
};

//https://api.exchangeratesapi.io/v1/symbols?access_key=`
