import React, { useState } from "react";

import { Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";

import { followbidCall } from "./../../library/Requests";

import MoreCollectionImg from "./../../assets/image/slide1.jpg";
import BidBlack from "../../assets/image/bid-black.png";
import BottomArrow from "./../../assets/image/Bottom_arrow.svg";
import { LOGINID } from "./../../Scenes/common";

import "./css/PurchasesListcontent.css";

class PurchasesListcontent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      footerCollapseOne: false,
      openKey: "",
    };
  }
  getmainImage = (item: any) => {
    if (item?.itemImages && item?.itemImages.length > 0) {
      let itemURRL = item?.itemImages.find(
        (i: any) => i?.displayOrder == 1
      )?.vurtualPath;
      return itemURRL;
    }
  };

  render() {
    // export const PurchasesListcontent: React.FC<props> = (props) => {
    // const [footerCollapseOne,setFooterCollapseOne] = useState(false);
    // const [openKey,setOpenKey] = useState("0");
    const Mylots = this.props?.Mylots;
    const sectionType = this.props?.sectionType;
    console.log(Mylots);
    const currenciesList = this.props?.currenciesList;
    const myfollowedLots = this.props?.myfollowedLots;
    const { footerCollapseOne, openKey } = this.state;
    console.log("openKey", openKey);

    console.log(Mylots,"lot")

    return (
      <>
        <Accordion defaultActiveKey={openKey}>
          {(Mylots || []).map((item: any, index: any) => {
            const keyI = "Section" + index;
            const itemLots = item?.lots;
            console.log(keyI);
            let currencyName =
              currenciesList.find((i: any) => item?.currency == i?.id)
                ?.currencyCode ?? "";

            return (
              <>
                {itemLots?.length > 0 && (
                  <Card>
                    <Card.Header className="pt-3 pb-0 bg-white border-0">
                      <Accordion.Toggle
                        eventKey={keyI}
                        className={`px-0 pt-0 pb-3 bg-white text-left d-flex align-items-center`}
                        onClick={() => {
                          // setFooterCollapseOne(!footerCollapseOne);
                          this.setState({ openKey: keyI });
                        }}
                      >
                        <span className="mr-auto d-inline-block">
                          {item?.title}
                        </span>
                        <img
                          src={BottomArrow}
                          alt="Bottom_arrow"
                          className="ml-1"
                        />
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={keyI}>
                      <Card.Body className="pb-0 px-0 px-sm-3">
                        <div className="more_collection_colum purchas_list">
                          <div className="more_from p-0">
                            <Row className="trending_lots_slider_cont">
                              {itemLots?.length == 0 ? (
                                <p className="ErrorMsg">
                                  No bid information on lots from Past Auctions
                                  is available.
                                </p>
                              ) : (
                                <>
                                  {itemLots.map((lot: any) => {
                                    const uLink =
                                      lot?.bidsCount > 0
                                        ? `/placeBid/${lot?.id}`
                                        : `/lotDetails/${lot?.id}`;
                                    return (
                                      <Col
                                        md={4}
                                        sm={6}
                                        className="lots_slider_cont text-left pb-3 mb-4"
                                        key={lot.id}
                                      >
                                        <div className="lot_number d-inline-block">
                                          Lot {lot.sno}
                                        </div>
                                        <div className="lots_slider_img">
                                          <Link
                                            to={uLink}
                                            className="PurchaseImgStyles"
                                          >
                                            <img
                                              src={this.getmainImage(lot)}
                                              className="w-100"
                                            />
                                          </Link>
                                        </div>
                                        <div className="lots_slider_cont_details">
                                          <h4 className="followed-artist-name">
                                            <Link
                                              to={uLink}
                                              className="PurchaseImgStyles"
                                            >
                                              {lot.makerName}{" "}
                                            </Link>
                                          </h4>
                                          <h4>
                                            <Link
                                              to={uLink}
                                              className="PurchaseImgStyles"
                                            >
                                              {lot.title}{" "}
                                            </Link>
                                          </h4>
                                          {!myfollowedLots && (
                                            <>
                                              {lot?.bidsCount > 0 &&
                                                lot?.status !== 6 &&
                                                lot?.status !== 7 &&
                                                lot?.myBidsCount > 0 && (
                                                  <>
                                                    {!lot?.outFromBid ? (
                                                      <div className="card-box-top-right-new card-box-top-right-new-cd green-colo-new">
                                                        You Have the Highest Bid
                                                      </div>
                                                    ) : (
                                                      <div className="card-box-top-right-new card-box-top-right-new-cd">
                                                        You Have Been Outbid
                                                      </div>
                                                    )}
                                                  </>
                                                )}
                                              <div className="lots_cost lots_cost_lat lots_cost_lat_coll_details">
                                                {lot?.status === 6 ? (
                                                  <>
                                                    <span className="d-block lotDetailsTextRightSide">
                                                      Lot Sold:
                                                    </span>
                                                    <span className="strong d-block">
                                                      {currencyName}{" "}
                                                      {lot?.soldFor?.toLocaleString()}
                                                    </span>
                                                  </>
                                                ) : lot?.status === 7 ? (
                                                  <span className="d-block lotDetailsTextRightSide">
                                                    Bidding is Closed
                                                  </span>
                                                ) : (
                                                  <>
                                                    <span className="d-block lotDetailsTextRightSide">
                                                      Current Bid: (
                                                      {lot?.bidsCount}{" "}
                                                      {lot?.bidsCount == 1
                                                        ? "Bid"
                                                        : "Bids"}
                                                      , Reserve
                                                      {lot?.lotMetReservice
                                                        ? ""
                                                        : " Not"}{" "}
                                                      Met)
                                                    </span>
                                                    <span className="strong d-block ">
                                                      {currencyName}{" "}
                                                      {(
                                                        lot?.currentBid ||
                                                        lot?.initialBiddingPrice
                                                      )?.toLocaleString()}
                                                      {/* {highestBidDetails?.amount?.toLocaleString()} */}
                                                    </span>
                                                  </>
                                                )}
                                              </div>

                                              <div className="price_list_btn d-flex justify-content-between">
                                                <div className="lots_cost">
                                                  <span className="d-block">
                                                    Estimate
                                                  </span>
                                                  <span className="d-block strong">
                                                    {`${currencyName} ${lot.estimatedPrice?.toLocaleString()}`}
                                                    {lot.estimatedPriceTo && (
                                                      <>
                                                        {` - ${currencyName} ${lot.estimatedPriceTo?.toLocaleString()}`}
                                                      </>
                                                    )}
                                                  </span>
                                                </div>
                                                <div className="btn_lots d-flex align-items-center">
                                                  {lot?.ifollowed ? (
                                                    <Button className="action_slider_browse_btn d-table text-decoration-none lots_slider_follow_btn lots_slider_follow_btn_my_lots">
                                                      FOLLOWING
                                                    </Button>
                                                  ) : (
                                                    <Button
                                                      onClick={() => {
                                                        this.props.followBid(
                                                          lot?.id
                                                        );
                                                      }}
                                                      className="action_slider_browse_btn d-table text-decoration-none lots_slider_followFollow_btn "
                                                    >
                                                      FOLLOW
                                                    </Button>
                                                  )}
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </Col>
                                    );
                                  })}
                                </>
                              )}
                            </Row>
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                )}
              </>
            );
          })}
        </Accordion>
      </>
    );
  }
}
export default PurchasesListcontent;
