import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ReactImageMagnify from "react-image-magnify";
import { Link } from "react-router-dom";
// import Carousel from "react-responsive-carousel";
import ImageGallery from "react-image-gallery";
// import "@brainhubeu/react-carousel/lib/style.css";

import "./css/ImageGallerySlider.css";

interface Props {
  images?: any;
}

export const ImageGallerySlider: React.FC<Props> = (Props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [imageUrl, setImageUrl] = useState("");
  const showBigImage = (e: any) => {
    setImageUrl(e.target.src);
    setShow(true);
  };
  return (
    <>
      <div className="image_gallery_slider bid_img_gallery_slider noTransform">
        <ImageGallery
          items={Props?.images}
          showPlayButton={false}
          showFullscreenButton={false}
          showNav={false}
          slideDuration={0}
          disableThumbnailScroll={true}
          onClick={(e) => {
            showBigImage(e);
          }}
          showThumbnails={Props?.images?.length > 1 ? true : false}
        />
      </div>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        className="imageModal"
        dialogClassName="modal-fullscreen"
      >
        <div className="imageSpan">
          <ReactImageMagnify
            {...{
              smallImage: {
                alt: "Wristwatch by Ted Baker London",
                isFluidWidth: true,
                src: imageUrl,
                sizes:
                  "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px",
              },
              largeImage: {
                src: imageUrl,
                width: 1326,
                height: 1330,
              },
              enlargedImagePosition: "over",
              hintTextTouch: "touch",
            }}
          />

          {/* <ReactImageMagnify
            {...{
              largeImage: {
                src: imageUrl,
                width: 1326,
                height: 1330,
                enlargedImagePosition: "over",
              },
              smallImage: {
                isFluidWidth: true,
                src: imageUrl,
                srcset:'small',
                sizes:
                  "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px",
              },
              enlargedImagePosition: "over",
              hintTextTouch: "touch",
            }}
          /> */}
        </div>
      </Modal>
    </>
  );
};
export default ImageGallerySlider;
