import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Button, Row, Col } from "react-bootstrap";

import "./css/ActionCalendarTabContent.css";
import moment from "moment";
import filterListImg from "../../assets/image/slide1.jpg";
import noImage from "../../assets/image/noImage.jpg";
import { render } from "@testing-library/react";
import { isMobile } from "react-device-detect";
import ContentLoader from "react-content-loader";
import ContentLoaderSection from "../../components/ContentLoaderSection/ContentLoaderSection";
import { ReactComponent as LeftIcon } from "../../assets/arrow-left-s-line.svg";

class ActionCalendarTabContent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      seleectedpastMonth: "",
      seleectedpastYear: 0,
      selectedUpcomingMonth: "",
      seleectedupcomingYear: 0,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  }

  componentDidMount() {
    this.setPreviousMonthAndYear();
  }

  componentDidUpdate(prevProps: any) {
    if (
      prevProps.selectedMonth !== this.props.selectedMonth ||
      prevProps.selectedYear !== this.props.selectedYear
    ) {
      this.setPreviousMonthAndYear();
    }
  }

  setPreviousMonthAndYear = () => {
    const { selectedMonth, selectedYear } = this.props;
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Find the index of the selectedMonth in the monthNames array
    let currentMonthIndex = monthNames.indexOf(selectedMonth);
    let upcomingMonthIndex = monthNames.indexOf(selectedMonth);

    let upcomingYear = Number(selectedYear);

    let currentYear = Number(selectedYear);

    if (currentMonthIndex === 0) {
      currentMonthIndex = 11; // December index
      currentYear -= 1;
    } else {
      currentMonthIndex -= 1;
    }

    if (upcomingMonthIndex === 0) {
      upcomingMonthIndex += 1;
    } else if (upcomingMonthIndex === 11) {
      upcomingMonthIndex = 0; // jan index
      upcomingYear += 1;
    } else {
      upcomingMonthIndex += 1;
    }

    console.log(upcomingMonthIndex, "upcomingMonthIndex");

    // Set the previous month and year in state
    this.setState({
      seleectedpastMonth: monthNames[currentMonthIndex],
      seleectedpastYear: currentYear,
      selectedUpcomingMonth: monthNames[upcomingMonthIndex],
      seleectedupcomingYear: upcomingYear,
    });
  };

  render() {
    const {
      selectedTab,
      listDetails,
      listDetailsNew,
      isLoading,
      allCollFull,
      isResultsPage,
      selectedMonth,
      selectedYear,
      setSelectedYears,
      setSelectedMonths,
      setCurrentPage,
      setPageLoadedState,
    } = this.props;
    const {
      months,
      seleectedpastMonth,
      seleectedpastYear,
      selectedUpcomingMonth,
      seleectedupcomingYear,
    } = this.state;


    const handleCheckLocalTime = (time: any) => {
      if (time) {
        // Assume the auctionStartDate is coming in ET (Eastern Time)
        const auctionStartLocal = moment
          .utc(time)
          .local()
          .format("YYYY-MM-DD HH:mm:ss a");

        const currentLocalTime = moment().format("YYYY-MM-DD HH:mm:ss a");

        // Compare the local times to check if the auction has started

        if (currentLocalTime > auctionStartLocal) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    };

    const handleTop = (e: any) => {
      e.preventDefault();
      let vehicleDesc: any = document.getElementById("calender-tabs")!;
      window.scrollTo({
        top: vehicleDesc,
        behavior: "smooth",
      });
      vehicleDesc.click();
    };

    console.log("selectedTab",selectedTab)

    return (
      <>
        <h6 className="d-md-block d-none">
          {selectedMonth} {selectedYear}
        </h6>
        {listDetails?.length === 0 && !isLoading && (
          <p className="noResults noResults-auctionpafw" id="errorPage">
            No calendar results
            <span>Please change your filter options</span>
          </p>
        )}
        <div className="filter_list_data_column">
          {!isLoading && (
            <>
              {(listDetails || []).map((i2: any, index: any) => {
                // let d: Date = new Date(i2.auctionStartDate);
                // let d1: Date = new Date(i2.auctionEndTime);
                // let t: Date = new Date(i2.auctionEndTime.split('T').join(' ').split('Z')[0]);
                return (
                  <div
                    key={index}
                    className="data_column_box data_column_box_list_view m-0 position-relative"
                  >
                    {!isLoading && (
                      <div className="date-wrap position-absolute bg-white text-center d-md-block d-flex">
                        <span
                          className={!isMobile ? "d-block dateLeft" : "d-block"}
                        >
                          {moment(i2?.auctionStartDate).format("D ")}
                          {moment(i2?.auctionStartDate).format("MMM") !=
                            moment(i2?.auctionEndDate).format("MMM") &&
                            moment(i2?.auctionStartDate).format("MMM")}
                          {i2?.auctionEndDate && (
                            <>- {moment(i2?.auctionEndDate).format("D MMM")}</>
                          )}
                        </span>
                        {/* <span className="d-block">{moment(i2?.auctionStartDate).format("MMM")}</span> */}
                      </div>
                    )}

                    {!isLoading && (
                      <Row className="list_view_cont">
                        <Col lg={5} className="lots_slider_img lot-page-border">
                          <div className="list_view_img overflow-hiidden">
                            <Link to={`/collectionDetails/${i2?.id}?tab=${selectedTab}`}>
                              <img
                                // (i2?.coverImage !="string" || i2?.coverImage != null || i2?.coverImage != undefined || i2?.coverImage !="")
                                src={
                                  i2?.coverImage != "string" ||
                                  i2?.coverImage != null ||
                                  i2?.coverImage != undefined ||
                                  i2?.coverImage != ""
                                    ? i2?.coverImage
                                    : noImage
                                }
                                className="w-100"
                                alt="filter_list_img"
                              />
                            </Link>
                          </div>
                        </Col>
                        <Col
                          lg={7}
                          className="lots_slider_cont_details d-flex flex-column"
                        >
                          <div>
                            {isMobile ? (
                              <ul className="border-list m-0 p-0 ">
                                <li className="d-inline-block position-relative">
                                  {moment(i2?.auctionStartDate).format(
                                    "MMMM D"
                                  )}
                                  {i2?.auctionEndDate && (
                                    <>
                                      {" "}
                                      -{" "}
                                      {moment(i2?.auctionEndDate).format(
                                        "MMMM D, YYYY"
                                      )}
                                    </>
                                  )}
                                </li>

                                {i2?.auctionStartTime && (
                                  <>
                                    <li className="d-inline-block position-relative">
                                      {moment(
                                        i2?.auctionStartTime,
                                        "HHmmss"
                                      ).format("h:mm A")}{" "}
                                      {i2?.timeZoneAbbr === "EST"
                                        ? "ET"
                                        : i2?.timeZoneAbbr}
                                    </li>
                                  </>
                                )}

                                <li className="d-inline-block position-relative d-inline-block d-md-none">
                                  {!isResultsPage
                                    ? i2.auctionType === "Online Auction"
                                      ? "Online Only Auction"
                                      : "Online Auction"
                                    : "Past Auction"}
                                </li>
                              </ul>
                            ) : (
                              ""
                            )}
                            <h6 className="d-md-block d-none">
                              {!isResultsPage
                                ? i2.auctionType === "Online Auction"
                                  ? "Online Only Auction"
                                  : "Online Auction"
                                : "Past Auction"}
                            </h6>

                            <h4 className="forMobileViewstyle mb-2 pb-1">
                              {i2.title}
                            </h4>

                            {handleCheckLocalTime(i2?.startOnUtc) &&
                              !isResultsPage && (
                                <div className="bidding-open-text">
                                  Bidding Open
                                </div>
                              )}
                            {!handleCheckLocalTime(i2?.startOnUtc) &&
                            !isResultsPage ? (
                              <div className="bidding-not-started">
                                Bidding Not Started
                              </div>
                            ) : null}
                            {isResultsPage ? (
                              <div className="bidding-not-started">
                                Auction Closed
                              </div>
                            ) : null}

                            {isMobile ? (
                              ""
                            ) : (
                              <ul className="border-list m-0 p-0 forMobileViewstyle">
                                <li className="d-inline-block calender-date-text position-relative">
                                  {!i2?.auctionEndDate
                                    ? moment(i2?.auctionStartDate).format(
                                        "MMMM D, YYYY"
                                      )
                                    : moment(i2?.auctionStartDate).format(
                                        "MMMM D "
                                      )}
                                  {i2?.auctionEndDate && (
                                    <>
                                      {` `}-{" "}
                                      {moment(i2?.auctionEndDate).format(
                                        "MMMM D, YYYY"
                                      )}
                                    </>
                                  )}
                                </li>

                                {i2?.auctionStartTime && (
                                  <>
                                    <li className="d-inline-block position-relative">
                                      {moment(
                                        i2?.auctionStartTime,
                                        "HHmmss"
                                      ).format("h:mm A")}{" "}
                                      {i2?.timeZoneAbbr === "EST"
                                        ? "ET"
                                        : i2?.timeZoneAbbr}
                                    </li>
                                  </>
                                )}

                                <li className="d-inline-block position-relative d-inline-block d-md-none">
                                  {i2.type} Auction
                                </li>
                              </ul>
                            )}
                          </div>
                          <Link
                            to={`/collectionDetails/${i2?.id}?tab=${selectedTab}`}
                            className="browse_btn btn-solid mt-auto-top text-white text-decoration-none text-center"
                          >
                            {isResultsPage
                              ? "View Results"
                              : !handleCheckLocalTime(i2?.startOnUtc)
                              ? "Preview Auction"
                              : "BROWSE AUCTION"}
                          </Link>
                        </Col>
                      </Row>
                    )}
                  </div>
                );
              })}
            </>
          )}

          {isLoading && (
            <div className="spinner_Loading_iconCollectionDetails">
              <ContentLoaderSection section="auctioncalender" />
            </div>
          )}

          {this.props?.isLoadingLoadMore && (
            <div className="spinner_Loading_icon">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {!isLoading &&
            !this.props?.isLoadingLoadMore &&
            allCollFull?.totalCount > listDetailsNew?.length &&
            allCollFull?.totalCount > 18 && (
              <div className="load_more_list_btn text-center">
                <Button
                  onClick={this.props.viewMore}
                  className="loadMore classViewMore text-white d-inline-block text-decoration-none"
                >
                  LOAD MORE
                </Button>
              </div>
            )}
        </div>
        {!isLoading &&
          !this.props?.isLoadingLoadMore &&
          allCollFull?.totalCount === listDetailsNew?.length &&
          isResultsPage && (
            <div className="calender-bottom-sec">
              <h6
                className="d-flex align-items-center"
                onClick={async (e: any) => {
                  setCurrentPage(1);

                  await setSelectedYears(seleectedpastYear);
                  await setSelectedMonths(seleectedpastMonth);
                  setPageLoadedState(true);
                  handleTop(e);
                }}
              >
                <LeftIcon />
                {seleectedpastMonth} {seleectedpastYear}
              </h6>
              <h6
                className="d-flex align-items-center left-side-callender"
                onClick={async (e: any) => {
                  setCurrentPage(1);
                  await setSelectedYears(String(seleectedupcomingYear));
                  await setSelectedMonths(selectedUpcomingMonth);
                  setPageLoadedState(true);
                  handleTop(e);
                }}
              >
                {selectedUpcomingMonth} {seleectedupcomingYear}
                <LeftIcon />
              </h6>
            </div>
          )}
      </>
    );
  }
}
export default ActionCalendarTabContent;
