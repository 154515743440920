import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import AccountSlideBar from "../../components/AccountSlideBar";
import PurchasesListcontent from "../../components/PurchasesListcontent";

import "./css/emailpre.css";

import { getcurrenciesCall } from "./../../library/Requests";
import {
  getCustomerEnabledPreferenceCall,
  updateCustomerpreferenceCall,
  getAllPreferenceCall,
} from "./../../library/Requests/MyAccount";
import { LOGINID } from "./../../Scenes/common";
import Loader from "./../../components/Common/Loader";
class EmailPreferences extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      currenciesList: [],
      Mylots: [],
      errorMsg: false,
      userData: {},
      emailList: [],
      enabledEmailPref: [],
      isLoading: false,
    };
  }
  componentDidMount() {
    // this.getcurrenciesDataCall();
    this.getDataonLoad();
    document.title = "FAHH-myFollowedLots";
  }

  // getcurrenciesDataCall = async () => {
  //   const resp = await getcurrenciesCall();
  //   this.setState({ currenciesList: resp?.data });
  // };
  getDataonLoad = async () => {
    var userDD = {};
    let userInfo = localStorage.getItem("customerInfoDetails");
    if (userInfo != undefined && userInfo != null && userInfo != "") {
      userDD = JSON.parse(userInfo);
    }
    this.setState({ userData: userDD });
    this.getEnabledPref();
    const resp = await getCustomerEnabledPreferenceCall({
      customerId: LOGINID,
    });
    this.setState({ enabledEmailPref: resp?.data });

    // if (resp?.totalCount == 0) {
    //   this.setState({ errorMsg: true });
    // }
    // this.setState({ Mylots: resp?.followedBids });
  };
  getEnabledPref = async () => {
    const resp1 = await getAllPreferenceCall({});
    console.log("resp1", resp1);
    this.setState({ emailList: resp1?.data });
  };
  handleChangeStatus = (item: any, e: any) => {
    const { enabledEmailPref } = this.state;
    const { checked } = e.target;
    console.log("item", item);
    console.log("checked", checked);
    const idx = enabledEmailPref?.findIndex(
      (u: any) => u.preferenceGroupId === item?.id
    );
    if (idx !== -1) {
      enabledEmailPref[idx].enabled = checked;
    }
    console.log("enabledEmailPref", enabledEmailPref);
    this.setState({ enabledEmailPref });
  };
  handleUpdatePreferences = async () => {
    const { enabledEmailPref } = this.state;
    this.setState({ isLoading: true });
    const resp = await updateCustomerpreferenceCall({
      preference: enabledEmailPref,
    });
    this.getEnabledPref();
    this.setState({ isLoading: false });
    console.log("resp", resp);
  };
  render() {
    const {
      currenciesList,
      Mylots,
      errorMsg,
      emailList,
      userData,
      enabledEmailPref,
      isLoading,
    } = this.state;
    return (
      <>
        {/* */}
        <div className="email_preferences_page_content account_all_page">
          <Container>
            {isLoading && <Loader />}
            <Row className="purchases_page_left_right_content">
              <AccountSlideBar />
              <Col lg={9} className="email-preferences-content-page">
                <div className="email-preferences-page">
                  <h3>Manage Your Preferences</h3>
                  <h4>Your Email</h4>
                  <div className="your-email-box-sec">{userData?.email}</div>
                  {emailList?.length > 0 && (
                    <>
                      <div className="your-email-sec-header-content">
                        What type of email would you prefer to receive?
                      </div>
                      <div className="list-email-prefes-sec-box">
                        {emailList?.map((item: any) => {
                          const isEna = enabledEmailPref?.find(
                            (ittMM: any) => ittMM?.preferenceGroupId == item?.id
                          )?.enabled;
                          console.log("enabledEmailPref", enabledEmailPref);
                          console.log("emailList", emailList);
                          console.log("isEna", isEna);
                          console.log("item?.id", item?.id);
                          return (
                            <div className="list-email-prefes-single-box">
                              <div className="email-sec-box-left">
                                <p>{item?.preferenceGroup}</p>
                                <p>{item?.description}</p>
                              </div>
                              <div className="email-sec-box-right">
                                <input
                                  onChange={(e) => {
                                    this.handleChangeStatus(item, e);
                                  }}
                                  checked={isEna}
                                  type="checkbox"
                                />
                              </div>
                            </div>
                          );
                        })}
                        <div className="list-email-prefes-single-box">
                          <div className="email-sec-box-left">
                            <p>Unsubscribe All Emails</p>
                            {/* <p>{item?.description}</p> */}
                          </div>
                          <div className="email-sec-box-right">
                            <input
                              // onChange={(e) => {
                              //   this.handleChangeStatus(item, e);
                              // }}
                              // checked={isEna}
                              type="checkbox"
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="unsubscribe-box">
                        <div className="email-sec-box-right">
                          <span>
                            <input
                              // onChange={(e) => {
                              //   this.handleChangeStatus(item, e);
                              // }}
                              // checked={isEna}
                              type="checkbox"
                            />{" "}
                            <span className="unsubscribe-text"></span>
                          </span>
                        </div>
                      </div> */}
                      <div className="update-preferences-box">
                        <button onClick={this.handleUpdatePreferences}>
                          UPDATE PREFERENCES
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default EmailPreferences;
