import React, { Component } from "react";
import { Row, Col, Card } from "react-bootstrap";
import "./styles.css";

import { connect } from "react-redux";
import * as actionCreators from "./../../actions/index.js";
import moment from "moment-timezone";
import html2PDF from "jspdf-html2canvas";

import {
  getCollectionItemsCall,
  getCollectionByIdCall,
  getcurrenciesCall,
  followbidCall,
  getTimeZones,
  unfollowbidCall,
} from "./../../library/Requests";

class PrintPage extends React.Component {
  static propTypes = {};
  state = {
    collectionData: {},
    currenciesList: [],
    timezonesList: [],
    CollectionItems: [],
    isDownloadPage: false,
  };
  componentDidMount() {
    console.log(this.props);
    if (
      this.props?.location?.pathname.indexOf("/collectionDataDownload") > -1
    ) {
      this.setState({ isDownloadPage: true });
    }
    const id = this.props?.match?.params?.id;
    this.getcurrenciesDataCall();
    this.gettimezonesDataCall();
    this.getData(id);
  }
  getcurrenciesDataCall = async () => {
    const resp = await getcurrenciesCall();
    this.setState({ currenciesList: resp?.data });
  };
  gettimezonesDataCall = async () => {
    const timezoneResp = await getTimeZones();
    this.setState({ timezonesList: timezoneResp?.data });
  };
  getData = async (id) => {
    const userI = this.props?.user?.id;
    let resp = await getCollectionByIdCall(id, userI);
    this.setState({ collectionData: resp?.data });
    const body = {
      page: 1,
      pageSize: 5,
      collectionId: id,
    };
    let resp1 = await getCollectionItemsCall(body);
    this.setState({ CollectionItems: resp1?.data });
    const a = this;
    if (this.state.isDownloadPage) {
      setTimeout(() => {
        a.printPdf();
      }, 100);
    } else {
      setTimeout(() => {
        window.print();
      }, 100);
    }
  };
  getmainImage = (item) => {
    if (item?.itemImages && item?.itemImages.length > 0) {
      let itemURRL = item?.itemImages.find(
        (i) => i.displayOrder == 1
      )?.vurtualPath;
      return itemURRL;
    }
  };
  createMarkup = (data) => {
    return { __html: data };
  };

  printPdf = async () => {
    const page = document.getElementsByClassName("printContainer");
    const pdf = await html2PDF(page, {
      jsPDF: {
        format: "a4",
        unit: "px",
        putOnlyUsedFonts: false,
        floatPrecision: "smart",
      },
      margin: {
        top: 10,
        right: 10,
        bottom: 0,
        left: 10,
      },
      html2canvas: {
        scrollX: 0,
        scrollY: -window.scrollY,
        imageTimeout: 0,
        useCORS: true,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight,
        removeContainer: false,
        scale: 2,
      },
      imageQuality: 12,
      imageType: "image/jpg",

      init: (this.settingFont = (pdf) => {
        pdf.setFont("PTSan");
        pdf.setFontSize(5);
      }),
      success: (this.settingFont = (pdf) => {}),
    });
    pdf.setFontSize(8);
    pdf.save("Download.pdf");
  };

  render() {
    const { collectionData, CollectionItems, currenciesList, timezonesList } =
      this.state;
    let currencyName = currenciesList.find(
      (i) => collectionData?.currency == i?.id
    )?.currencyCode;
    let bannerTimeZone = timezonesList.find(
      (i) => collectionData?.timeZoneId == i?.id
    )?.abbrivation;
    let bannerTimeZone1 = bannerTimeZone === "EST" ? "ET" : bannerTimeZone;
    return (
      <div className="container printContainer">
        {/* <Row className="dotted"></Row> */}
        <center>
          <p className="HeadingText">FineArt.HipHop</p>
        </center>
        <Row className="geaderNametitle">
          <h2 className="collectionHeading">{collectionData?.title}</h2>
          {collectionData?.auctionType == 1 ? (
            <>
              <p className="HeadingDate">
                Date :{" "}
                {moment(collectionData?.startOnUtc).format("MMMM D, YYYY")}
                {` `} -{" "}
                {moment(collectionData?.endOnUtc).format("MMMM D, YYYY")}{" "}
                {bannerTimeZone1}
              </p>
            </>
          ) : (
            <p className="HeadingDate">
              Date : {moment(collectionData?.startOnUtc).format("MMMM D, YYYY")}{" "}
              {bannerTimeZone1}{" "}
            </p>
          )}
        </Row>
        <Row>
          <p className="SaleText">Sale Overview</p>
        </Row>
        <Row>
          <p className="SaleAllText">
            {" "}
            <div
              dangerouslySetInnerHTML={this.createMarkup(
                collectionData?.collectionOverview
              )}
            />{" "}
          </p>
        </Row>
        <Row>
          <p className="SaleText">Condition Of Business</p>
        </Row>
        <Row>
          <p className="SaleAllText">
            {" "}
            <div
              dangerouslySetInnerHTML={this.createMarkup(
                collectionData?.conditionOfSale
              )}
            />
          </p>
        </Row>
        <Row>
          <p className="SaleInformationText">Sale Information</p>
        </Row>
        <hr className="hrRow" />
        <Row>
          <Col className="noPadding">
            <p className="saleInfoText">
              <span className="ModeColor">Mode :</span>{" "}
              {collectionData?.auctionType == 1 ? "Online" : "Live"}
            </p>
            <p className="saleInfoText">
              <span className="DateColor">
                Date :
                {collectionData?.auctionType == 1 ? (
                  <>
                    {moment(collectionData?.startOnUtc).format("MMMM D, YYYY")}{" "}
                    {` `}-{" "}
                    {moment(collectionData?.endOnUtc).format("MMMM D, YYYY")}{" "}
                    {bannerTimeZone1}
                  </>
                ) : (
                  <>
                    {" "}
                    {moment(collectionData?.startOnUtc).format(
                      "MMMM D, YYYY"
                    )}{" "}
                    {bannerTimeZone1}
                  </>
                )}
              </span>
            </p>
            <p className="saleInfoText">
              <span className="TotalLotsColor">Total Lots :</span>{" "}
              {CollectionItems?.length}
            </p>
            <p className="saleInfoText ContactInformationText">
              Contact Information :
            </p>
            <p className="ContactInformation">
              <span>support@fineart.hiphop</span>
            </p>
          </Col>
        </Row>
        <Row>
          <h4 className="lotsHeadingText">Lots</h4>
        </Row>
        {(CollectionItems || []).map((item) => {
          return (
            <>
              <hr className="cardHrRow" />
              <Row className="printCardRow">
                <Col sm="3" className="noPadding">
                  <Card className="printCard">
                    <Card.Img
                      src={this.getmainImage(item)}
                      className="printImageSize"
                    />
                  </Card>
                </Col>
                <Col className="noPadding">
                  <p className="printLotNumber">Lot {item?.sno} </p>
                  <p className="printCardTitle">{item?.title}</p>

                  {item?.estimatedPriceTo ? (
                    <p>
                      <span className="printEstimatePrice">Estimate:</span>{" "}
                      {item?.estimatedPrice?.toLocaleString()} -{" "}
                      {item?.estimatedPriceTo?.toLocaleString()} {currencyName}
                    </p>
                  ) : (
                    <p>
                      <span className="printEstimatePrice">Estimate:</span>{" "}
                      {item?.estimatedPrice?.toLocaleString()} {currencyName}
                    </p>
                  )}
                </Col>
              </Row>
            </>
          );
        })}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps, actionCreators)(PrintPage);
