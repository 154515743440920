import React, { useState, Component } from "react";
import { Row, Col, Accordion, Card, Button, Form } from "react-bootstrap";
import { ReactComponent as Line } from "./../../assets/Line.svg";
import "./style.css";
import { getAllSiteManagersCall } from "../../library/Requests/SiteManager";
import { getAllCategories } from "../../library/Requests";
import moment from "moment";
import Article1Main from "./../../assets/articleimages/article3-main.png";
import Article1Image from "./../../assets/articleimages/article1.png";
import Article2Image from "./../../assets/articleimages/article2.png";
import Article3Image from "./../../assets/articleimages/article3.png";

import article3img1 from "./../../assets/articleimages/article3img1.png";
import article3img2 from "./../../assets/articleimages/article3img2.png";
import shareicon from "./../../assets/main/share-fill.png";
import { DOMAIN_URL } from "../../library/constants";
import ShareModal from "./../../components/ShareModal/ShareModal";

const Article3 = () => {
  const [shareOpen, setShareOpen] = useState(false);
  const lotPageUrl = `${DOMAIN_URL}article3`;
  const moreList = [
    {
      title: "Article",
      text: "Introducing FineArt.HipHop",
      dateText: "August 13th, 2023",
      image: Article1Image,
      link: "/theBirthOfHipHop",
    },
    {
      title: "Article",
      text: "Frozen Moments",
      dateText: "August 13th, 2023",
      image: Article2Image,
      link: "/frozenMoments",
    },
  ];
  return (
    <>
      <div className="wrapper">
        <ShareModal
          show={shareOpen}
          handleClose={() => {
            setShareOpen(false);
          }}
          url={lotPageUrl}
          title={"Frozen Moments"}
          onCopyClick={() => {
            navigator.clipboard.writeText(lotPageUrl);
          }}
        />
        <div className="topbannerSection">
          <div className="manageSiteManagerView1"></div>
          <div className="manageSiteManagerView manageSiteManagerView2">
            <Row className="imageSectionView">
              <img
                src={Article1Main}
                className="d-block w-100 tk "
                alt="clicked-slider-img"
              />
            </Row>
            <div className="share-icon-header-sec">
              <button
                onClick={() => {
                  setShareOpen(true);
                }}
              >
                <img src={shareicon} />
              </button>
            </div>
            <div className="BottomSectionView BottomSectionView1">
              <Row className="bannerContentRow">
                <Col className="bannerTextContent">
                  <p className="bannerCategoryText">
                    <Line fill="#FDD490" className="CategoryTextLine" />{" "}
                    <span className="finalTextcolorName">Article</span>{" "}
                  </p>
                  <p className="bannerTitleText">
                    <span className="finalTextcolorName">
                      {`Frozen Moments`}
                    </span>{" "}
                  </p>
                  <p className="bannerSubText">
                    <span className="finalTextcolorName">
                      {" "}
                      by Claude “Paradise” Gray | August 13th, 2023
                    </span>{" "}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="PreviewArticleContainer">
          <Card className="previewArticle">
            <Card.Body className="previewArticleBody">
              <div className="article-sec-con-desi">
                <div className="article-sec-con-left">
                  <Row className="plain-text-row">
                    I started collecting flyers in elementary school. I was a
                    young kid who was also collecting baseball cards and comic
                    books but when I saw the party flyers for Hip Hop jams
                    posted up at the barber shop on Ogden Avenue in Highbridge,
                    I nearly lost my mind. The pairing of art and music totally
                    captivated me and launched me into a metaverse of Hip Hop
                    superheroes with flamboyant names.
                  </Row>

                  <Row className="plain-text-row">
                    I remember when I saw an Iron Man character on a Grandmaster
                    Flash flyer at the Audubon Ballroom where Malcolm X was
                    killed. The Spider Man figure on a Grandmaster Flowers flyer
                    I saw when my family moved from the Bronx to Brooklyn. And
                    even my childhood hero Muhammad Ali drawn on a flyer by the
                    legendary writer Phase 2.
                  </Row>

                  <Row className="plain-text-row">
                    While the names of the DJs and MCs were flamboyant on the
                    flyers, so were the names of the guys who created them:
                    Buddy Esquire, Phase 2, Straightman, AR, Danny T and many
                    others. These guys were my introduction to fine art; they
                    were Picassos, Michelangelos and Andy Warhols. The flyers
                    they created every weekend were pieces of Hip Hop history,
                    they are the receipts frozen moments in time. They are
                    historical documents and the glue that keeps the culture
                    together. Hip Hop has its share of folklore and there are
                    those who are prone to exaggerate and even lie, but the
                    flyers don’t lie.
                  </Row>
                </div>
                <div className="article-sec-con-right">
                  <Row className="row-image-article">
                    <img className="v-img" src={article3img1}></img>
                  </Row>
                  <div className="shadow-text-row">
                    Renaissance Ballroom: Harlem, NY 1979 • Designer unknown
                  </div>
                </div>
              </div>

              <div className="article-sec-con-desi1">
                <div className="article-sec-con-left">
                  <Row className="row-image-article">
                    <img className="v-img" src={article3img2}></img>
                  </Row>
                  <div className="shadow-text-row">
                    T-Connection: Bronx, NY 1981 • Designer Buddy Esquire, Poo 2
                    & Eddie Ed
                  </div>
                </div>
                <div className="article-sec-con-right">
                  <div className="plain-text-row">
                    I’ve been collecting flyers for close to 46 years and I’ve
                    protected and preserved them like they were made of gold not
                    just paper. With this historic auction we hope to share our
                    love and passion for the flyers and the graphic artists who
                    created them.
                  </div>
                </div>
              </div>

              <div className="the-end-sec">
                <div className="the-end-sec-content">
                  <span className="dash-line"></span>
                  <span className="dash-text">END</span>
                  <span className="dash-line"></span>
                </div>
              </div>
              <Row className="more-from-word-sec">
                <p className="MoreRowText">
                  <Line fill="#FDD490" className="MoreTextLine" />
                  <span className="">More from the Word</span>
                </p>
              </Row>

              <Row className="headerPreviewContent ImagesRows">
                {(moreList || [])?.map((item: any, index: any) => {
                  console.log("inside", item);
                  let userLink = item.link;

                  return (
                    <>
                      <Col
                        sm={12}
                        md={4}
                        xs={12}
                        className="ImagesCols col-12 col-sm-12"
                      >
                        <a target="_self" href={userLink}>
                          <Card className="ImageCards col-sm-12">
                            <Card.Img className="ImageMore" src={item.image} />
                          </Card>

                          <p className="StoriesText">
                            <Line fill="#FDD490" className="StoriesLine" />
                            {item.title}
                          </p>
                          <p className="TheCollectionText col-sm-12 col-xs-12">
                            {item.text}
                          </p>
                          <p className="DateTexts">August 13th, 2023</p>
                        </a>
                      </Col>
                    </>
                  );
                })}
              </Row>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};
export default Article3;
