import React, { useState } from "react";

import bigScreenImg from "../../assets/image/big_screen.png";
import LiveActionVideoList from "../../components/LiveActionVideoList";

import bidWhiteImg from "../../assets/image/bid-white.png";
import vect3 from "../../assets/image/vect3.png";

import videoIcon from "./../../assets/image/play-button.svg";
import closeIcon from "./../../assets/image/close.svg";

import "./css/LiveActionVideoContent.css";
// import LiveVideo from "./../../pages/LiveVideo";
class LiveActionVideoContent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      fullScreenMode: false,
      openLeftSideBar: false,
    };
  }
  render() {
    const { fullScreenMode, openLeftSideBar } = this.state;
    const collectionData = this.props?.collectionData;
    const lotData = this.props?.lotData;
    const idCollection = this.props?.idCollection;
    const highestBidDetails = this.props?.highestBidDetails;
    const nextBidAmount = this.props?.highestBidDetailsFull?.nextBidAmount ?? 0;
    let currencyName = this.props?.currenciesList.find(
      (i: any) => collectionData?.currency == i?.id
    )?.currencyCode;

    return (
      <>
        <div
          className="d-block d-lg-none live_action_right_sidebar_icon"
          onClick={() => {
            this.setState({ openLeftSideBar: true });
          }}
        >
          <img src={videoIcon} alt="menu-icon" />
        </div>
        <div
          className={`auction_box_3 auction_box_right ${
            openLeftSideBar ? "active" : ""
          }`}
        >
          <div className="auction_box_vedio_sec">
            <div className="live_action_video">
              <div>
                {/* <LiveVideo
                  BiddingLodding={this.props?.BiddingLodding}
                  collectionData={collectionData}
                  triggerBidCall={this.props?.triggerBidCall}
                  lotData={lotData}
                  highestBidDetails={highestBidDetails}
                  currencyName={currencyName}
                  idCollection={idCollection}
                  nextBidAmount={nextBidAmount}
                  lotItemStatus={this.props?.lotItemStatus}
                  dropdownvalues={this.props?.dropdownvalues}
                  streamCode={this.props.streamCode}
                /> */}
              </div>
            </div>
          </div>
          {/* <div className="auction_box_inner_list">
          <ul className="reset">
            <LiveActionVideoList />
          </ul>
        </div> */}
          {/* <div className="btn_lots">
          <a
            href="inner3.html"
            className="lots_btn_same position-absolute text-white w-100 d-flex align-items-center justify-content-center text-decoration-none"
          >
            <img src={bidWhiteImg} className="bid_icon mr-1" />
            BID - USD 1600 12
          </a>
        </div> */}
        </div>
        <img
          src={closeIcon}
          alt="close-icon"
          className="right_video_close_bar position-absolute d-block d-lg-none"
          onClick={() => {
            this.setState({ openLeftSideBar: false });
          }}
        />
      </>
    );
  }
}
export default LiveActionVideoContent;
