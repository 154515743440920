import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./styles.css";

const CustomModal = (props) => {
  return (
    <Modal
      {...props}
      // size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={props?.longText ? "long-text-message-flow" : ""}
    >
      {props?.isAuctionCompleted ? (
        <>
          <Modal.Body className="modalBodyDefault modalBodyCompleted">
            <p className="modalBodyText">Auction Closed</p>
            <p className="modalBodyTextYThanks">Thank you for participating.</p>
            <button onClick={props.viewMoreClick} className="viewResultsBtn">
              {" "}
              View Results
            </button>
          </Modal.Body>
        </>
      ) : (
        <>
          <Modal.Body className="modalBodyDefault">
            <p className="modalBodyText">{props.titleText}</p>
            {props?.subTitleText && (
              <p className="modal-the-sub-title-text">{props.subTitleText}</p>
            )}
          </Modal.Body>
          <Modal.Footer
            className={
              props?.bidSuccess
                ? "bidSuccess-popup-sec"
                : props?.showCancelBtn
                ? "cancel-btn-popup-latest"
                : ""
            }
          >
            {props.cancelText != "" && (
              <Button onClick={props.onCancel} className="modalCandelBtn">
                {props.cancelText}
              </Button>
            )}
            {props.deleteText != "" && (
              <Button onClick={props.onDelete} className="modalDeleteBtn">
                {props.deleteText}
              </Button>
            )}
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default CustomModal;
