import styled from "styled-components";

export const CreateNewPasswordStyled = styled.div`
  .create-header {
    color: #111;
    text-align: center;
    font-family: Noto Serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .error_msg {
    color: red;
  }
  .Create-New-password-sec {
    width: 650px;

    margin: auto;
    margin-top: 80px;
  }
  .create-sub-header {
    color: #111;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
    margin-top: 24px;
    margin-bottom: 40px;
  }
  .create-submit-sec {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 140px;
  }
  .create-submit-sec button {
    border-radius: 4px;
    border: 1px solid #111;
    background: #111;
    color: #fff;
    font-family: Noto Serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 220px;
    height: 50px;
  }
  .create-submit-sec button:disabled {
    opacity: 0.5;
  }
  .input-group-btn {
    float: right;
    margin-top: -40px;
  }
  .input-group-btn span {
    cursor: pointer;
  }
  .create-password-sec-error .input-group-btn {
    margin-right: 20px;
  }
  @media (max-width: 700px) {
    .Create-New-password-sec {
      width: 80%;
      margin: auto;
    }
  }
`;
