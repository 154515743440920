import React, { Component } from "react";
import "./style.css";
class PrivacyPolicy extends React.Component {
  componentDidMount() {
    document.title = "FAHH-Privacy Policy";
  }
  render() {
    return (
      <div className="contactUsContainer">
        <p className="contact-us-main-header">Privacy Policy</p>
        <div className="shipping-box-header terms-con-add-space">
          <p className="pri-poli-header-con">
            PRIVACY POLICY/TERMS OF SERVICE – FINEART.HIPHOP INC. LAST UPDATED
            ON AUGUST 12, 2023.
          </p>
          <p className="pri-poli-header-con-sec">
            FineArt.HipHop Inc. is a U.S. company and subject only to U.S. laws
            and jurisdiction, so for the moment these Terms of Service (and any
            other terms, policies, or guidelines that we provide to you) are
            only available in English. <br />
            <div></div>
            Hello! Welcome to FineArt.HipHop Inc.’s Terms of Service for The
            FineArt.HipHop app and website. Please read this carefully before
            using our app and website location, services, or products. This is a
            contract between you and FineArt.HipHop Inc. for The FineArt.HipHop
            app and website and its related app and website location, products
            and services. Please feel free to contact us with any questions or
            suggestions.{" "}
          </p>
          <div className="pri-poli-header-con-sec-page">
            <p>
              <span>1. ACCEPTING THE TERMS OF SERVICE</span>
            </p>
            <p>
              Please read these Terms of Service carefully before using The
              FineArt.HipHop app and website (the "App and website") and/or the
              other domains, webapp and websites, products, app, mobile app and,
              services, and/or Content provided by FineArt.HipHop Inc. (all of
              those collectively with the App and website, the "Services";
              FineArt.HipHop Inc., a Delaware corporation, collectively with its
              agents, representatives, consultants, employees, officers, and
              directors, "FineArt.HipHop," "we," or "us"). By using or accessing
              the Services, you ("App and website Subscriber" or "you") agree to
              be bound by all the terms and conditions of this Agreement. If you
              don’t agree to all the terms and conditions of this Agreement, you
              shouldn't, and aren't permitted to, use the Services.{" "}
            </p>
            <p>
              <span> 2. MODIFICATIONS TO THIS AGREEMENT</span>
            </p>
            <p>
              FineArt.HipHop reserves the right to modify this Agreement by (1)
              posting a revised Agreement on and/or through the Services and (2)
              providing notice to you that this Agreement has changed, generally
              via email where practicable, and otherwise through the Services
              (such as through a notification on the App and website).
              Modifications will not apply retroactively. You are responsible
              for reviewing and becoming familiar with any modifications to this
              Agreement. We may sometimes ask you to review and to explicitly
              agree to (or reject) a revised version of this Agreement. <br />{" "}
              <div></div>
              In such cases, modifications will be effective at the time of your
              agreement to the modified version of this Agreement. If you do not
              agree at that time, you are not permitted to use the Services. In
              cases where we do not ask for your explicit agreement to a
              modified version of this Agreement, but otherwise provide notice
              as set forth above, the modified version of this Agreement will
              become effective fourteen days after we have posted the modified
              Agreement and provided you
            </p>
            <p>
              <span> 3. USE OF THE SERVICES</span>
            </p>
            <p>
              Eligibility: No individual under the age of thirteen (13) may use
              the Services, provide any personal information to FineArt.HipHop,
              or otherwise submit personal information through the Services
              (including, for example, a name, address, telephone number, or
              email address). You may only use the Services if you can form a
              binding contract with FineArt.HipHop and are not legally
              prohibited from using the Services.{" "}
            </p>
            <p>
              <span>
                {" "}
                4. USE OF ACCOUNTS THROUGH THIRD PARTIES AND SECURITY{" "}
              </span>
            </p>
            <p>
              As a condition to using certain of the Services, you may be
              required to create an account with a Single Sign-On provider (an
              "Account") and select a password and SSO username. FineArt.HipHop
              does not store or have access to your Account including your
              password. You agree that FineArt.HipHop is not responsible for
              managing any of your Account, including your Account information.
              You are responsible for maintaining the security of your Account.{" "}
            </p>
            <p>
              <span> 5. PRIVACY</span>
            </p>
            <p>
              For information about how FineArt.HipHop collects, uses, and
              shares your information, please review this Privacy Policy. You
              agree that by using the Services you consent to the collection,
              use, and sharing as set forth, of such information, including the
              transfer of this information to the United States and/or other
              countries for storage, processing, and use by FineArt.HipHop.{" "}
            </p>
            <p>
              <span>
                {" "}
                6. CONTENT AND NO ABILITY TO CREATE APP AND WEBSITE SUBSCRIBER
                CONTENT
              </span>
            </p>
            <p>
              Definitions: For purposes of this Agreement: (1) the term
              "Content" means a creative expression and includes, without
              limitation, video, audio, photographs, images, illustrations,
              animations, logos, tools, written posts, replies, comments,
              information, data, text, software, scripts, executable files,
              graphics, Themes (as defined below), and interactive features, any
              of which may be generated, provided, or otherwise made accessible
              on or through the Services; (2) the term "App and website
              Subscriber Content" means Content that an App and website
              Subscriber submits, transfers, or otherwise provides to the
              Services. THERE IS NO APP AND WEBSITE SUBSCRIBER CONTENT
              ASSOCIATED WITH THIS APP AND WEBSITE. Any Content created by you
              will be through third-party SSO providers and is governed by SSO
              provider’s Content creation terms. You agree that by using the App
              and website you will abide by the terms of the SSO terms of use to
              the extent you can access any feature of respective SSO from the
              App and website. You also agree that you will respect the
              intellectual property rights of others.{" "}
            </p>
            <p>
              <span> 7. OBJECTIONABLE CONTENT POLICY</span>
            </p>
            <p>
              There is no tolerance for objectional content or abusive users.
              The App and website may display content from social media entities
              and the App and website provides you with a pointer to the social
              media provider location where you can flag any objectionable
              content displayed through the App and website or report abusive
              users (please note, there is no ability for users to contact one
              another through the App and website). Users may be enabled to post
              content through social media services and such postings are
              subject to each respective social media service’s terms of use.{" "}
            </p>
            <br />
            <div></div>
            <p>
              Example of objectionable content include (i) sexually explicit
              materials; (ii) obscene, defamatory, libelous, slanderous, violent
              and/or unlawful content or profanity; (iii) content that infringes
              on the rights of any third party, including copyright, trademark,
              privacy, publicity or other personal or proprietary right, or that
              is deceptive or fraudulent; (iv) content that promotes the use or
              sale of illegal or regulated substances, tobacco products,
              ammunition and/or firearms; and (v) gambling of any kind. Through
              the mechanism we identify in the App and website a user can flag
              content they deem objectionable for review. The App and website
              relies upon the respective social media service to follow through
              with any such requests. If a user is not satisfied with the
              respective social media service’s handling of the request for
              review, then the user can contact FineArt.HipHop via email at
              “admin@fineart.hiphop”. In addition, the App and website has a
              mechanism for users to filter sensitive postings – the default is
              set to not display what has been determined to be “sensitive”
              postings. Additional third-party content is shown on the App and
              website in certain sections, and such content is not user
              generated.
            </p>
            <p>
              <span> 8. LIMITED LICENSE</span>
            </p>
            <p>
              By using the App and website you are agreeing to be bound by the
              terms and conditions of this limited license included herein (the
              Agreement). This Agreement is a legal agreement between you
              (either an individual or a single entity) and FineArt.HipHop and
              it governs your use of the App and website made available to you
              by FineArt.HipHop. If you do not agree to the terms of this
              Agreement, uninstall the App and website or do not download or use
              the App and website. The App and website is licensed, not sold, to
              you by FineArt.HipHop for use strictly in accordance with the
              terms of this Agreement. FineArt.HipHop grants you a revocable,
              nonexclusive, non-transferable, limited license to download,
              install and use the App and website strictly in accordance with
              the terms of this Agreement.{" "}
            </p>
            <p>
              <span> 9. RESTRICTIONS</span>
            </p>
            <p>
              You agree not to, and you will not permit others to: license,
              sell, rent, lease, assign, distribute, transmit, host, outsource,
              disclose or otherwise commercially exploit the App and website or
              make the App and website available to any third party. modify,
              make derivative works of, disassemble, decrypt, reverse compile or
              reverse engineer any part of the App and website. remove, alter or
              obscure any proprietary notice (including any notice of copyright
              or trademark) of FineArt.HipHop or its affiliates, partners,
              suppliers or the licensors of the App and website.{" "}
            </p>
            <p>
              <span> 10. INTELLECTUAL PROPERTY</span>
            </p>
            <p>
              The App and website, including without limitation all copyrights,
              patents, trademarks, trade secrets and other intellectual property
              rights are, and shall remain, the sole and exclusive property of
              FineArt.HipHop.{" "}
            </p>
            <p>
              <span> 11. LIMITATION ON LIABILITY</span>
            </p>
            <p>
              Notwithstanding any damages that you might incur, the entire
              liability of FineArt.HipHop and any of its suppliers under any
              provision of this Agreement and your exclusive remedy for all of
              the foregoing shall be limited to the amount actually paid by you
              for the App and website. To the maximum extent permitted by app
              and applicable law, in no event shall FineArt.HipHop or its
              suppliers be liable for any special, incidental, indirect, or
              consequential damages whatsoever (including, but not limited to,
              damages for loss of profits, for loss of data or other
              information, for business interruption, for personal injury, for
              loss of privacy arising out of or in any way related to the use of
              or inability to use the App and website, third-party software
              and/or third-party hardware used with the App and website, or
              otherwise in connection with any provision of this Agreement),
              even if FineArt.HipHop or any supplier has been advised of the
              possibility of such damages and even if the remedy fails of its
              essential purpose. Some states/jurisdictions do not allow the
              exclusion or limitation of incidental or consequential damages, so
              the above limitation or exclusion may not app and websitely to
              you.{" "}
            </p>
            <p>
              <span> 12. INDEMNIFICATION</span>
            </p>
            <p>
              You agree to indemnify and hold FineArt.HipHop and its parents,
              subsidiaries, affiliates, officers, employees, agents, partners,
              and licensors (if any) harmless from any claim or demand,
              including reasonable attorneys' fees, due to or arising out of
              your: (a) use of the App and website; (b) violation of this
              Agreement or any law or regulation; or (c) violation of any right
              of a third party.{" "}
            </p>
            <p>
              <span> 13. NO WARRANTIES</span>
            </p>
            <p>
              The App and website is provided to you "AS IS" and "AS AVAILABLE"
              and with all faults and defects without warranty of any kind. To
              the maximum extent permitted under app and applicable law,
              FineArt.HipHop , on its own behalf and on behalf of its affiliates
              and its and their respective licensors and service providers,
              expressly disclaims all warranties, whether express, implied,
              statutory or otherwise, with respect to the App and website,
              including all implied warranties of merchantability, fitness for a
              particular purpose, title and non-infringement, and warranties
              that may arise out of course of dealing, course of performance,
              usage or trade practice. Without limitation to the foregoing,
              FineArt.HipHop provides no warranty or undertaking, and makes no
              representation of any kind that the App and website will meet your
              requirements, achieve any intended results, be compatible or work
              with any other software, App and websites, systems or services,
              operate without interruption, meet any performance or reliability
              standards or be error free or that any errors or defects can or
              will be corrected. Without limiting the foregoing, neither
              FineArt.HipHop nor any FineArt.HipHop' provider makes any
              representation or warranty of any kind, express or implied: (i) as
              to the operation or availability of the App and website, or the
              information, content, and materials or products included thereon;
              (ii) that the App and website will be uninterrupted or error-free;
              (iii) as to the accuracy, reliability, or currency of any
              information or content provided through the App and website; or
              (iv) that the App and website, its servers, the content, or
              e-mails sent from or on behalf of FineArt.HipHop are free of
              viruses, scripts, trojan horses, worms, malware, timebombs or
              other harmful components. Some jurisdictions do not allow the
              exclusion of or limitations on implied warranties or the
              limitations on the app and applicable statutory rights of a
              consumer, so some or all of the above exclusions and limitations
              may not apply to you.{" "}
            </p>
            <p>
              <span> 14. GOVERNING LAWS</span>
            </p>
            <p>
              The laws of Delaware, United States, excluding its conflicts of
              law rules, shall govern this Agreement and your use of the App and
              website. Your use of the App and website may also be subject to
              other local, state, national, or international laws.{" "}
            </p>
            <br />
            <br />
            <p>
              To report concerns or feedback related to the privacy policy/terms
              of use please contact us at the below addresses:{" "}
            </p>
            <p className="address-privacy-poli">
              FineArt.HipHop Inc. <br />
              Privacy Officer <br />
              368 Rector Street, Unit 216 <br />
              Perth Amboy, NJ 08861 <br />
              E-mail: legal@FineArt.HipHop
            </p>
            <p>
              In your letter, please describe in as much detail as possible ways
              in which you believe the Privacy Policy has not been complied
              with. We will investigate your complaint promptly.{" "}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default PrivacyPolicy;
