import React, { useState, useEffect } from "react";
import moment from "moment";
import * as signalR from "@microsoft/signalr";
import CountdownTimer from "./Counter/CountdownTimer";
import {
  convertUTCToLocaltime,
  convertUTCToLMilleSec,
  convertLMilleSec,
} from "../../Scenes/common";
import { BiddingInfoStyled } from "./BiddingInfo.styled";
const LotDetailsTime = (props: any) => {
  const {
    vehicleDetails,
    biddingShowText,
    lotData,
    isPastAuction,
    collectionData,
    pageFrom,
    bannerTimeZone,
    updateIsExpiredButton,
    dateText,
    frompage,
  } = props;
  const [biddingShowNo, setBiddingShowNo] = useState("");
  const [biddingShowText1, setBiddingShowText1] = useState("");
  const [isPastCollection, setIsPastCollection] = useState(false);
  const [widthProgress, setWidthProgress] = useState("0%");
  const [showProgressBarSec, setShowProgressBarSec] = useState(true);
  const [biddingClosed, setBiddingClosed] = useState(false);

  const showProgressBarsecFn = (flag: any, seconds: any) => {
    setShowProgressBarSec(flag);
    showTimeProgressBar(seconds);
  };
  var i = 0;
  const showTimeProgressBar = (seconds: any) => {
    const seconds1 = seconds || 0;
    const secounds22 = parseInt(seconds1);
    const seconds2 = seconds1 ? 60 - secounds22 : 0;
    const newW = seconds2 * 1.66;
    // elem.style.width = width + "%";
    setWidthProgress(`${newW}%`);

    // if (i == 0) {
    //   i = 1;
    //   var elem = document.getElementById("myProgress-myBar");
    //   var width = seconds2;
    //   var id = setInterval(function () {
    //     if (width >= 60) {
    //       clearInterval(id);
    //       i = 0;
    //       setShowProgressBarSec(false);
    //     } else {
    //       width++;
    //       const newW = width * 1.66;
    //       console.log("newW", newW);
    //       console.log("width", width);
    //       // elem.style.width = width + "%";
    //       setWidthProgress(`${newW}%`);
    //     }
    //   }, 1000);
    // }
  };
  const targetDate = convertUTCToLocaltime(
    lotData?.extentedExpiresOnUtc
  ).toString();
  // const collSLocalTim = convertUTCToLMilleSec(
  //   vehicleDetails?.auction_createdon_utc
  // );
  const expiresOn = convertUTCToLMilleSec(lotData?.expiresOnUtc);
  const extentedExpiresOnUtc = convertUTCToLMilleSec(
    lotData?.extentedExpiresOnUtc
  );
  const currentTime = convertLMilleSec();
  const currentTime_plus_20 = currentTime + 432000000;
  const hour_1_before = currentTime + 3600000;
  const before_15_mins = currentTime + 900000;

  const collSLocalTim = convertUTCToLMilleSec(collectionData?.startOnUtc);
  const collELocalTim = convertUTCToLMilleSec(collectionData?.endOnUtc);
  const originCollEndTime = convertUTCToLMilleSec(collectionData?.liveEndDate);
  // const currentTime = convertLMilleSec();
  // const currentTime_plus_20 = currentTime + 1728000000;
  const sDate =
    collectionData.auctionType === 2
      ? collectionData?.liveStartDate
      : collectionData?.onlineStartDate;
  const sTime =
    collectionData.auctionType === 2
      ? collectionData?.liveStartTime
      : collectionData?.onlineStartTime;

  useEffect(() => {
    if (props.fromPage === "placebid") {
      if (currentTime > extentedExpiresOnUtc) {
        props.isBiddingClosed();
      }
    } else if (props.pageFrom === "myactivebid") {
      if (currentTime > extentedExpiresOnUtc) {
        // props.biddingClosed();
      }
    }
  }, [extentedExpiresOnUtc, currentTime]);

  return (
    <>
      <BiddingInfoStyled widthProgress={widthProgress}>
        <div
          className={`${
            frompage === "lotpage" ? "lot-closes-time" : "lot-timer-sec"
          }`}
        >
          {frompage === "lotpage" && (
            <p className="lot-begin-text">{dateText ? dateText : ""}</p>
          )}
          {currentTime < collSLocalTim ? (
            <>
              {moment(sDate).format("MMMM D, YYYY")}
              {" | "}
              {moment(sTime, "HHmmss").format("h:mm A")} (
              {bannerTimeZone === "EST" ? "ET" : bannerTimeZone})<p></p>
            </>
          ) : currentTime_plus_20 < extentedExpiresOnUtc ? (
            <>
              {frompage === "placebid" && (
                <p className="lot-begin-text">{dateText ? dateText : ""}</p>
              )}
              <p className="lot-begin-date">
                {/* {moment(lotData?.extentedExpiresOnUtc).format(
                  "MMMM D, YYYY h:mm A"
                )} */}
                {moment
                  .utc(lotData?.extentedExpiresOnUtc)
                  .tz("America/New_York")
                  .format("MMMM D, YYYY [(]h:mm A")}{" "}
                {"ET)"}
              </p>
            </>
          ) : currentTime < extentedExpiresOnUtc ? (
            <>
              <div className="full-time-sec-amount-sec-count">
                <div
                  className={`place-bid-time-sec-count-time place-bid-time-sec-count-time-${pageFrom}`}
                >
                  {" "}
                  <p
                    className={
                      hour_1_before > extentedExpiresOnUtc
                        ? "time-red-color"
                        : ""
                    }
                  >
                    <CountdownTimer
                      targetDate={targetDate}
                      showExtraText={false}
                      showProgressBarsec={showProgressBarsecFn}
                      updateIsExpiredButton={() => {
                        updateIsExpiredButton();
                        setBiddingClosed(true);
                      }}
                    />
                    {!biddingClosed ? (
                      <>
                        {currentTime > expiresOn ? (
                          <span className="time-red-color">Extended</span>
                        ) : (
                          <>
                            {before_15_mins > extentedExpiresOnUtc
                              ? "Lot is Closing"
                              : "Until Lot Begins Closing"}
                          </>
                        )}
                      </>
                    ) : (
                      <>Bidding is Closed</>
                    )}
                  </p>
                </div>
                <div className="place-bid-time-sec-count-bar">
                  {showProgressBarSec && !biddingClosed && (
                    <div className="main-sec-top-progress-bar">
                      <div
                        id="my-progress-myProgress"
                        className="my-progress-myProgress"
                      >
                        <div
                          id="myProgress-myBar"
                          className="myProgress-myBar"
                        ></div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : currentTime > extentedExpiresOnUtc ? (
            <>
              {pageFrom === "myactivebid" ? (
                <div className="bidding-closed-text-active">
                  <p>Bidding is Closed </p>
                  <p>
                    {moment(lotData?.extentedExpiresOnUtc).format(
                      "MMMM D, YYYY [|] h:mm A"
                    )}
                    {` `}({bannerTimeZone === "EST" ? "ET" : bannerTimeZone})
                  </p>
                </div>
              ) : (
                <>
                  <p>Bidding is Closed</p>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </BiddingInfoStyled>{" "}
    </>
  );
};
export default LotDetailsTime;
