import styled from "styled-components";

export const AccountActivationStyled = styled.div`
  .create-header {
    color: #111;
    text-align: center;
    font-family: Noto Serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .error_msg {
    color: red;
  }
  .Create-New-password-sec {
    width: 650px;

    margin: auto;
    margin-top: 80px;
  }
  .create-sub-header {
    color: #111;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
    margin-top: 24px;
    margin-bottom: 40px;
    text-align: center;
  }
  @media (max-width: 700px) {
    .Create-New-password-sec {
      width: 80%;
      margin: auto;
    }
  }
`;
