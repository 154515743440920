import React, { useEffect, useState } from "react";

import { FreshDeskCreateTicketStyled } from "./FreshDeskCreateTicket.styled";
import { Container, Row, Col, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";

import { ReactComponent as PasswordCrossIcon } from "./../../assets/image/passwordCross.svg";
import { ReactComponent as PasswordTickIcon } from "./../../assets/image/passwordTick.svg";
import { handleCreateTicketCall } from "../../library/Requests/MyAccount";
import CustomModal from "../../components/CustomModel";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const FreshDeskCreateTicket = () => {
  const [userPassword, setUserPassword] = useState("");
  const [ticketText, setTicketText] = useState("");
  const [userPasswordError, setUserPasswordError] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    // checkAccountValid();
  }, []);

  const handleCreateTicket = async () => {
    if (ticketText) {
      const jsonV = {
        name: "vinod",
        description: ticketText,
        subject: "Fahh ticket",
        status: 2,
        priority: 1,
        source: 2,
        requester_id: 129,
        phone: "4123434",
        email: "vinod@gmail.com",
      };
      const newResp = await handleCreateTicketCall(jsonV);
      console.log("newResp", newResp);
      // if (newResp?.success) {
      setShowSuccessMessage(true);
      //   // window.location.href = "/";
      // } else if (newResp?.errors[0]) {
      //   setErrorMessage(newResp?.errors[0]);
      // }
    }
  };
  const handleChnageValue = (e: any) => {
    const { name, value } = e.target;

    setTicketText(value);
  };
  return (
    <>
      <FreshDeskCreateTicketStyled>
        <div className="Create-New-password-sec">
          <div className="create-header">Create Ticket</div>
          <div className="create-ticket-container">
            <textarea
              placeholder="Please Enter the Ticket Desc."
              rows={7}
              name="ticketText"
              onChange={handleChnageValue}
              value={ticketText}
              cols={50}
            ></textarea>
            <div>
              <button onClick={handleCreateTicket}>SUBMIT</button>
            </div>
          </div>
          <div className="create-sub-header"> </div>
        </div>
      </FreshDeskCreateTicketStyled>{" "}
    </>
  );
};
export default FreshDeskCreateTicket;
