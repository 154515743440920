import styled from "styled-components";

export const MyActiveBidsStyled = styled.div<{
  widthProgress?: any;
}>`
  .local-text-red-coll {
    float: right;
    background: var(--error-colour-400, #cc1433);
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    padding: 16px 14px;
  }
  .your-max-bid-amount-text-bidquickbid {
    margin-bottom: 10px;
  }
  .coll-close-time {
    width: 100%;
    display: flex;
    justify-content: end;
  }
  .my-bids-count-my-active-bid-page {
  }
  .lotDetailsTextRightSide {
    color: rgba(0, 0, 0, 0.5);
    font-family: "BentonSansSmall";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .lots_cost_lat_coll_details {
    margin-top: 15px;
    .strong {
      color: #333;
      font-family: "BentonSansRegular", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
    .lots_cost_lat_coll_details .lotDetailsTextRightSide{
      font-family: "BentonSansLight", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform:uppercase;
    }
  .card-box-reserve-met-time {
    text-align: right;
    margin-bottom: 12px;
  }
  .card-box-reserve-met-time span {
    font-family: "BentonSansLight", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .card-box-reserve-met-time .bidding-closed-text-active {
    color: #7f7f7f;
  }
  .card-box-con-middle-row a:hover {
    text-decoration: none;
  }
  .local-text-red-coll img {
    margin-right: 12px;
  }
  .local-text-red-coll span {
    margin-left: 8px;
  }
  .card-box {
    border-radius: 4px;
    border: 1px solid #ededed;
    background: #fff;
    padding: 20px;
    max-width: 560px;
    margin-top: 20px;
  }
  .card-box-top {
    display: flex;
    gap: 32px;
    justify-content: space-between;
  }
  .card-box-top-row {
    display: flex;
    gap: 32px;
    justify-content: space-between;
  }
  .show-timer-sec {
    color: #cc1433;
  }
  .main-sec-top-progress-bar {
    width: 100%;
    display: flex;
    justify-content: end;
  }
  .my-progress-myProgress {
    width: 100%;
    background-color: #ddd;
    height: 7px;
    margin-bottom: 20px;
    border-radius: 4px;
  }
  // .myProgress-myBar {
  //   height: 7px;
  //   width: ${({ widthProgress }) => widthProgress || "0%"};
  //   background-color: #eb5454;
  //   border-radius: 4px;
  // }
  .card-box-top-left {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    background: #fff;
    // width: 60px;
    color: #111;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.56px;
    padding: 6px 14px;
  }
  .card-box-top-right {
    color: #ef9b29;
    font-family: "BentonSansMedium";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform:uppercase;
  }
  .card-box-bottom {
    margin-top: 17px;
    display: flex;
    gap: 15px;
  }
  .card-box-b-img {
    width: 30%;
    height: 170px;
    img {
      object-fit: fill;
      max-width: 100%;
      max-height: 170px;
    }
  }
  .card-box-b-img-row {
    width: 25%;
    height: 170px;
    img {
      object-fit: contain;
      max-width: 100%;
      // max-height: 170px;
      height:100%;
      width: 100%;
    }
  }
  .card-box-con-middle-row {
    width: 40%;
  }
  .card-box-b-con-row {
    width: 35%;
  }
  .card-box-b-con {
    width: 70%;
  }
  .card-box-con-title {
    overflow: hidden;
    color: #111;
    text-overflow: ellipsis;
    font-family: "Mercury Display A", "Mercury Display B", MercuryDisplay-wb,
      "Mercury Display", serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 26px; /* 144.444% */
    text-transform:uppercase;
  }
  .card-box-con-estimate {
    margin-top: 16px;
    color: rgba(0, 0, 0, 0.5);
    font-family: "BentonSansLight" !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    span {
      color: #333;
      font-family: "BentonSansRegular" !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .show-timer-sec-sec-cont p {
    font-size: 14px !important;
    font-weight: 500;
    font-family: BentonSansLight;
    color: #333;
  }
  .card-box-bid-amount {
    display: flex;
    gap: 15px;
    margin-top: 18px;
    margin-bottom: 10px;
  }
  .select-bid-amount-sec label {
    color: rgba(0, 0, 0, 0.74);
  font-family: "BentonSansRegular" !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  .select-bid-amount-sec select {
    border-radius: 4px;
    border: 1px solid #000;
    height: 48px;
    font-family: "BentonSansRegular";
    font-size:16px;
  }
  .card-box-bid-amount-row {
    display: flex;
    gap: 15px;
    // margin-top: 18px;
    margin-bottom: 10px;
    justify-content: space-between;
    .card-box-amount {
      margin-top: -3px;
      position: relative;
    }
  }
  .card-box-bid {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .card-box-amount {
    color: #ef9b29;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .card-box-reserve-met {
    color: rgba(0, 0, 0, 0.5);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 25px;
  }
  .card-box-actions {
    display: flex;
    gap: 15px;
  }
  .card-box-actions-row {
    display: flex;
    justify-content: end;
    gap: 15px;
    .card-box-increase-bid {
      width: 100%;
      cursor: pointer;
    }
  }
  .card-box-cancel-bid {
    border: 1px solid #000;
     font-family: "BentonSansRegular";
    padding: 14px 5px;
    border-radius: 4px;
    cursor: pointer;
    width: 40%;
    text-align: center;
    background: #fff;
    font-size: 16px;
  }
    .card-box-cancel-bid:hover{
        background: #000;
        color:#fff;    }
  .card-box-place-bid {
    border: none;
    padding: 14px 5px;
    border-radius: 4px;
    cursor: pointer;
    width: 70%;
    background: #000;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    font-family: "BentonSansRegular";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .card-box-increase-bid img {
    margin-right: 10px;
  }
  .your-max-bid-amount-text-bid {
    color: #50ae00;
    font-size: 14px;
    text-align: right;
    font-family: "BentonSansRegular";
    span {
      white-space: nowrap;
    }
  }
  .select-bid-amount-sec {
    width: 100%;
  }
  .card-box-place-bid1 {
    font-size: 16px;
  }
  .card-box-top-rightl-last {
    min-width: 200px;
  }
  .card-box-quick-bid {
    width: 35%;
    border-radius: 4px;
    border: 1px solid #000;

    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
    height: 54px;
    padding-top: 13px;
    text-align: center;
  }
  .card-box-increase-bid {
    width: 65%;
    border-radius: 4px;
    border: 1px solid #000;
    background: #000;
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
    height: 54px;
    padding-top: 13px;
    text-align: center;
  }
  .my-active-bids-list {
    display: flex;
    justify-content: center;
    .my-no-active-bids {
          text-align: center;
    margin-top: 100px;
    margin-bottom: 50px;
    font-size: 24px;
}
    }
  }
  .my-active-bids-list-con {
    width: 90%;
    margin: auto;
  }
  .my-active-bids-list-con-row {
    width: 78%;
    margin: auto;
    .card-box {
      max-width: 1050px;
      position: relative;
    }
  }
  .green-colo {
    color: #50ae00;
  }
  @media (max-width: 990px) {
    .card-box {
      max-width: 100%;
    }
    .coll-close-time {
      margin-top: 20px;
    }
    .card-box-quick-bid,
    .card-box-increase-bid {
      // font-size: 13px;
      // padding-top: 5px;
    }
    .my-active-bids-list-con-row {
      width: 100%;
    }
    .card-box-top-row {
      display: block;
      height: 35px;
    }
    .card-box-top-left {
      width: 89px;
      float: left;
      text-align: center;
    }
    .card-box-top-right,
    .card-box-top-rightl-last {
      text-align: right;
    }
    .card-box-bottom {
      display: block;
    }

    .card-box-b-img-row,
    .card-box-con-middle-row,
    .card-box-b-con-row {
      width: 90%;
      margin: auto;
    }
    .card-box-con-middle-row {
      margin-top: 20px;
      margin-left: 0px;
    }
    .card-box-top-right,
    .lotDetailsTextRightSide {
      font-size: 14px;
    }
  }
  @media (min-width: 991px) and (max-width: 1200px) {
    .card-box-cancel-bid {
      font-size: 9px;
    }
    .card-box-place-bid {
      font-size: 12px;
    }
  }
  @media (min-width: 991px) and (max-width: 1045px) {
    .card-box-cancel-bid {
      font-size: 8px;
    }
    .card-box-place-bid {
      font-size: 9px;
    }
  }
  @media (min-width: 1201px) and (max-width: 1370px) {
    .card-box-cancel-bid {
      font-size: 12px;
    }
    .card-box-place-bid {
      font-size: 14px;
    }
  }
  @media (max-width: 990px) {
    .card-box-cancel-bid {
      font-size: 11px;
    }
    .card-box-place-bid {
      font-size: 14px;
    }
  }
  @media (max-width: 450px) {
    .card-box-place-bid1 {
      font-size: 12px;
    }
  }
`;
