import React, { useState, Component } from "react";
import { Row, Col, Accordion, Card, Button, Form } from "react-bootstrap";
import { ReactComponent as Line } from "./../../assets/Line.svg";
import "./style.css";
import {
  createarticleDataCall,
  getarticlebyidCall,
} from "./../../library/Requests/ContentManagement";
import { getAllSiteManagersCall } from "./../../library/Requests/SiteManager";
import { getAllCategories } from "./../../library/Requests";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { createJSDocReturnTag } from "typescript";

class viewArticle extends Component {
  static propTypes = {};

  state = {
    allBanners: [],
    articleID: 0,
    bannerId: 0,
    ArticleData: {},
    viewArticle: true,
    articlePublishPopup: false,
    currentBanner: {},
    allCategories: [],
  };
  componentDidMount = () => {
    const id = this.props?.match?.params?.id;
    this.setState({ bannerId: id });
    this.getAllSiteManagers(id);
  };
  getAllSiteManagers = async (id) => {
    const resp = await getAllSiteManagersCall();
    const bannersList = resp?.data;
    let articleIDNo = bannersList.find((i2) => id == i2?.id)?.articleId;
    let bannerType = bannersList.find((i2) => id == i2?.id)?.type;
    let list = bannersList.filter((item) => item.type == bannerType);
    let currentBanner1 = bannersList.filter((item) => item.id == id)[0];
    this.setState({
      articleID: articleIDNo,
      allBanners: list,
      currentBanner: currentBanner1,
    });
    this.getArticleById(articleIDNo);
    const cate = await getAllCategories();
    this.setState({ allCategories: cate?.data });
  };
  getArticleById = async (id) => {
    let respInfo = await getarticlebyidCall(id);
    let someCollection = respInfo.data;
    this.setState({ ArticleData: someCollection });
  };
  createMarkup = (data) => {
    return { __html: data };
  };
  render() {
    const {
      ArticleData,
      viewArticle,
      articlePublishPopup,
      allBanners,
      allCategories,
      currentBanner,
    } = this.state;
    let cateName = allCategories.find(
      (i2) => currentBanner?.categoryId == i2?.id
    )?.category;
    console.log("currentBanner", currentBanner);
    return (
      <>
        <div className="wrapper">
          <div className="topbannerSection">
            <div className="manageSiteManagerView">
              <Row className="imageSectionView">
                <img
                  src={currentBanner?.image}
                  className="d-block w-100 tk"
                  alt="clicked-slider-img"
                />
              </Row>
              <div className="BottomSectionView">
                <Row className="bannerContentRow">
                  <Col className="bannerTextContent">
                    <p className="bannerCategoryText">
                      <Line fill="#FDD490" className="CategoryTextLine" />{" "}
                      <span className="finalTextcolorName">{cateName}</span>{" "}
                    </p>
                    <p className="bannerTitleText">
                      <span className="finalTextcolorName">
                        {currentBanner?.title?.substr(0, 40)}{" "}
                      </span>{" "}
                    </p>
                    <p className="bannerSubText">
                      <span className="finalTextcolorName">
                        {" "}
                        "Auther Name" | August 13th, 2023
                      </span>{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div className="PreviewArticleContainer">
            <Card className="previewArticle">
              <Card.Body className="previewArticleBody">
                <Row className=" btnsRowPreview">
                  {/* <span className="textHeaderPreview">Fineart Hiphop &nbsp; | &nbsp; {moment(ArticleData?.createdOnUtc).format("MMM Do, YYYY")} </span>
                    <span className="textHeaderPreview"><Love/> 0 &nbsp;&nbsp; &nbsp;&nbsp; <Share/> 0</span> */}
                </Row>
                <Row className="headerPreviewContent">
                  <p className="PreviewTitle">{ArticleData?.title} </p>
                </Row>
                <Row className="headerPreviewContent">
                  <p className="PreviewAuthor"> By {ArticleData?.author} </p>
                </Row>
                <Row className="headerPreviewContent imageContainer">
                  <Row className="imageRow">
                    {ArticleData?.featuredImage ? (
                      <img
                        src={ArticleData?.featuredImage}
                        alt="Preview"
                        className="previewImage"
                      />
                    ) : null}
                  </Row>
                </Row>
                <Row className="headerPreviewContent headerContentViewArticle">
                  <div
                    dangerouslySetInnerHTML={this.createMarkup(
                      ArticleData?.articalContent
                    )}
                  />
                </Row>

                <Row className="headerPreviewContent">
                  <p className="PreviewAdescCon"> {ArticleData?.excerpt}</p>
                  <p className="PreviewAdescCon"> {ArticleData?.tags}</p>
                </Row>

                <Row>
                  <p className="MoreRowText">
                    <Line fill="#FDD490" className="MoreTextLine" />
                    <span className="">More from the</span>{" "}
                    {currentBanner?.type == 1 ? "Hero" : "Word"}
                  </p>
                </Row>

                <Row className="headerPreviewContent ImagesRows">
                  {(allBanners || [])?.map((item, index) => {
                    console.log("inside", item);
                    let userLink = item.textLink;
                    if (item?.articleId && item?.articleId != "") {
                      userLink = `/viewArticle/${item?.id}`;
                    }
                    return (
                      <>
                        {currentBanner?.id != item?.id && (
                          <Col
                            sm={12}
                            md={4}
                            xs={12}
                            className="ImagesCols col-12 col-sm-12"
                          >
                            <a target="_self" href={userLink}>
                              <Card className="ImageCards col-sm-12">
                                <Card.Img
                                  className="ImageMore"
                                  src={item.image}
                                />
                              </Card>

                              <p className="StoriesText">
                                <Line fill="#FDD490" className="StoriesLine" />
                                Stories
                              </p>
                              <p className="TheCollectionText col-sm-12 col-xs-12">
                                {item.title}
                              </p>
                              <p className="DateTexts">january 19,2021</p>
                            </a>
                          </Col>
                        )}
                      </>
                    );
                  })}
                </Row>
              </Card.Body>
            </Card>
          </div>
        </div>
      </>
    );
  }
}

export default viewArticle;
