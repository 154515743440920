import React from "react";
import { Col, Row, Button } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import SliderOne from "./../../assets/image/slide1.jpg";
import SliderTwo from "./../../assets/image/slide2.jpg";
import { ReactComponent as Blackbid } from "../../assets/image/blackbidIcon.svg";
import "./css/LotsSlider.css";
import { LOGINID } from "./../../Scenes/common";
import {
  getTrandingLots,
  followbidCall,
  getcurrenciesCall,
} from "./../../library/Requests";

import { connect } from "react-redux";
import * as actionCreators from "./../../actions/index.js";
import noImage from "../../assets/image/noImage.jpg";

import { AFTER_LOGIN_TRIGGER_PAGE } from "./../../library/Requests/eventConstants";

class LotsSlider extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      allData: [],
      currenciesList: [],
    };
  }

  componentDidMount() {
    const self = this;
    setTimeout(() => {
      // self.getAllSiteManagers();
      self.getcurrenciesDataCall();
    }, 1000);
    window.document.addEventListener(
      AFTER_LOGIN_TRIGGER_PAGE,
      function (event) {
        self.followBid(
          self.props?.afterLoginTriggerPage?.lotId ||
            self.props.lotIdNumberValue?.lotId
        );
      },
      false
    );
  }
  getcurrenciesDataCall = async () => {
    const resp = await getcurrenciesCall();
    this.setState({ currenciesList: resp?.data });
  };
  getAllSiteManagers = async () => {
    console.log("LOGINID", LOGINID);
    const bodt = {
      page: 1,
      pageSize: 10,
      isTrending: true,
      customerId: LOGINID,
    };
    const resp = await getTrandingLots(bodt);
    this.setState({ allData: resp?.data });
  };
  getmainImage = (item: any) => {
    if (item?.itemImages && item?.itemImages.length > 0) {
      let itemURRL = item?.itemImages.find(
        (i: any) => i.displayOrder == 1
      )?.vurtualPath;
      if (
        itemURRL === "string" ||
        itemURRL === null ||
        itemURRL === undefined ||
        itemURRL === ""
      ) {
        return noImage;
      } else {
        return itemURRL;
      }
    }
  };
  followBid = async (id: any) => {
    this.props.afterLoginTriggerPageCall({});
    const { allData } = this.state;
    let userInfo = localStorage.getItem("customerInfoDetails");
    if (userInfo != undefined && userInfo != null && userInfo != "") {
      const userIn = JSON.parse(userInfo);
      if (userIn?.id) {
        const index = allData.findIndex((item: any) => item?.id === id);
        if (index > -1) {
          allData[index].ifollowed = true;
        }
        const body = {
          follow: {
            itemId: id,
            customerId: userIn?.id,
            isFollow: true,
          },
        };
        this.setState({ allData: allData });
        const resp = await followbidCall(body);
        this.props.followeddata(true);
      }
    } else {
      const jsonD = {
        pageName: "followClick",
        lotId: id,
      };
      this.props.afterLoginTriggerPageCall(jsonD);
      this.props.LopinPopUpPage(true);
    }
  };
  unFollowBid = async (id: any) => {
    const { allData } = this.state;
    let userInfo = localStorage.getItem("customerInfoDetails");
    if (userInfo != undefined && userInfo != null && userInfo != "") {
      const userIn = JSON.parse(userInfo);
      if (userIn?.id) {
        const index = allData.findIndex((item: any) => item?.id === id);
        if (index > -1) {
          allData[index].ifollowed = false;
        }

        const body = {
          follow: {
            itemId: id,
            customerId: userIn?.id,
            isFollow: false,
          },
        };
        const resp = await followbidCall(body);
        this.props.followeddata(false);
        this.setState({ allData: allData });
      }
    }
  };
  gotoDetailsPage = (url: any) => {
    this.props.history.push(url);
  };

  render() {
    const responsive = {
      desktop: {
        breakpoint: { max: 1920, min: 1400 },
        items: 4.1,
        slidesToSlide: 1,
      },
      tablet: {
        breakpoint: { max: 1399, min: 992 },
        items: 4.1,
        slidesToSlide: 1,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1.1,
        slidesToSlide: 1,
      },
    };

    const { allData, currenciesList } = this.state;
    console.log(allData);
    const trendingcount = allData?.length ?? 0;
    return (
      <>
        {trendingcount > 0 && (
          <>
            <div className="custom_slider_heading_filter d-flex align-items-center">
              <h3 className="mb-0 mr-auto position-relative pl-3">
                Lots that might interest you
              </h3>
            </div>
            <div className="custom_action_slider">
              <Carousel
                responsive={responsive}
                removeArrowOnDeviceType={["mobile"]}
                arrows={true}
              >
                {allData.map((item: any, index: any) => {
                  // let currencyName = currenciesList.find((i:any) => item?.currency == i?.id)?.currencyCode;
                  let currencyName = item?.currency;
                  return (
                    <div
                      key={item?.id}
                      className="action_slider_content lots_slider_content"
                    >
                      <div
                        onClick={() => {
                          this.gotoDetailsPage(`/lotDetails/${item?.id}`);
                        }}
                        className="action_slider_img overflow-hidden"
                      >
                        <img
                          src={this.getmainImage(item)}
                          className="img-fluid border-0 h-100"
                        />
                      </div>
                      <div className="action_slider_body_content">
                        <h3 className="action_slider_heading overflow-hidden">
                          {item?.title}
                        </h3>
                        <div className="lots_slider_text_btn d-md-flex align-items-start">
                          <div className="lots_slider_text mr-auto">
                            <span>Estimate</span>
                            <p>
                              {currencyName} {item?.estimatedPrice}
                              {item?.estimatedPriceTo && (
                                <>
                                  - {currencyName} {item?.estimatedPriceTo}
                                </>
                              )}{" "}
                            </p>
                          </div>
                          <div className="d-flex align-items-center">
                            {item?.ifollowed ? (
                              <Button
                                onClick={() => {
                                  this?.unFollowBid(item?.id);
                                }}
                                className=" followingThisBid action_slider_browse_btn d-table text-decoration-none lots_slider_follow_btn"
                              >
                                FOLLOWING
                              </Button>
                            ) : (
                              <Button
                                onClick={() => {
                                  this?.followBid(item?.id);
                                }}
                                className="action_slider_browse_btn bg-white d-table text-decoration-none lots_slider_follow_btn"
                              >
                                FOLLOW
                              </Button>
                            )}

                            <Link
                              to={`/lotDetails/${item?.id}`}
                              className="action_slider_browse_btn d-flex align-items-center ml-2 text-decoration-none lots_slider_bid_btn"
                            >
                              <Blackbid /> BID
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="slid_view_all w-100 h-100 ">
                  <Link
                    to="/trendingLots"
                    className="slid_view_all_btn w-100 h-100 d-flex align-items-center justify-content-center text-white text-decoration-none position-relative"
                  >
                    View All
                  </Link>
                </div>
              </Carousel>
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return state;
};
// export default withRouter(LoginPopup);
export default connect(mapStateToProps, actionCreators)(withRouter(LotsSlider));
// export default withRouter(LotsSlider);
