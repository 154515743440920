import React, { Component } from "react";
import "./css/style.css";
class Contactus extends React.Component {
  componentDidMount() {
    document.title = "FAHH-contact us";
  }
  render() {
    return (
      <div className="contactUsContainer">
        <p className="contact-us-main-header">Contact Us</p>
        <div className="contact-us-box-header">
          <div className="contact-us-box-header-content">
            If you need to contact someone at FAHH, please email{" "}
            <a href="mailto:support@fineart.hiphop">support@fineart.hiphop</a>{" "}
            {/* <div className="email-text">Email us</div>
            <div className="sub-text">
              You can submit a ticket describing your issue and one of our
              representatives will assist you.
            </div>
            <div>
              <a target="_blank" href="mailto:support@fineart.hiphop">
                {" "}
                <button>Create A Ticket</button>{" "}
              </a>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}
export default Contactus;
