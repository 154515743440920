import styled from "styled-components";

export const LotDeatilsStyled = styled.div`
  .inner_banner_slider
    .banner_right_cont
    .right_cont_details_box
    .lots_cost
    .lot-current-bid-sec {
    color: rgba(0, 0, 0, 0.5);
    font-family: "BentonSansLight";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    .bid-cost-details {
      font-family: "BentonSansRegular";
      color: #000;
      font-size: 12px;
    }
  }
  .inner_banner_slider
    .banner_right_cont
    .right_cont_details_box
    .lots_cost
    .bid-cost-details {
    font-family: "BentonSansRegular";
    color: #000;
    font-size: 12px;
  }
`;
