import React, { Component } from "react";
import "./css/style.css";
class StorageGuides extends React.Component {
  componentDidMount() {
    document.title = "FAHH-storageGuides";
  }
  render() {
    return (
      <div className="storageGuidesContainer">
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </p>
        <p>this is Storage Guide page</p>
      </div>
    );
  }
}
export default StorageGuides;
