import React, { useEffect, useState } from "react";

import { GateCodeStyled } from "./GateCode.styled";
import { Container, Row, Col, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";

import { ReactComponent as PasswordCrossIcon } from "./../../assets/image/passwordCross.svg";
import { ReactComponent as PasswordTickIcon } from "./../../assets/image/passwordTick.svg";
import { verifyemailConfirmCall } from "../../library/Requests/MyAccount";
import CustomModal from "../../components/CustomModel";
import { STATIC_GATE_CODE } from "./../../library/constants";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const GateCode = () => {
  const [userPassword, setUserPassword] = useState("");
  const [userPasswordError, setUserPasswordError] = useState("");
  const [userConformPassword, setUserConformPassword] = useState("");
  const [userConformPasswordError, setConformUserPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [gateCode, setGateCode] = useState("");
  const query = useQuery();
  const token = query.get("token");
  const guid = query.get("guid");
  const email = query.get("email");

  useEffect(() => {}, []);

  const handleChangeCode = async (e: any) => {
    const { name, value } = e.target;
    setGateCode(value);
  };

  const handleSubmit = async (e: any) => {
    if (STATIC_GATE_CODE === gateCode) {
      localStorage.setItem("gateCode2", gateCode);
      window.location.href = "/";
    } else {
      setErrorMessage("Please Enter Valid Code");
    }
  };

  return (
    <>
      <GateCodeStyled>
        <div className="Create-gate-code-sec">
          <div className="create-header">Enter Gate Code</div>
          <div className="gate-code-sec-con">
            <input
              value={gateCode}
              onChange={handleChangeCode}
              placeholder="Enter Gate Code"
            />
            <button onClick={handleSubmit}>SUBMIT</button>
          </div>
          <div>
            {errorMessage && <p className="error_msg">{errorMessage}</p>}
          </div>
        </div>
      </GateCodeStyled>{" "}
    </>
  );
};
export default GateCode;
