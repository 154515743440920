import _ from "lodash";
import Axios from "axios";

import { TRIGGER_LOGIN_POPUP } from "./../../library/Requests/eventConstants";
import { triggerEventForProfileUpdate } from "./../../Scenes/common";
const FreshDeskAPKey = "fOXxvZycdt8pQVLQR7OA";

export const get = async function (url) {
  try {
    const response = await Axios.get(url, {
      headers: {
        Authorization: "bearer " + localStorage.getItem("cat"),
      },
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      triggerEventForProfileUpdate(TRIGGER_LOGIN_POPUP);
    }
    // return error.response;
  }
};
export const put = async function (url, body = {}) {
  const response = await Axios.put(url, body, {
    headers: {
      Authorization: "bearer " + localStorage.getItem("cat"),
    },
  });
  return response.data;
};
export const post = async function (url, body = {}) {
  try {
    const response = await Axios.post(url, body, {
      headers: {
        Authorization: "bearer " + localStorage.getItem("cat"),
      },
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      triggerEventForProfileUpdate(TRIGGER_LOGIN_POPUP);
    }
    // return error.response;
  }
};

export const del = async function (url, body = {}) {
  const response = await Axios.delete(url, body, {
    headers: {
      Authorization: "bearer " + localStorage.getItem("cat"),
    },
  });
  return response.data;
};

export const freshDeskGet = async function (url) {
  try {
    const response = await Axios.get(url, {
      headers: {
        Authorization: `Basic ${btoa(FreshDeskAPKey)}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      triggerEventForProfileUpdate(TRIGGER_LOGIN_POPUP);
    }
    // return error.response;
  }
};

export const freshDeskPost = async function (url, body = {}) {
  try {
    const response = await Axios.post(url, body, {
      headers: {
        Authorization: `Basic ${btoa(FreshDeskAPKey)}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      triggerEventForProfileUpdate(TRIGGER_LOGIN_POPUP);
    }
    // return error.response;
  }
};
