import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import AccountSlideBar from "../../components/AccountSlideBar";

import "./css/Bids.css";
import Loader from "./../../components/Common/Loader";
import { getcurrenciesCall, followbidCall } from "./../../library/Requests";
import { getcustomerbidsCall } from "./../../library/Requests/MyAccount";
import { LOGINID } from "./../../Scenes/common";
import PurchasesListcontent from "../../components/PurchasesListcontent";

class MyBids extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      currenciesList: [],
      activeBids: [],
      pastBids: [],
      isLoading: false,
    };
  }
  componentDidMount() {
    this.getcurrenciesDataCall();
    this.getDataonLoad();
    document.title = "FAHH-MyBids";
  }

  getcurrenciesDataCall = async () => {
    const resp = await getcurrenciesCall();
    this.setState({ currenciesList: resp?.data });
  };
  getDataonLoad = async () => {
    this.setState({ isLoading: true });
    const resp = await getcustomerbidsCall(LOGINID);
    this.setState({
      activeBids: resp?.activeBids,
      pastBids: resp?.pastBids,
      isLoading: false,
    });
  };
  followBid = async (lotId: any) => {
    const body = {
      follow: {
        itemId: lotId,
        customerId: LOGINID,
        isFollow: true,
      },
    };
    this.setState({ isLoading: true });
    const resp = await followbidCall(body);
    this.props.followeddata(true);
    this.getDataonLoad();
    this.setState({ isLoading: false });
  };
  render() {
    const { currenciesList, activeBids, pastBids, isLoading } = this.state;
    console.log(currenciesList);
    console.log("activeBids", activeBids);
    console.log("pastBids", pastBids);

    return (
      <>
        <div className=" bids_page_content account_all_page">
          <Container>
            <Row className="bids_page_left_right_content">
              {isLoading && <Loader />}
              <AccountSlideBar />
              <Col lg={9}>
                <div className="purchases_page_content bids_page_right_content account_all_right_content d-none d-md-block">
                  <div className="bids_page_active_bid account_all_right_section">
                    <div className="account_all_right_content_heading pb-3">
                      <h4 className="mb-0">
                        Bidding Activity (Active Auctions)
                      </h4>
                    </div>

                    {activeBids.length > 0 ? (
                      <>
                        <div className="purchases_no_activity">
                          <PurchasesListcontent
                            sectionType="bids"
                            Mylots={activeBids}
                            followBid={this.followBid}
                            currenciesList={currenciesList}
                          />
                        </div>
                      </>
                    ) : (
                      <div className="bids_no_activity text-center mx-auto">
                        <p>
                          You are not currently bidding on any items in an
                          Active Auction. To see items you were bidding on in
                          auctions that ended go to Bidding Activity (Past
                          Auctions) below. To participate and bid in current or
                          upcoming sales, please browse our Auction Calendar.
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="purchases_page_content bids_page_past_bid account_all_right_section">
                    <div className="account_all_right_content_heading pb-3">
                      <h4 className="mb-0">Bidding Activity (Past Auctions)</h4>
                    </div>
                    {pastBids.length > 0 ? (
                      <>
                        <div className="purchases_no_activity">
                          <PurchasesListcontent
                            sectionType="bids"
                            Mylots={pastBids}
                            followBid={this.followBid}
                            currenciesList={currenciesList}
                          />
                        </div>
                      </>
                    ) : (
                      <div className="bids_no_activity text-center mx-auto">
                        <p>
                          You do not have any bid activity in the last 12
                          months. To participate and bid in current or upcoming
                          sales, please browse our Auction Calendar.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="responsive_bids_tab d-block d-md-none">
                  <Tabs
                    defaultActiveKey="profile"
                    id="uncontrolled-tab-example"
                  >
                    <Tab
                      eventKey="home"
                      title="Bidding Activity (Active Auctions)"
                    >
                      {activeBids.length > 0 ? (
                        <>
                          <div className="purchases_no_activity purchases_mobile_view">
                            <PurchasesListcontent
                              Mylots={activeBids}
                              sectionType="bids"
                              followBid={this.followBid}
                              currenciesList={currenciesList}
                            />
                          </div>
                        </>
                      ) : (
                        <div className="bids_no_activity text-center mx-auto">
                          <p>
                            You have no upcoming activity. To participate in any
                            of Fineart.hiphop’s upcoming sales, please browse
                            our
                            <Link to="/bids"> Auction Calendar</Link>
                          </p>
                        </div>
                      )}
                    </Tab>
                    <Tab
                      eventKey="profile"
                      title="Bidding Activity (Past Auctions)"
                    >
                      {pastBids.length > 0 ? (
                        <>
                          <div className="purchases_no_activity purchases_mobile_view">
                            <PurchasesListcontent
                              Mylots={pastBids}
                              sectionType="bids"
                              followBid={this.followBid}
                              currenciesList={currenciesList}
                            />
                          </div>
                        </>
                      ) : (
                        <div className="bids_no_activity text-center mx-auto">
                          <p>
                            You have no upcoming activity. To participate in any
                            of Fineart.hiphop’s upcoming sales, please browse
                            our
                            <Link to="/bids"> Auction Calendar</Link>
                          </p>
                        </div>
                      )}
                    </Tab>
                  </Tabs>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default MyBids;
