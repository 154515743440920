import React, { Component } from "react";
import "./css/style.css";
class PaymentGuides extends React.Component {
  componentDidMount() {
    document.title = "FAHH-paymentGuides";
  }
  render() {
    return (
      <div className="contactUsContainer">
        <p className="contact-us-main-header">Payment</p>
        <div className="shipping-box-header">
          <p>
            Congratulations on becoming a successful bidder at a FineArt.HipHop
            auction!
          </p>
          <p>
            If you were successful in our auction, your purchase information
            will be available to view in your online account, where you can view
            invoices, make payments, arrange and track you shipping.
          </p>
          <p>
            We also accept payment via US Bank account/ACH transfers. If you
            require any assistance with the payment process, please email {" "}
            <a href="mailto:support@fineart.hiphop">support@fineart.hiphop</a>{" "}
            and we are happy to help.
          </p>
          <p>
            You will receive an email that will direct you to the online
            checkout page. Here, you will be provided with an itemized breakdown
            of all costs including buyer’s premium, applicable shipping, taxes
            and insurance. Once payment has been made, you will be provided with
            a confirmation of payment.
          </p>
          {/* <p>
            Please note that payment is due immediately via online checkout.
            FineArt.HipHop does not accept third-party payments from an
            individual or organization that is not the winning bidder.
          </p> */}
          <p>
            Please note that payment is due immediately via online checkout.
            FineArt.HipHop does not accept third-party payments from an
            individual or organization that is not the winning bidder.
          </p>
          <p>
            Invoices and payments must be in the name of the registered bidder,
            whether an individual or a third pary. The bidder’s name must match
            the name on the invoice and payment method used. This is a standard
            practice to ensure proper tracking and ownership of the items being
            sold.
          </p>
        </div>
      </div>
    );
  }
}
export default PaymentGuides;
