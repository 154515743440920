import { useEffect, useState } from "react";
import { convertLMilleSec } from "../../Scenes/common";

const AuctionClosed = (props: any) => {
  // State to trigger re-render
  const [currenttime, setCurrentTime] = useState(convertLMilleSec());

  const { collELocalTim } = props;

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(convertLMilleSec()); // Updates time every second
    }, 1000); // Set interval to 1 second

    // Cleanup the interval when the component unmounts
    return () => clearInterval(timer);
  }, []); // Empty dependency array means this runs once on mount and cleanup on unmount


  return (
    <>
      {currenttime < collELocalTim ? (
        <p>
          <span className="time-red-color">Lots Are Closing</span>{" "}
        </p>
      ) : currenttime > collELocalTim ? (
        <>
          <p>Auction Closed</p>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default AuctionClosed;
