import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { followeddata } from "../../actions";

import { ReactComponent as CloseIcon } from "../../assets/close-icon.svg";

const HeaderTopNotification = () => {
  const { isFollowed } = useSelector((state: any) => state);
  const [isFollowedData, setIfollowedData] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(followeddata(false));
  }, [window.location.pathname]);

  useEffect(() => {
    if (isFollowed) {
      setIfollowedData(true);
    } else {
      setIfollowedData(false);
    }
  }, [isFollowed]);

  return (
    <>
      {isFollowedData && (
        <div className="new-item-sec-map-list">
          <div className="banner-notification-sec banner-notification-sec">
            <span className="row-item-sec-pay">
              <div>

              You can easily access your followed lots for this auction or any auction by going to My Account
              &gt; My Followed Lots.
              <a
                onClick={() => {
                  history.push("/myFollowedLots");
                }}
              >
                {" "}
                Go to My Followed Lots
              </a>
              </div>
              <CloseIcon
                onClick={() => {
                  dispatch(followeddata(false));
                }}
                className="close-notification-icon"
              />
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderTopNotification;
