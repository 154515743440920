import React, { Component, useState } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";

import { Nav, Container, Form, FormControl, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import Logo_mail from "./../../assets/main/email-verification-1.png";
import ModalLogo from "../../assets/main/fahh_logo.png";
import { forgotPasswordCall } from "./../../library/Requests/MyAccount";
import { connect } from "react-redux";
import * as actionCreators from "./../../actions/index.js";
import Loader from "./../../components/Common/Loader";
// import Box from "../component/box.js";
import {
  AFTER_LOGIN_TRIGGER_PAGE,
  TRIGGER_LOGIN_POPUP,
  AFTER_PLACEBID_TRIGGER_PAGE,
  AFTER_REGISTERBID_TRIGGER_PAGE,
} from "./../../library/Requests/eventConstants";
import { triggerEventForProfileUpdate } from "./../../Scenes/common";
import "./styles.css";
const ForgotPassword = (props) => {
  const [loginError, setLoginError] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userEmailError, setUserEmailError] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleChange = (value) => {
    setUserEmail(value);
    setUserEmailError("");
    setLoginError("");
  };
  const loginAction = async () => {
    if (userEmail) {
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailRegex.test(String(userEmail).toLowerCase())) {
        setUserEmailError("");
        setIsLoading(true);
        const resp = await forgotPasswordCall({ email: userEmail });
        if (isEmailSent) {
          setShowSuccessMessage(true);
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 4000);
        }

        setIsLoading(false);
        if (resp?.success) {
          setIsEmailSent(true);
        } else if (resp?.errors?.length > 0) {
          setLoginError(resp?.errors[0]);
        } else {
          setLoginError(resp?.data);
        }
        console.log("resp", resp);
      } else {
        setUserEmailError("Please Enter valid Email.");
      }
    } else {
      setUserEmailError("Please Enter Email.");
    }
  };
  return (
    <Modal
      show={props?.show}
      onHide={() => {
        props.onHide();
      }}
      centered
      className="login_modal for_got_password_model"
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <Loader />}
        {!isEmailSent ? (
          <>
            <div className="forgot_password_sec_full">
              {/* <img
                src={ModalLogo}
                alt="login_logo"
                className="login_logo login_logo_sign_in_model1 d-block mx-auto d-md-none"
              /> */}
              <h3 className="sub_forgotpassword_text">Forgot your password?</h3>
              <p className="sub_sub_forgotpassword_text">
                Enter your email address and we'll send you a link to reset your
                password.
              </p>
              <Form>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email address*</Form.Label>
                  <Form.Control
                    value={userEmail}
                    onChange={(e) => {
                      handleChange(e.target.value);
                    }}
                    type="email"
                    // placeholder="Email"
                  />
                  {userEmailError && (
                    <Form.Text>
                      <p className="forgotpassword_text_error">
                        {userEmailError}
                      </p>
                    </Form.Text>
                  )}
                </Form.Group>

                {loginError != "" && (
                  <div className="login_error_msg">{loginError}</div>
                )}
              </Form>
              <div className="continue_forgot_password">
                <Button
                  variant=" login_btn"
                  onClick={() => {
                    loginAction();
                  }}
                  disabled={isLoading}
                >
                  CONTINUE
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="forgot_password_sec_full success-link-email">
              <h3 className="sub_forgotpassword_text">Email has been sent!</h3>
              <p className="sub_sub_forgotpassword_text ">
                Check your inbox for an email from FineArt.HipHop with a
                password reset link (sent to {userEmail}).
              </p>

              <div>
                <div className="email-success-Logo">
                  {/* <img src={Logo_mail} /> */}
                </div>
              </div>
              <div className="email-success-Logo-img">
                <img src={Logo_mail} />
              </div>

              <div>
                <p className="dont-receive-link-email">
                  Didn’t receive the link?{" "}
                  <span onClick={loginAction}>Resend</span>
                </p>
              </div>
              {showSuccessMessage && (
                <div className="success-message-txt">
                  Email has been sent Successfully.
                </div>
              )}
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ForgotPassword;
