import { get, post, put, del } from "./helpers";
import { API_BASE, SERVER_IP } from "../constants";

export const getdraftedarticleCall = async (search) => {
  return await get(
    `${SERVER_IP}${API_BASE}/ContentManagement/getallarticle?Status=2&Page=1&PageSize=50&SearchText=${search}`
  );
};
export const getpublishedarticleCall = async (search) => {
  return await get(
    `${SERVER_IP}${API_BASE}/ContentManagement/getallarticle?Status=1&Page=1&PageSize=50&SearchText=${search}`
  );
};
export const getarticlebyidCall = async (id) => {
  return await get(
    `${SERVER_IP}${API_BASE}/ContentManagement/getarticlebyid?Id=${id}`
  );
};
