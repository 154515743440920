import React, { useState } from "react";

import Modal from "react-bootstrap/Modal";
import { Button, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import "./css/ManageProfileModal.css";
import MobileNumbersList from "./../../components/Common/MobileNumbersList";
import { formatPhoneNumber } from "./../../Scenes/common";
interface Props {
  userDataManage: any;
  ChangeValue?: any;
  onConfirmSave?: any;
}
export const ManageProfileModal: React.FC<Props> = (Props) => {
  const [show, setShow] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const onConfirm = () => {
    setConfirmShow(true);
    setShow(false);
  };
  const onConfirmModalClose = () => {
    setConfirmShow(false);
  };
  const handleSaveChanges = () => {
    if (
      userD?.firstName &&
      userD?.lastName &&
      userD?.email &&
      userD?.phoneCode &&
      userD?.phoneNumber
    ) {
      Props?.onConfirmSave();
      handleClose();
    }
  };
  //   const ChangeValue = async (selectedType:any,selectedvalue:any)=>{
  //     userData[selectedType] = selectedvalue;
  //     // setUserData(userData);
  // }
  var regExp = /\(([^)]+)\)/;
  var matches = regExp.exec(Props?.userDataManage.phoneCode);
  const userD = Props?.userDataManage;
  return (
    <>
      <Button
        variant="outline-primary"
        className="btn btn-outline-primary profileBtns manage-profile-btn-sec"
        onClick={handleShow}
      >
        MANAGE PROFILE
      </Button>

      <Modal
        centered
        show={show}
        onHide={handleClose}
        className="custom_modal_box custom_magage_profile_modal"
      >
        <Modal.Body>
          <h3>Manage Profile</h3>

          <Form>
            <Row>
              <Col md={3}>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Title*</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      Props.ChangeValue("title", e.target.value);
                    }}
                  >
                    <option
                      selected={
                        Props?.userDataManage?.title == "Mr." ? true : false
                      }
                      value="Mr."
                    >
                      {" "}
                      Mr.
                    </option>
                    <option
                      selected={
                        Props?.userDataManage?.title == "Mrs." ? true : false
                      }
                      value="Mrs."
                    >
                      {" "}
                      Mrs.
                    </option>
                    <option
                      selected={
                        Props?.userDataManage?.title == "Miss" ? true : false
                      }
                      value="Miss"
                    >
                      {" "}
                      Miss
                    </option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={10}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>First Name*</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      Props.ChangeValue("firstName", e.target.value);
                    }}
                    type="text"
                    isInvalid={!userD?.firstName}
                    value={userD?.firstName}
                    placeholder="First Name"
                  />
                  {/* <Form.Control.Feedback type="invalid">
                    {!userD?.firstName ? <> Please Enter First Name</> : null}
                  </Form.Control.Feedback> */}
                </Form.Group>
              </Col>
              <Col md={10}>
                <Form.Group controlId="exampleForm.ControlInput2">
                  <Form.Label>Last Name*</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      Props.ChangeValue("lastName", e.target.value);
                    }}
                    value={userD?.lastName}
                    isInvalid={!userD?.lastName}
                    type="text"
                    placeholder="Last Name"
                  />
                </Form.Group>
              </Col>
              <Col md={10}>
                <Form.Group controlId="exampleForm.ControlInput2">
                  <Form.Label>Email*</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      Props.ChangeValue("email", e.target.value);
                    }}
                    value={userD?.email}
                    isInvalid={!userD?.email}
                    type="email"
                    placeholder="Email"
                  />
                </Form.Group>
              </Col>
              <Col md={10} className="signup-flow-phone-pe">
                <div className="mobile-number-col">
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Mobile Number </Form.Label>
                    <div className="phone-control-fields-sec">
                      <div className="phone-control-fields-sec-left">
                        <Form.Control
                          className="phoneCodeSelect"
                          value={userD.phoneCode}
                          isInvalid={!userD?.phoneCode}
                          onChange={(e) => {
                            Props.ChangeValue("phoneCode", e.target.value);
                          }}
                          name="phoneCode"
                          as="select"
                        >
                          <MobileNumbersList />
                        </Form.Control>
                      </div>
                      <div className="phone-control-fields-sec-right">
                        <Form.Control
                          className="phoneNumberStyles"
                          type="text"
                          maxLength={12}
                          value={formatPhoneNumber(userD?.phoneNumber)}
                          name="phoneNumber"
                          placeholder="Mobile Number"
                          isInvalid={!userD?.phoneNumber}
                          onChange={(e) => {
                            Props.ChangeValue("phoneNumber", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </Form.Group>
                </div>
              </Col>
              {/* <Col md={10} className="signup-flow-phone-pe">
              <div className="mobile-number-col">
                <Form.Group
                  controlId="exampleForm.ControlSelect1"
                  className="twoBox"
                >
                  <Form.Label className="emptyLabel"></Form.Label>
                  <Form.Control
                    className="phoneNumberStyles"
                    type="text"
                    value={Props?.userDataManage?.phoneNumber}
                    name="phoneNumber"
                    placeholder="Mobile Number"
                    onChange={(e) => {
                      Props.ChangeValue("phoneNumber", e.target.value);
                    }}
                  />
                </Form.Group>
                </div>
              </Col> */}

              <Col className="d-flex justify-content-between modal_btns">
                <Button
                  variant="outline-secondary close_modal_btn"
                  onClick={handleClose}
                >
                  CANCEL
                </Button>
                <Button
                  variant="outline-secondary save_change_modal_btn"
                  onClick={handleSaveChanges}
                >
                  SAVE CHANGES
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        show={confirmShow}
        onHide={handleClose}
        className="custom_modal_box custom_magage_profile_modal"
      >
        <Modal.Body>
          <h5 className="text-center w-100 mb-3">
            Your profile has been successfully updated
          </h5>
          <Col className="d-flex justify-content-between modal_btns pt-0">
            <Button
              variant="outline-secondary save_change_modal_btn"
              className="ml-auto"
              onClick={onConfirmModalClose}
            >
              Ok
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ManageProfileModal;
